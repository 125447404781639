<template>

    <div class="MyWidth">
      <v-dialog v-model="dialog3" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card style="opacity: 1;">
            <v-toolbar dark>
              <v-btn icon dark @click="dialog3 = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Tabla - Persons Types</v-toolbar-title>
              <v-spacer></v-spacer>
                
              <v-btn dark class="ma-2" :loading="loading2" :disabled="loading2" color="green" @click="DownloadPersonTypeList()">
                Actualizar lista
              </v-btn>
            </v-toolbar>

            <v-card style="padding:20px; background: #e4e9f7; ">
              <v-card-title style="padding-bottom: 0;">
                <v-btn dark class="ma-2" :loading="loading2" :disabled="loading2" color="blue" @click="ExportData()">
                  Exportar a la tabla
                </v-btn>
                
              <v-spacer></v-spacer>
                
              </v-card-title>

              <v-data-table show-select v-model="selected" :headers="headers2" :items="desserts2" 
                :single-select="singleSelect2"
                  item-key="id" class="elevation-1">
                <template v-slot:top>
                </template>
              </v-data-table>
            </v-card>

          </v-card>
      </v-dialog>

      <v-toolbar dark>
          <v-toolbar-title>
              Configuración de Boletos
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" small fab dark color="teal" v-bind="attrs" v-on="on" @click="CuotasOnX">
                  <span class="material-icons">
                    monetization_on
                  </span>
                </v-btn>
                </template>
            <span>Coutas</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn class="mx-2" small fab dark color="teal" v-bind="attrs" v-on="on" @click="OpenPendientes()">
                  <v-icon dark>
                    mdi-format-list-bulleted-square
                  </v-icon>
                </v-btn>
                </template>
            <span>Tabla Person Type</span>
          </v-tooltip>

        </v-toolbar>

        <v-row class="MyPadding2" style="padding-top: 20px;">
          <v-col cols="12" md="6" class="MyPadding4">
          </v-col>
          <v-col cols="12" md="3" align="right" class="MyPadding4" v-if="false">
            <v-select dense outlined :items="Entidad" v-model="EntidadX" name="id" item-text="name" label="Entidad" background-color='white' v-on:change="EntidadChange"></v-select>
          </v-col>
          <v-col cols="12" md="3" align="right" class="MyPadding4" v-if="false">
            <v-select dense outlined :items="Sitios" v-model="SitiosX" name="id" item-text="name" label="Sitios" background-color='white'></v-select>
          </v-col>
          <v-col cols="12" class="MyPadding3">
            <VueTable @paste="onPaste" ref="ComiTable" :ButonWith="'width:80px'" :BtnDelete="null" :headers="headers" :desserts="desserts" :titulo="titulo" :father="'CuentaContable'"/>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="900px">
          <v-card style="zoom: 90%; opacity:1;">
            <v-card-title style="background: #272727; color: white;">
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>

                <v-row v-if="!ShowCuota" style="margin-top: 5px;">
                  <v-col cols="12" sm="6" md="3" class="MyPadding">
                    <v-text-field readonly v-model="editedItem.Idticket" disabled label="Id Boleto Axess"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="MyPadding">
                    <v-text-field readonly v-model="editedItem.cuenta" disabled label="Numero de Cuenta"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="MyPadding">
                    <v-text-field v-model="editedItem.mayo" label="Id de Boleto Mayorista"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" class="MyPadding">
                    <v-text-field v-model="editedItem.promtec" label="Cuota Promtec"></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="7" class="MyPadding">
                    <v-text-field readonly v-model="editedItem.nombre" disabled label="Nombre Largo"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="5" class="MyPadding">
                    <v-text-field v-model="editedItem.alias" label="Alias"></v-text-field>
                  </v-col>


                  <v-col cols="12" sm="6" md="4" class="MyPadding">
                    <v-text-field v-model="editedItem.tickettype" label="Ticket Type"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="MyPadding">
                    <v-text-field v-model="editedItem.npool" label="Npool"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4" class="MyPadding">
                    <v-text-field v-model="editedItem.web" label="Id. Boleto WebShop"></v-text-field>
                  </v-col>




                  <v-col cols="12" sm="6" md="4" class="MyPadding">
                    <v-select :items="Entidad" v-model="editedItem.entidadx" name="id" item-text="name" label="Entidad" @change="EntiChange()"></v-select>
                  </v-col>
                  <v-col cols="12" sm="6" md="5" class="MyPadding">
                    <v-select :items="Sitios2" :disabled="disableSitio" v-model="editedItem.sitio" name="id" item-text="name" label="Sitio"></v-select>
                  </v-col>
                  <v-col cols="12">
                    <div>
                      <strong>CUOTAS INA</strong><hr style="margin-top: 2px; border: 1px solid;">
                    </div>
                      <v-chip class="ma-2" v-for="(item, index1) in Comision" :key="`ComisionX-${index1}`">
                        {{textfix(item.name)}} ${{item.valor}}
                      </v-chip>
                      <v-chip color="primary" class="ma-2 pointman" @click="OpenCoutas">
                        <v-icon>mdi-plus</v-icon>
                        Editar
                      </v-chip>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-btn dark color="secundary" @click="close" :loading="loading3" v-if="!ShowCuota">
                CancelAR
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn dark color="success" @click="save" :loading="loading3" v-if="!ShowCuota">
                GUARDAR
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialog2X" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card style="opacity: 1; background: #e4e9f7;">
            <v-toolbar dark>
              <v-btn icon dark @click="dialog2X = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Modulo de Coutas INA</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn class="mx-2" dark color="teal" @click="UpdateCuotasTicket()" v-if="CuotasOn">
                <v-icon dark>
                  save
                </v-icon>
                GUARDAR COUTAS
              </v-btn>
                
            </v-toolbar>

            <v-card style="margin: 20px; padding: 20px;">
              <v-row>
                <v-col cols="12">
                  <div class="BarFormCuotas noselect" @click="ShowFormCoutasX">
                    <span v-if="!ShowFormCoutas" class="material-icons" style="transform: rotate(90deg); position: absolute; left: calc(100% - 50px);">                      
                      double_arrow
                    </span>
                    <span v-if="ShowFormCoutas" class="material-icons" style="transform: rotate(270deg); position: absolute; left: calc(100% - 50px);">                      
                      double_arrow
                    </span>
                    Formulario Cuotas
                  </div>

                  <v-card style="padding: 20px; zoom: 80%;" v-if="ShowFormCoutas" class="noselect">
                    <v-row>
                      <v-col cols="2" style="padding-top: 0;">
                        <v-text-field :disabled="true" v-model="CoutaItem.id" label="Id Couta"></v-text-field>
                      </v-col>
                      <v-col cols="10" style="padding-top: 0;">
                        <v-text-field v-model="CoutaItem.name" label="Nombre de la Couta"></v-text-field>
                      </v-col>
                      <v-col cols="2" style="padding-top: 0;">
                        <v-text-field :disabled="EnableValor" v-model="CoutaItem.valor" label="Couta INA"></v-text-field>
                      </v-col>
                      <v-col cols="3" style="padding-top: 0;">
                        <v-text-field v-model="CoutaItem.cuenta" label="Cuenta Contable"></v-text-field>
                      </v-col>
                      <v-col cols="2" style="padding-top: 0;">
                        <v-select :items="Activo" v-model="CoutaItem.activo" name="id" item-text="name" label="Activo"></v-select>
                      </v-col>
                      <v-col cols="5" style="padding-top: 0;">
                        <v-row>
                          <v-col cols="6" align="center">
                            <v-btn dark color="success" @click="SaveCuota">
                              GUARDAR
                            </v-btn>
                          </v-col>
                          <v-col cols="6" align="center">
                            <v-btn dark color="blue" @click="NewCouta">
                              NUEVO CUOTA
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-card>

                </v-col>
                <v-col cols="12" v-if="!CuotasOn">
                   <v-card style="opacity: 1;">
                      <v-data-table show-select v-model="selected3" :headers="headers3"
                        :items="ComisionX"
                        :single-select="singleSelect"
                        item-key="id" class="elevation-1 pointman"
                        @click:row="handleClickCuotas">
                      </v-data-table>
                   </v-card>
                </v-col>
                <v-col cols="8" v-if="CuotasOn">
                   <v-card style="opacity: 1;">
                      <v-data-table show-select v-model="selected3" :headers="headers3"
                        :items="ComisionX"
                        :single-select="singleSelect"
                        item-key="id" class="elevation-1 pointman"
                        @click:row="handleClickCuotas">
                      </v-data-table>
                   </v-card>
                </v-col>
                <v-col cols="4" v-if="CuotasOn">
                  <h4>Coutas Seleccionadas</h4><hr>
                  <v-chip color="blue" class="ma-2" v-for="(item, index1) in selected3" :key="`ComisionX2-${index1}`">
                    <v-btn class="CuotasBtnDelete" @click="CuotasBtnDelete(index1)">
                      X
                    </v-btn>
                      {{textfix(item.name)}} ${{item.valor}}
                  </v-chip>
                </v-col>
              </v-row>
            </v-card>
          </v-card>
        </v-dialog>

        <textarea ref="PasteBox" @paste="onPaste" style="background:white; width:100%;" v-if="false">
          
        </textarea>
    </div>
</template>


<script>
import { setColorHeadFooter } from "@/utils/StyleTools.js";
import VueTable from '../VueTable.vue'
import axios from 'axios'

export default {
    components: {
        VueTable
    },
    data(){
        return{
            EnableValor:      false,
            ShowFormCoutas:   false,
            CuotasOn:         false,
            tab:              null,
            disableSitio:     false,
            singleSelect:     true,
            singleSelect2:    false,
            loading2:         false,
            loading3:         false,
            Activo:           [1,0],
            desserts2:        [],
            selected:         [],
            selected3:         [],
            headers2:         [
                                {
                                  text: 'Id Ticket',
                                  align: 'left',
                                  value: 'id',
                                },
                                {
                                  text: 'Nombre',
                                  align: 'left',
                                  value: 'name',
                                },
                                {
                                  text: 'Numero de Cuenta',
                                  align: 'left',
                                  value: 'cuenta',
                                }
                              ],

            json_data:        [],
            TextAll:          'TODO',
            dialog:           false,    //Modulo de edicion
            dialog2X:          false,    //Modulo de Coutas
            dialog3:          false,    //Modulo de PersonType
            editedIndex:      -1,
            category:         '',
            EntidadX:         1,
            SitiosX:          1,
            SitiosY:          1,

            CoutaItem:        {activo:1,  cuenta:null, id:null, name:null, valor:null},
            ShowCuota:        false,
            selectedX:        [],
            selectedY:        [],
            Comision:         [],

            ComisionN:        [],
            ComisionM:        [],

            ComisionX:        [],
            ComisionY:        [],
            DefaultComision:  {name:'', value:null, id:null},
               
            Activar:          [
                {id:0, name:'NO'},
                {id:1, name:'SI'},
                              ],
            Entidad:          [
              {name:'AAFY', id:1},
                              ],
            Sitios2:          [],
            Sitios:           [
                                  {name:'CHICHEN ITZÁ', id:1},
                              ],
            Bancas:           [
              {name:'CHICHEN ITZÁ', id:1, Fideocomiso:'A'},
                              ],
            editedItem:       {
                id:       null,
                Idticket: null,
                IdSitio:  null,
                mayo:     null,
                entidad:  null,
                nombre:   '',
                alias:    '',
                tickettype: null,
                npool:    null,
                web:      null,
                cuenta:   '',
                sitio:    '',
                entidadx: '',
                promtec:  null
                              },
            defaultItem:      {
                id:       null,
                Idticket: null,
                IdSitio:  null,
                mayo:     null,
                entidad:  null,
                nombre:   '',
                cuenta:   '',
                sitio:    '',
                entidadx: '',
                promtec:  null
                              },
            headers:          [
                                  { text: 'Id Boleto',          value: 'Idticket' },
                                  { text: 'Cuenta Contable',    value: 'cuenta' },
                                  { text: 'Nombre del Boleto',  value: 'nombre' },
                                  { text: 'Alias',  value: 'alias' },
                                  { text: 'Nombre Axess',       value: 'SZNAME' },                    
                                  { text: 'Sitio',              value: 'sitio' },
                                  { text: 'Cuota INA',          value: 'valor',     align:"center" },
                                  { text: 'Cuota Promtec',      value: 'promtec',   align:"center" },
                                  { text: 'Entidad',            value: 'entidadx',  align:"center" },
                                  { text: 'Ver en Mayorista',   value: 'mayo',      align:"center" },
                                  { text: 'TicketType',   value: 'tickettype',      align:"center" },
                                  { text: 'Npool',   value: 'npool',      align:"center" },
                                  { text: 'Ver en WebShop',   value: 'web',      align:"center" },
                              ],
            headers3:         [
                                { text: 'Id',                 value: 'id' },
                                { text: 'Nombre de la Couta', value: 'name' },
                                { text: 'Couta INAH',         value: 'valor' },
                                { text: 'Cuenta Contable',    value: 'cuenta' },                    
                                { text: 'activo',             value: 'activo' }
                              ],
            desserts:         [],
            titulo:           ''
        }
    },
   computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Editar Cuota' : 'Editar Registro'
      },
    },
    updated(){ 
      setColorHeadFooter();
    },
    mounted(){ 
      setColorHeadFooter();
    },
    created () {
      this.initialize()
    },
    methods: {
      ShowFormCoutasX(){
        this.ShowFormCoutas = !this.ShowFormCoutas
      },
      CuotasOnX(){
        this.CuotasOn = false
        this.selected3 = []
        this.dialog2X = true
      },
      NewCouta(){
        this.EnableValor  = false
        this.CoutaItem =  {activo:1,  cuenta:null, id:null, name:null, valor:null}
      },
      handleClickCuotas(value) {
        this.EnableValor  = true
        this.CoutaItem    = JSON.parse(JSON.stringify(value))
      },
      CuotasBtnDelete(index1){
        this.selected3.splice(index1, 1)
      },
      savecuota(){
        this.Comision = JSON.parse(JSON.stringify(this.ComisionN))
        let array = this.ComisionX

        for (var x=0; x < this.Comision.length; x++){
          array = array.filter( itemx => itemx.id !== this.Comision[x].id )
        }
        this.ComisionY = array


        if(this.ShowCuota == false){
          this.ShowCuota = true
          this.editedIndex = -1
        }else{
          this.ShowCuota = false
          this.editedIndex = 1
        }

      },
      dynamicSort(property) {
          var sortOrder = 1;
          if(property[0] === "-") {
              sortOrder = -1;
              property = property.substr(1);
          }
          return function (a,b) {
              /* next line works with strings and numbers, 
              * and you may want to customize it to your needs
              */
              var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
              return result * sortOrder;
          }
      },
      reasignar(){
        for(var x=0; x < this.selectedX.length; x++){
          this.ComisionY.push( this.ComisionX[this.selectedX[x]])
        }

        let select = this.selectedX
        select.sort(function(a, b){return b-a})

        for(var x=0; x < select.length; x++){
          this.ComisionN.splice(select[x], 1)
        }

        this.ComisionN.sort(this.dynamicSort("name"))
        this.ComisionY.sort(this.dynamicSort("name"))
        this.selectedY = []
        this.selectedX = []
      },
      asignar(){
        for(var x=0; x < this.selectedY.length; x++){
          this.ComisionN.push( this.ComisionY[this.selectedY[x]])
        }

        let select = this.selectedY
        select.sort(function(a, b){return b-a})

        for(var x=0; x < select.length; x++){
          this.ComisionY.splice(select[x], 1)
        }

        this.ComisionN.sort(this.dynamicSort("name"))
        this.ComisionY.sort(this.dynamicSort("name"))
        this.selectedY = []
        this.selectedX = []
      },
      OpenCoutas(){
        this.CuotasOn       = true
        this.ShowFormCoutas = false
        this.selected3      = JSON.parse(JSON.stringify(this.Comision))
        this.dialog2X        = true
      },
      SaveCuota(){
        if(!this.CoutaItem.name){
          this.MSGBOXFIX('error','Configuracion del Boleto','Escriba el nombre de a cuota')
          return false
        }

        if(!this.CoutaItem.valor == null){
          this.MSGBOXFIX('error', 'Configuracion del Boleto', 'Escriba el cost0 de a cuota del INA')
          return false
        }

        axios
        .post(this.$root.myserver + this.$root.myport + "/CC/SaveCuota",
        {
            token: '',
            data: this.CoutaItem
        })
        .then(response => {
          var text
          
          if(this.CoutaItemid){
            text = 'Se ha actualizado correctamente la cuota'
          }else{  
            text = 'Se ha creado correctamente la cuota'
          }

          this.MSGBOXFIX('success', 'Configuracion del Boleto', text)
          this.initialize()
        })
        .catch(error => {
          this.$swal({
              icon:   'error',
              title:  'Configuracion del Boleto',
              text:   'Ha ocurrido un error'
          })
          .then((result) => {
            setTimeout(() => {
              this.OpenCoutas()
              this.ShowFormCoutas = true
            }, 10)
          })
          console.log(error)
        })
      },
      UpdateCuotasTicket(){
        
        var datax = JSON.parse(JSON.stringify(this.selected3))

        axios
        .post(this.$root.myserver + this.$root.myport + "/CC/UpdateShares",
        {
            token:      '',
            Idticket:   this.editedItem,
            IdComision: datax[0].id
        })
        .then(response => {
          this.initialize()
          this.Comision = datax
          this.dialog2X  = false
          
          this.MSGBOX('success','Cuotas INA','Se ha actualizado tu cuota correctamente')

        })
        .catch(error => {
          this.dialog2X  = false

          this.MSGBOX('error','Cuotas INA','Ha ocurrido un error')
          console.log(error)
        })
      },
      MSGBOXFIX(icon, title, text){
        this.$swal({
            icon: icon,
            title: title,
            text: text
        }).then((result) => {
          setTimeout(() => {
            this.OpenCoutas()
            this.ShowFormCoutas = true
          }, 1)
        })
      },
      MSGBOX(icon, title, text){
        this.$swal({
            icon: icon,
            title: title,
            text: text
        })
      },
      textfix(text){
          text = text.split('Servicio de obtención anticipada de boletos aplicable a la zona arqueológica de').join('Couta')
        return text
      },
      EntiChange(){
        let res       = this.Entidad.find( itemx => itemx.name === this.editedItem.entidadx )

        if(res.id){
          this.disableSitio = false
        }else{
          this.disableSitio = true
        }

        this.Sitios2  = this.Sitios.filter( itemx => itemx.entidad === res.id )
      },
      ExportData(){
        let datax = []

        for(var x=0; x < this.selected.length; x++){
          datax.push([this.selected[x].id, 1])
        }

        axios
        .post(this.$root.myserver + this.$root.myport + "/CC/update",
        {
            token:  '',
            datax:  datax
        })
        .then(response => {
            this.selected = []
            this.OpenPendientes()
            this.initialize()
        })

      },
      DownloadPersonTypeList(){
        this.loading2   = true
        this.desserts2  = []

        axios
        .post(this.$root.myserver + this.$root.myport + "/api2/getCatalogues/UpdateCataloge",
        {
            token: '',
            i_szTableName:'tabperstypdef'
        })
        .then(response => {

          axios
          .post(this.$root.myserver + this.$root.myport + "/api2/getCatalogues/UpdateCataloge",
          {
              token: '',
              i_szTableName:'tabkundenkartentypdef'
          })
          .then(response => {
            this.loading2 = false
            this.OpenPendientes()
          })

        }).catch(error => {
          this.loading2 = false
        })
      },
      OpenPendientes(){
        this.desserts2 = []
        axios        
        .post(this.$root.myserver + this.$root.myport + "/CC/PersonTypeData",
        {
            token: ''
        })
        .then(response => {
          
           this.desserts2 = response.data
        })

        this.dialog3  = true

      },
      onPaste (evt) {
        var  Tx = evt.clipboardData.getData('text/plain')
        //Tx = Tx.split("	")
        Tx = Tx.split("\r\n")
        var Aray = []
        for(var x= 0; x < Tx.length; x++){
          var XX = Tx[x].split("\t")
          if(XX[1]){
            Aray.push(XX)
          }
        }

        setTimeout(() => {
          this.$refs.PasteBox.value = ""  
        }, 100);
        
        console.log(Aray)
      },
      SearEntida(sitio){
        if(sitio.id){
          var resultado = this.Entidad.find( itemx => itemx.id === sitio.entidad )
          return resultado.name
        }
      },
      changeSiteEdt(){
        this.SitiosX = null
        var resultado = this.Sitios.find( itemx => itemx.name === this.editedItem.sitioX )
        this.editedItem.sitio = resultado.id
      },
      EntidadChange(){
        var resultado = this.Entidad.find( itemx => itemx.name === this.EntidadX );
        axios
          .post(this.$root.myserver + this.$root.myport + "/CC/Lista",
          {
              entidad: resultado.id
          })
          .then(response => {
    
            this.$refs.ComiTable.loadingX = false
            
            //Cuentas contables
            this.desserts = response.data[0]

            //Sitios
            this.Sitios = response.data[2]
          })
          .catch(error => {
              console.log(error)
          })
          .finally(() => {
          })

      },
      CloseComision(){
        this.dialog2X = false
      },
      OpenComision(item){
        this.dialog2X = true
      },
      close(){
          this.dialog = false            
      },
      save(){
        this.loading3 = true

        let mayo = 0
        let res1  = this.Sitios2.find( itemx => itemx.name === this.editedItem.sitio )
        
        if(res1){
          this.editedItem.IdSitio = res1.id
        }

        mayo = this.editedItem.mayo
        
        
        axios
        .post(this.$root.myserver + this.$root.myport + "/CC/save",
        {
            sitio:  this.editedItem,
            mayo:   mayo
        })
        .then(response => {
          this.dialog = false
          this.initialize()
          this.loading3 = false

          this.MSGBOX('success','Configuracion del Boleto','Se ha actualizado correctamente la configuracion')

        })
        .catch(error => {
          this.dialog = false
          this.loading3 = false
          
          this.MSGBOX('error','Configuracion del Boleto','Ha ocurrido un error')

          console.log(error)
        })

      },
      initialize() {
        axios
        .post(this.$root.myserver + this.$root.myport + "/CC/Lista",
        {
            token: ''
        })
        .then(response => {
          this.$refs.ComiTable.loadingX = false

          this.desserts  = response.data[0]
          this.Sitios    = response.data[1]
          this.Entidad   = response.data[2]
          this.ComisionX = response.data[3]
          
          this.ComisionM = response.data[3]
          this.ComisionN = response.data[4]
        })
        .catch(error => {
            console.log(error)
        })
        .finally(() => {
        })
      },
      OpenFormEdit(item){
        this.ShowCuota    = false
        this.editedIndex  = this.desserts.indexOf(item)
        var edit          = Object.assign({}, item)
        this.selectedY    = []
        this.selectedX    = []
        this.editedItem   = edit        
        this.dialog       = true
        
        if(item.entidad){
          this.disableSitio = false
        }else{
          this.disableSitio = true
        }

        this.Sitios2      = this.Sitios.filter( itemx => itemx.entidad === item.entidad )
        this.Comision     = this.ComisionX.filter( itemx => itemx.id === item.comision )
        this.ComisionN    = JSON.parse(JSON.stringify(this.Comision)) 
        let array         = this.ComisionX
        
        for (var x=0; x < this.Comision.length; x++){
          array = array.filter( itemx => itemx.id !== this.Comision[x].id )
        }

        this.ComisionY    = array

      },
      OpenFormNew(item){
          this.editedIndex = -1
          this.dialog = true
          this.editedItem = Object.assign({}, item)
      },
      deleteItem(item){
          this.editedIndex = this.desserts.indexOf(item)
          this.editedItem = Object.assign({}, item)

          this.$swal({
              title: 'Estas seguro?',
              text: "Realmente quieres borrar el registro!",
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              confirmButtonText: 'Si!'
          }).then((result) => {
              if (result.isConfirmed) {
                  axios
                  .post(this.$root.myserver + this.$root.myport + "/CC/delete",
                  {
                      id: item.id
                  })
                  .then(response => {
                  })
                  .catch(error => {
                      console.log(error)
                  })   

                  this.desserts.splice(this.editedIndex, 1)
                  this.$swal(
                      'Eliminado!',
                      'El registro se ha eliminado.',
                      'success'
                  )
              }
              this.dialogDelete = true
          })
      }
    }
}
</script>

<style scoped>
  .BarFormCuotas{
    background: black;
    color: white;
    padding: 5px 5px 5px 15px;
    border-radius: 5px 5px 0px 0px;
  }
  .BarFormCuotas:hover{
    cursor: pointer;
    color: #6acfff;
  }
  .TabIconSpace{
     margin-right: 6px;
  }
  .CuotasBtnDelete{
      border-radius: 42px;
      min-width: 30px !important;
      height: 24px !important;
      padding: 0;
      padding-top: 1px;
      margin-right: 5px;
  }
  .listsize{
    height: 350px;
    overflow: auto;
    border: 2px solid silver !important;
    border-radius: 3px !important;
  }
  .theme--light.v-input--is-disabled, .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea {
      color: #000 !important;
      opacity: 0.9;
  }

  .pointman:hover{
    cursor: pointer;
    background-color: #f6f6f6;
  }
  .purple_bg { 
    background-color: white; 
  }
  .MyWidth{
    width: 100%;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
  }
  .MyPadding{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
  }
  .MyPadding2{
    padding-right: 10px;
  }
  .MyPadding3{
    padding-top: 0 !important;
    padding-left: 40px !important;
    padding-right: 30px !important;
  }
  .MyPadding4{
    padding-bottom: 0 !important;
    padding-right: 15px !important;
    padding-left: 25px !important;
  }
  .MyPadding5{
    padding-left: 0 !important;
  }
  .MyPadding6{
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .RowX{
    font-size: 18px;
  }
  .BListComi{
        background: rgb(223, 223, 223) !important
  }
  .DivComi{
    max-height: 600px;
    overflow: auto;
  }
  .TxtSiteX{
    font-weight: bold;
    font-size: 18px;
    margin-top: 18px;
  }

  @media only screen and (max-width: 500px) {
    .MyPadding3{
      padding-top: 0 !important;
      padding-left: 30px !important;
      padding-right: 20px !important;
    }
  }
</style>