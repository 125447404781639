<template>
  <v-card class="VcarRowT1">
    <table style="width:100%;">
      <tr align="center">
        <td colspan="6" style="font-weight: bold; font-size: 20px;">
          <strong>{{ titulox }}</strong>
        </td>
      </tr>
    </table>
    <fileIndex :Anexos="Anexos" v-if="Anexos.length"/>
    <br v-if="!Anexos.length"/>
    <div style="width:100%; overflow:auto">
      <table
        border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse;"
      >
        <thead>
          <tr>
            <td class="MyTdPadding" align="center"><strong>Usuario</strong></td>
            <td class="MyTdPadding" align="center">
              <strong>No. Caja</strong>
            </td>
            <td class="MyTdPadding" align="center">
              <strong>Tipo de pago</strong>
            </td>
            <td class="MyTdPadding" align="center"><strong>Cuota</strong></td>
            <td class="MyTdPadding" align="center">
              <strong>Cuenta de deposito</strong>
            </td>
            <td class="MyTdPadding" style="width:150px;" align="center">
              <strong>Importe</strong>
            </td>
          </tr>
        </thead>
        <tbody v-for="(item, index1) in DetUser" :key="`DetUser-${index1}`">
          <tr v-for="(item2, index2) in item.dataX" :key="`DetUserX-${index2}`">
            <td class="MyTdPadding">{{ item2.Usuario }}</td>
            <td class="MyTdPadding" align="center">{{ item2.Caja }}</td>
            <td class="MyTdPadding">{{ item2.TipoPago }}</td>
            <td class="MyTdPadding">{{ item2.Cuota }}</td>
            <td class="MyTdPadding" align="center">
              {{ item2.CuentaBancaria }}
            </td>
            <td class="MyTdPadding" align="right" style="width:150px;">
              ${{ formatPrice(item2.total) }}
            </td>
          </tr>
          <tr>
            <td colspan="5" class="MyTdPadding" align="right">
              <strong>TOTAL</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
              style="border-top: 1px solid black;"
            >
              <strong>${{ formatPrice(item.total) }}</strong>
            </td>
          </tr>
          <tr
            style="background-color: #009879 !important; padding: 0px !important; height: 20px;"
          >
            <td colspan="6"></td>
          </tr>
          <tr v-if="index1 == DetUser.length - 1">
            <td colspan="3" class="MyTdPadding"></td>
            <td colspan="2" class="MyTdPadding">
              <strong>TOTAL PARADOR</strong>
            </td>
            <td class="MyTdPadding" align="right">
              <strong>${{ formatPrice(totalP) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table
        border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse;"
      >
        <thead>
          <tr>
            <td colspan="8" class="MyTdPadding" align="center">
              <strong>Informe de Ventas</strong>
            </td>
          </tr>
          <tr>
            <td class="MyTdPadding" align="center"><strong>Cuenta</strong></td>
            <td colspan="2" class="MyTdPadding"><strong>Concepto</strong></td>
            <td class="MyTdPadding" align="center">
              <strong>Vendidos</strong>
            </td>
            <td class="MyTdPadding" align="center"><strong>Canjes</strong></td>
            <td class="MyTdPadding" align="center"><strong>Total</strong></td>
            <td class="MyTdPadding" align="center">
              <strong>Precio Individual</strong>
            </td>
            <td class="MyTdPadding" style="width:150px;" align="center">
              <strong>Importe</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index1) in DetTicket" :key="`DetTicket-${index1}`">
            <td class="MyTdPadding">{{ item.Cuenta }}</td>
            <td colspan="2" class="MyTdPadding">{{ item.Concepto }}</td>
            <td class="MyTdPadding" align="center">{{ formatNum(item.Cantidad) }}</td>
            <td class="MyTdPadding" align="center">{{ formatNum(item.Canje) }}</td>
            <td class="MyTdPadding" align="center">
              {{ formatNum(FixCanje(item.Cantidad) + FixCanje(item.Canje)) }}
            </td>
            <td class="MyTdPadding" align="right">
              ${{ formatPrice(item.PrecioIndividual) }}
            </td>
            <td class="MyTdPadding" align="right" style="width:150px;">
              ${{ formatPrice(item.Importe) }}
            </td>
          </tr>
          <tr>
            <td colspan="3" class="MyTdPadding" align="right">
            </td>
            <td class="MyTdPadding" align="center">
              <strong>{{formatNum(totalVendidos)}}</strong>
            </td>
            <td class="MyTdPadding" align="center">
              <strong>{{formatNum(totalCanje)}}</strong>
            </td>
            <td class="MyTdPadding" align="center">
              <strong>{{formatNum(totalFinal)}}</strong>
            </td>
            <td class="MyTdPadding" align="right">
              <strong>TOTAL</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
              style="border-top: 1px solid black;"
            >
              <strong>${{ formatPrice(DetTotalVen) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table
        border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse;"
        v-if="sitio == 39"
      >
        <thead>
          <tr>
            <td class="MyTdPadding"><strong>Concepto</strong></td>
            <td class="MyTdPadding"><strong>Importe</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <strong>Fideicomiso A:</strong>
            </td>
            <td class="MyTdPadding" align="right">${{ formatPrice(FideA) }}</td>
          </tr>
          <tr>
            <td>
              <strong>Fideicomiso B:</strong>
            </td>
            <td class="MyTdPadding" align="right">${{ formatPrice(FideB) }}</td>
          </tr>
          <tr>
            <td align="right">
              <strong>TOTAL</strong>
            </td>
            <td class="MyTdPadding" align="right">
              <strong>${{ formatPrice(FideA + FideB) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
      <table border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse;">
        <thead>
          <tr>
            <td class="MyTdPadding">
              <strong>Recibos Expedidos:&nbsp;&nbsp; {{ DetTotalRec }}</strong>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colspan="10">
              {{ DetRec }}
            </td>
          </tr>
        </tbody>
      </table>

    </div>
  </v-card>
</template>

<script>
import fileIndex from './Filesindex.vue'
import { formatPrice, formatNum } from "@/utils/NumericTools.js";

export default {
  components:{
    fileIndex
  },
  data() {
    return {
      formatPrice, formatNum,
      DataEmpty: true,
      TxtEmpty: "",
      totalVendidos:0,
      totalCanje:0,
      totalFinal:0,
      //DetUser:        [],
      //DetTicket:      [],
      //totalP:         0,
      //DetRec:         '',
      //DetTotalRec:    0,
      //DetTotalVen:    0,
      Generete: false,
      menu2: false,
      menu3: false,
      date: "",
      date2: "",
      SitiosX: "",
      Sitios: [
        { name: "CHICHEN ITZÁ", id: 1 },
        { name: "El Corchito", id: 17 },
      ],
    };
  },
  props: [
    "titulox",
    "DetUser",
    "DetTicket",
    "totalP",
    "DetTotalVen",
    "DetTotalRec",
    "DetRec",
    "FideB",
    "FideA",
    "sitio",
    "Anexos",
  ],
  mounted(){
    let Cantidad = 0
    let Canje = 0

    this.DetTicket.map(function(item){
      Cantidad += item.Cantidad
      Canje += item.Canje
    })

    this.totalVendidos = Cantidad
    this.totalCanje = Canje
    this.totalFinal = Cantidad + Canje
  },
  methods: {
    FixCanje(item) {
      let ite = 0;
      if (item) {
        ite = item;
      }
      return ite;
    },
  },
};
</script>

<style scoped>
.VcarRowT1 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 7px 10px 26px 24px;
  zoom: 80%;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

@media (max-width: 400px) {
  .VcarRowT1 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>
