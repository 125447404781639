<template>
    <div>
        <div class="MyPaddingV">
            <v-card class="MyTdPadding" style="padding: 20px;">
                <v-row style="zoom:90%">
                    <v-col cols="12" align="center">
                        <h3><strong>Recaudación por Caja</strong></h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="MyForm">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field                   
                                v-model="date"
                                style="height: 10px; !important"
                                label="Seleccione una fecha de inicio"
                                prepend-icon="mdi-calendar"
                                outlined
                                readonly
                                v-bind="attrs"
                                class="Mytext"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            locale="esp"
                            v-model="date"
                            @input="ChangeDate(1)"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="MyForm">
                        <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field                   
                                v-model="date2"
                                style="height: 10px; !important"
                                label="Seleccione una fecha de fin"
                                prepend-icon="mdi-calendar"
                                outlined
                                readonly
                                v-bind="attrs"
                                class="Mytext"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            locale="esp"
                            v-model="date2"
                            @input="ChangeDate(2)"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="MyForm">
                        <v-select prepend-icon="mdi-bank" dense outlined :items="Sitios" v-model="SitiosX" name="id" item-text="name" label="Seleccione un parador" background-color='white' v-on:change="loadPOS"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="MyForm">
                        <v-select prepend-icon="mdi-account" dense outlined :items="Cajas" v-model="CajasX" name="id" item-text="name" label="Seleccione una caja" background-color='white' v-on:change="POSXChange"></v-select>
                    </v-col>
                    <v-col cols="12" sm="12" md="6" class="Mypadding2 MyForm" align="center">

                            <v-btn :loading="Generete" :disabled="Generete" height="40" color="primary" class="ma-2" @click="GenereteX">
                                GENERAR
                            </v-btn>

                            <v-btn :loading="Generete" :disabled="Generete" height="40" color="success" class="ma-2" @click="ExportarX">
                                Exportar
                            </v-btn>

                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div style="padding:20px;" v-if="DataEmpty">
            <h5>{{TxtEmpty}}</h5>
        </div>

        <div id="page-wrap" v-if="DetUser.length > 0" align="center">
          <TableReport1 :sitio="NumSitio" :FideA="FideA" :FideB="FideB" :titulox="titulox" :DetUser="DetUser" :DetTicket="DetTicket" :totalP="totalP" :DetTotalVen="DetTotalVen" :DetTotalRec="DetTotalRec" :DetRec="DetRec" :Anexos="Anexos"/>
        </div>

    </div>
</template>

<script>
import { exportExcelJavascript, sumarDias } from "@/utils/ReportsTools.js";
import $ from "jquery";
import axios from 'axios'
import TableReport1 from './TablaReport1.vue'

export default {
    components: {
        TableReport1
    },
    data(){
        return{
            NumSitio:       0,
            FideA:          0,
            FideB:          0,
            titulox:        '',
            DataEmpty:      true,
            TxtEmpty:       '',
            DetUser:        [],
            DetTicket:      [],
            Cajas:          [],
            CajasX:         null,
            CajasY:         null,
            totalP:         0,
            DetRec:         '',
            DetTotalRec:    0,
            DetTotalVen:    0,
            Generete:       false,
            menu2:          false,
            menu3:          false,
            date:           '2021-10-19',
            date2:          (new Date(Date.now())).toISOString().substr(0, 10),
            SitiosX:        null,
            Anexos:         [],
            Sitios:[
                {name:'CHICHEN ITZÁ', id:1},
                {name:'El Corchito', id:17},
            ],
        }
    },
    mounted(){
        this.Sitios = this.$root.siteX
        this.date   = sumarDias(new Date(Date.now()), -1)
        this.date2  = sumarDias(new Date(Date.now()), -1)
    },
    methods: {
        ExportarX: function(event){
            if(!this.SitiosX){
                this.$swal({
                    icon: 'warning',
                    title: 'Seleccione un Parador',
                })
                return false
            }

            if(!this.CajasX){
                this.$swal({
                    icon: 'warning',
                    title: 'Seleccione un Caja',
                })
                this.Generete = false
                return false
            }

            if(this.DetUser.length == 0){
                this.$swal({
                    icon: 'warning',
                    title: 'Genera el reporte!',
                })
                return false
            }

            var table= document.getElementById('page-wrap');
            var html = table.outerHTML;
            exportExcelJavascript(html, event, this.titulox)
        },
        GenereteX: function (event) {
            this.DetUser        = []
            this.Generete       = true
            this.DetTotalRec    = 0
            if(!this.SitiosX){
                this.$swal({
                    icon: 'warning',
                    title: 'Seleccione un Parador',
                })
                this.Generete = false
                return false
            }

            if(!this.CajasX){
                this.$swal({
                    icon: 'warning',
                    title: 'Seleccione un Caja',
                })
                this.Generete = false
                return false
            }

            var SitiosX =this.Sitios.find( itemx => itemx.name === this.SitiosX );

            var CajasY  = this.Cajas.find( itemx => itemx.name === this.CajasX );
            var name = 'Recaudación - '+this.SitiosX+' - '+this.CajasX+' -  de '+this.date+' a '+this.date2;
            this.titulox = name
            this.NumSitio = SitiosX.id

            axios
            .post(this.$root.myserver + this.$root.myport + "/Report/ReportRecaudacionC",
            {
                SitiosX:  SitiosX.id,
                CajasY:   CajasY.id,
                dateX:    this.date,
                dateY:    this.date2,
                token:    this.$root.token.token
            })
            .then(response => {
                this.DataEmpty =   false
                if(response.data[0]){
                    if(response.data[0][0]){
                        if(response.data[0][0].success == "FALSE"){
                            this.$swal({
                                icon: 'warning',
                                title: response.data[0][0].message,
                            })
                            this.$parent.$parent.Exit()
                            return false
                        }
                    }else{
                        this.DataEmpty  =   true
                        this.TxtEmpty   =   this.$parent.TxtSinDatos
                    }
                }
                //console.log(response.data);
                var DataX       = []
                let totalP      = 0
                let totalV      = 0
                let DetUse      = response.data[0]
                this.DetTicket  = response.data[1]
                let DetRep  	= response.data[2]
                let Fido  	    = response.data[4]
                this.Anexos     = response.data[5]
                let TempTxt     = ''
                this.FideA      = 0
                this.FideB      = 0

                for(var i = 0; i < DetUse.length; i++){
                    var B1 = 0

                    if(DetUse[i].Jsonx){
                        DetUse[i] = JSON.parse(DetUse[i].Jsonx)
                        var excedente;
                        var efectivo;
                        var tarjeta;
                        var array = []
                        var totalX = 0

                        for( var x=0; x < DetUse[i].length; x++){
                            B1 = B1 + DetUse[i][x].B
                        }
                        totalX = totalX + B1
                        //this.FideB = this.FideB + B1

                        excedente = {Usuario:DetUse[i][0].Usuario, Caja:DetUse[i][0].Caja, total:B1, CuentaBancaria:DetUse[i][0].CuentaBancaria, TipoPago: "EFECTIVO", Cuota:'Excedente Cuotas Extranjero'}

                        var r1 = DetUse[i].find( itemx => itemx.TipoPago === "EFECTIVO" );
                        
                        if(r1){
                            efectivo = {Usuario:r1.Usuario, Caja:r1.Caja, total:r1.total - B1, CuentaBancaria:r1.CuentaBancaria, TipoPago: "EFECTIVO", Cuota:'Propios'}
                        }else{
                            efectivo = {Usuario:DetUse[i][0].Usuario, Caja:DetUse[i][0].Caja, total:0 - B1, CuentaBancaria:'-', TipoPago: "EFECTIVO", Cuota:'Propios'}
                        }
                        totalX = totalX + efectivo.total

                        var r2 = DetUse[i].find( itemx => itemx.TipoPago === "TARJETA" );
                        if(r2){
                            tarjeta = {Usuario:r2.Usuario, Caja:r2.Caja, total:r2.total, CuentaBancaria:r2.CuentaBancaria, TipoPago: "TARJETA", Cuota:'Propios'}
                        }else{
                            tarjeta = {Usuario:DetUse[i][0].Usuario, Caja:DetUse[i][0].Caja, total:0, CuentaBancaria:'-', TipoPago: "TARJETA", Cuota:'Propios'}
                        }
                        totalX = totalX + tarjeta.total

                        totalP = totalP + totalX

                        //cobro de extranjero adulto en caso de que sea chichen affy el sitio
                        if(SitiosX.id == 39){
                            array.push(excedente)
                        }
                        array.push(efectivo)
                        array.push(tarjeta)

                        DataX.push({dataX:array, total:totalX})
                    }

                }
                //this.FideA = totalP - this.FideB
                if(Fido[0]){
                    this.FideB = Fido[0].B
                    this.FideA = Fido[0].A
                }else{
                    this.FideA = 0
                    this.FideB = 0
                }
                
                this.totalP     = totalP
                this.DetUser    = DataX

                for(var i=0; i < this.DetTicket.length; i++){
                    totalV = totalV + this.DetTicket[i].Importe
                }
                this.DetTotalVen = totalV

                for(var i=0; i < DetRep.length; i++){
                    if(i > 0){
                        TempTxt = TempTxt + ', '    
                    }
                    TempTxt = TempTxt + DetRep[i].Expedidos
                }

                this.DetRec         = TempTxt
                this.DetTotalRec    = DetRep.length
                this.Generete       = false
                this.$parent.ShowPrinter    = true

                this.$root.token.token = response.data[3][0].token
                this.$parent.$parent.SetToken()
            })
            .catch(error => {
                console.log(error)
            })
            .finally(() => {
            })

            
        },
        ChangeDate(menu){
            if(menu == 1){
                this.menu2 = false
            }else{
                this.menu3 = false
            }

            this.loadPOS()
        },
        POSXChange(){
            this.DetUser = []
        },
        loadPOS(){
            
            if(this.SitiosX){
                this.Cajas      = []
                this.CajasX     = null
                this.CajasY     = null

                var SitiosX = this.Sitios.find( itemx => itemx.name === this.SitiosX );

                axios
                .post(this.$root.myserver + this.$root.myport + "/Report/GetRecaudacionCajas",
                {
                    SitiosX:  SitiosX.id,
                    dateX:    this.date,
                    dateY:    this.date2
                })
                .then(response => {
                    this.Cajas = response.data
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                })
            }
        }
    }
}
</script>


<style scoped>
    .MyForm{
        height: 75px;
    }
    #page-wrap{
        margin-top:20px;
        /* height: calc(100vh - 170px); */
        height: calc(100vh - 168px);
        overflow: auto; 
        padding: 7px 10px 26px 24px;
        zoom: 80%;
        margin-right: 10px;
    }
    .MySeparator{
        background: #9a9a9a;
        height: 20px;
    }
    .MyBorder{
        border-top: 1px solid black;
    }
    .MyTdPadding{
        padding: 5px;
    }
    .MyPaddingV{
        margin: 25px 20px 25px 20px;
        zoom: 90%;
    }
    .Mytext .v-input__control div fieldset{
        max-height: 45px;
    }
    .Mytext .v-input__control div input{
            margin-top: -8px;
    }
    .Mytext .v-input__prepend-outer{
        margin: 9px !important;
    }
    .MyPadding{
        padding: 20px;
    }
    .Mypadding2{
        padding: 4px !important;
    }
</style>