import { render, staticRenderFns } from "./TabalMayoGob2.vue?vue&type=template&id=02f5d6bc&scoped=true&"
import script from "./TabalMayoGob2.vue?vue&type=script&lang=js&"
export * from "./TabalMayoGob2.vue?vue&type=script&lang=js&"
import style0 from "./TabalMayoGob2.vue?vue&type=style&index=0&id=02f5d6bc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02f5d6bc",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VBtn,VCard,VCardText,VCardTitle,VDataTable,VIcon,VProgressLinear,VSimpleTable,VSpacer})
