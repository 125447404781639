<template>
    <div style="width: 100%;">
        <div style="background:black; color:white;">
            <div style="padding:10px;">
                <h3>Wizzard</h3>
            </div>
        </div>
        
        <div class="row">
            <div class="col-10">
                <div style="padding-bottom:10px;">
                    <h3>Datos</h3>
                </div>
            </div>
            <div class="col-2">
                <div style="padding-bottom:10px;">
                    <h3>Campos</h3>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-10">
                <v-row class="noselect" v-for="(element, index) in list1" :key="'Row-'+index">
                    <v-col class='drop-zone Mycell' draggable
                        :id="index+'-'+index2"
                        @drop='onDrop($event, 1)' 
                        @dragover.prevent
                        @dragenter.prevent
                        @dragstart='startDrag($event, element2, index)'
                        v-for="(element2, index2) in element" :key="'Col-'+index2"
                    >
                        {{element2.name}}
                        <v-btn class="mx-2" @click="remov(index,index2)" fab dark small color="primary" style="zoom:60%;" v-if="element2.id != null">
                            <v-icon dark>
                                mdi-close
                            </v-icon>
                        </v-btn>    
                    </v-col>
                    <v-col cols="1" style="max-width: 50px !important; padding: 5px;">
                        <v-btn small color="primary" dark style="min-width: 38px; min-height: 35px; font-size: 20px; padding: 0;">
                            <i class='bx bxs-cog'></i>
                        </v-btn>
            
                    </v-col>
                </v-row>
            </div>

            <div class="col-2">
                
                    <div :id="element.id" @dragstart='startDrag($event, element)' class="list-group-item" draggable v-for="(element, index) in list2" :key="element.name">
                        {{ element.name }} {{ index }}
                    </div>

            </div>
        </div>
    </div>
</template>

<script>
import draggable from 'vuedraggable' 

var VarDrag = null
var VarList = null

export default {    
    name: "two-lists",
  display: "Two Lists",
  order: 1,
  components: {
    draggable
  },
  data() {
    return {
      list1: [
          [
            { name: "", id: null },
            { name: "", id: null },
            { name: "", id: null }
          ],
          [
            { name: "", id: null }
          ]
      ],
      list2: [
        { name: "Juan", id: 0 },
        { name: "Edgard", id: 1 },
        { name: "Johnson", id: 2 }
      ]
    };
  },
  mounted(){
      this.list2.sort((a, b) => a.name.localeCompare(b.name))
  },
  methods: {
    mouseDown(index, index2){
        
    },
    startDrag: (evt, item, list) => {
      evt.dataTransfer.dropEffect    = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      VarList                        = list
      VarDrag                        = item.id
    },
    onDrop (evt, list) {
      var rowx = evt.currentTarget.id.split("-")
      
      if(VarList == null){
        var fiel                     = this.list2.find(element => element.id === VarDrag)
        var index                    = this.list2.findIndex(element => element.id === VarDrag)

        if(this.list1[rowx[0]][rowx[1]].id == null){
            this.list1[rowx[0]][rowx[1]] = JSON.parse(JSON.stringify(fiel))
            this.list2.splice(index, 1)
        }else{
            var aux = JSON.parse( JSON.stringify(this.list1[rowx[0]][rowx[1]]) )
            this.list1[rowx[0]][rowx[1]] = JSON.parse(JSON.stringify(fiel))
            this.list2.splice(index, 1)
            this.list2.push(aux)
            this.list2.sort((a, b) => a.name.localeCompare(b.name))
        }
        
        
        var list1 = this.list1
        this.list1 = JSON.parse(JSON.stringify(list1))


      }else{
        var fiel                     = this.list1[VarList].find(element => element.id === VarDrag)
        var index                    = this.list1[VarList].findIndex(element => element.id === VarDrag)

        if(this.list1[rowx[0]][rowx[1]].id == null){
            this.list1[rowx[0]][rowx[1]] = JSON.parse(JSON.stringify(fiel))
            this.list1[VarList][index]   = { name: "", id: null }
        }else{
            var aux = JSON.parse(JSON.stringify(this.list1[rowx[0]][rowx[1]]))
            this.list1[rowx[0]][rowx[1]] = JSON.parse(JSON.stringify(fiel))
            this.list1[VarList][index]   = aux
        }        

        var ListaX = this.list1
        this.list1 = JSON.parse( JSON.stringify(ListaX) )

      }

    },
    remov(index1, index2){
        var aux = JSON.parse(JSON.stringify(this.list1[index1][index2]))
        this.list1[index1][index2]   = { name: "", id: null }
        var ListaX = this.list1
        this.list1 = JSON.parse( JSON.stringify(ListaX) )
        this.list2.push(aux)
        this.list2.sort((a, b) => a.name.localeCompare(b.name))
    }
  }
}

</script>

<style scoped>
    .Mycell{
        min-width: 30px;
        min-height: 46px;
        background: white;
        border-bottom: 1px dashed;
        border-right: 1px dashed;
    }
    .Mycell:hover{
        cursor:grab;
    }
    .noselect{
        -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
</style>