<template>
  <v-card class="VcarRowT2">
    <table style="width: 100%">
      <tr align="center">
        <td colspan="6" style="font-weight: bold; font-size: 20px">
          <strong>{{ titulox }}</strong>
        </td>
      </tr>
    </table>

    <br />
    <div style="width: 100%; overflow: auto">
      <table
        border="1"
        bordercolor="#272727"
        style="border-collapse: collapse; font-size: 0.7em"
        class="styled-table"
      >
        <thead>
          <tr>
            <td align="center"><strong>TRANSACCIÓN</strong></td>
            <td align="center"><strong>FECHA</strong></td>
            <td align="center"><strong># CAJA</strong></td>
            <td align="center"><strong>CAJA</strong></td>
            <td align="center"><strong>SUBTOTAL</strong></td>
            <td align="center"><strong>CANCELACIÓN</strong></td>
            <td align="center"><strong>TOTAL</strong></td>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, index) in DetTrans">
            <tr :key="`Transaction-${index}`">
              <td align="center">
                <strong>{{ item.transac }}</strong>
              </td>
              <td align="center">
                <strong>{{ item.fecha + " " + item.hora }}</strong>
              </td>
              <td align="center">
                <strong>{{ item.idcaja }}</strong>
              </td>
              <td align="center">
                <strong>{{ item.caja }}</strong>
              </td>
              <td align="center">
                <strong>{{ item.sub }}</strong>
              </td>
              <td align="center">
                <strong>{{ item.cancel }}</strong>
              </td>
              <td align="center">
                <strong>{{ item.total }}</strong>
              </td>
            </tr>
            <tr :key="`DetTrans-${index}`">
              <td colspan="7" style="padding: 0px 8px !important; border-bottom: none !important">
                <table
                  border="1"
                  class="styled-table"
                  style="border-collapse: collapse; font-size: 0.7em; width: 100%; margin-top: 5px; margin-bottom: 5px"
                >
                  <thead>
                    <tr>
                      <td align="center"><strong>ESTADO</strong></td>
                      <td align="center"><strong>SERIE</strong></td>
                      <td align="center"><strong># CUENTA CONTABLE</strong></td>
                      <td align="center"><strong>CUENTA CONTABLE</strong></td>
                      <td align="center"><strong>DESCRIPCIÓN</strong></td>
                      <td align="center"><strong>CANTIDAD</strong></td>
                      <td align="center"><strong>PRECIO UNITARIO</strong></td>
                      <td align="center"><strong>SUBTOTAL</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item2, index2) in item.elements" :key="`Transaction-${index}${index2} `">
                      <td align="center">
                        <strong>{{ item2.sEstatus }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ item2.NSERIENNR }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ item2.IDNodoCuentaContable }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ item2.CuentaContable }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ item2.Descripcion }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ item2.CountTickets }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ item2.subtotal }}</strong>
                      </td>
                      <td align="center">
                        <strong>{{ item2.subtotal * item2.CountTickets }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr :key="`suptrans${index}`" style="height: 15px !important">
              <td colspan="7" style="background-color: white !important; border-top: none !important; border-bottom: none !important;"></td>
            </tr>
            <tr :key="`inftrans${index}`" style="height: 15px !important">
              <td colspan="7" style="background-color: white !important; border-top: none !important;"></td>
            </tr>
          </template>
          <tr>
            <td colspan="4" class="MyTdPadding" align="right">
              <strong>SUBTOTAL</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
              style="border-top: 1px solid black"
            >
              <strong>${{ formatPrice(totalX.subtotal)}}</strong>
            </td>
            <td  class="MyTdPadding" align="right">
              <strong>TOTAL</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
              style="border-top: 1px solid black"
            >
              <strong>${{ formatPrice(totalX.total)}}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
import { formatPrice } from "@/utils/NumericTools.js";

export default {
  components: {},
  data() {
    return {
      formatPrice
    };
  },
  props: ["titulox", "DetTrans", "totalX"],
  methods: {
  },
};
</script>

<style scoped>
.VcarRowT2 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.PieX {
  height: 100% !important;
}
.PieX div {
  height: 100% !important;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em !important;
  font-family: sans-serif !important;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.TotalX {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
  font-size: 18px !important;
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 1) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 0) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  background: rgba(160, 255, 211, 0.6);
}
@media (max-width: 400px) {
  .VcarRowT2 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>
