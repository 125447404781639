<template>
  <div class="modal-x">
    <v-card class="card-modal-x" outlined>
      <v-toolbar dark>
        <v-toolbar-title>{{ itemPrint.RefOri }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon dark @click="$emit('closeDialog')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-title style="padding: 5px 25px 5px 26px;" v-if="noDataPrint == null">
        <strong v-if="type == 2">
          {{ itemPrint.descripcion }} <span v-if="itemPrint.alias"> ({{ itemPrint.alias }})</span>
        </strong> 
        <v-spacer></v-spacer>
        <v-btn height="40" color="success" class="ma-2 ml-7" @click="ExportarX">
          Exportar
        </v-btn>
      </v-card-title>
      <v-card-text v-if="noDataPrint">
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" >
          {{textNoDataPrint}} 
        </v-alert>
      </v-card-text>
      <v-card-text class="px-10 pb-3" v-else>
        <div v-if="type == 1">
          <v-simple-table
            class="w-100"
            v-for="(sitio, index) in dataItemPrint"
            :key="'sitios-' + index"
          >
            <thead>
              <tr>
                <td
                  style="
                    background: white;
                    color: black;
                    padding: 0 !important;
                    padding-left: 5px !important;
                    height: 40px;
                    font-size: 15px;
                  "
                >
                  <strong
                    ><h5>{{ sitio.namesSitio }}</h5></strong
                  >
                </td>
              </tr>
            </thead>
            <tbody>
              <tr style="background-color: rgba(255, 165, 0, 0.4)">
                <td>
                  <v-simple-table>
                    <thead>
                      <tr style="background: rgb(253 142 48 / 22%); color: black">
                        <td class="MyTdPadding" align="center">
                          <strong>Tipo de boleto</strong>
                        </td>
                        <td class="MyTdPadding">
                          <strong>Nombre del Boleto</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>Precio Unitario</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>Clave de transacción de Impresión</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>Fecha Impresión</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>Cantidad</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>Descripción</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>Solicitó</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(sitioTicket, index) in sitio.data"
                        :key="`sitioTicketsP-${sitioTicket.id_ticket}-${index}`"
                      >
                        <td class="MyTdPadding" align="center" width="10%">
                          <strong>{{ sitioTicket.id_ticket }}</strong>
                        </td>
                        <td class="MyTdPadding" width="25%">
                          <strong>{{ sitioTicket.descripcion }} </strong>  
                        </td>
                        <td class="MyTdPadding" align="center" width="10%">
                          <strong>${{ formatPrice(sitioTicket.costo) }} MXN</strong>
                        </td>
                        <td class="MyTdPadding" align="center" width="20%">
                          <strong>{{ sitioTicket.clave }}</strong>
                        </td>
                        <td class="MyTdPadding" align="center" width="6.66%">
                          {{ sitioTicket.f_solicitud }}
                        </td>
                        <td class="MyTdPadding" align="center" width="6.66%">
                          {{ sitioTicket.cantidad }}
                        </td>
                        <td class="MyTdPadding" align="center" width="15%">
                          {{ sitioTicket.descript }}
                        </td>
                        <td class="MyTdPadding" align="center" width="6.66%">
                          <strong>{{ sitioTicket.solicito }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <div :id="`history${this.type}-Tickets-wrap`" v-show="false">
            <table style="width:100%;">
              <tr align="right">
                <td colspan="8" style="font-size: 16px;">
                  <strong>Línea de Referencia: </strong>  {{itemPrint.RefOri}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="8" style="font-size: 16px;">
                  <strong>Compañía: </strong>  {{itemPrint.CorpName}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="8" style="font-size: 16px;">
                  <strong>Fecha de Solicitud: </strong>  {{itemPrint.fecha}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="8" style="font-size: 16px;">
                  <strong>Total: </strong>  {{itemPrint.total}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="8" style="font-size: 16px;">
                  <strong>Total Boletos: </strong>  {{itemPrint.cBoletos}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="8" style="font-size: 16px;">
                  <strong>Autorizó: </strong>  {{itemPrint.autorizo}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="8" style="font-size: 16px;">
                  <strong>Fecha autorización: </strong>  {{itemPrint.f_autorizacion}}
                </td>
              </tr>
              <tr></tr>
            </table>
            

            <div v-for="(sitio, index) in dataItemPrint" :key="'sitios-Printed-' + index">
              <table
                class="styled-table"
                style="border-collapse: collapse; width:100%;"
              >
                <thead>
                  <tr>
                    <td
                      style="
                        background: white;
                        color: black;
                        padding: 0 !important;
                        padding-left: 5px !important;
                        height: 40px;
                        font-size: 15px;
                      "
                    >
                      <strong
                        ><h5>{{ sitio.namesSitio }}</h5></strong
                      >
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr style="background-color: rgba(255, 165, 0, 0.4)">
                    <td>
                      <table
                        border="1"
                        bordercolor="#272727"
                        class="styled-table"
                      >
                        <thead>
                          <tr style="background: rgb(253 142 48 / 22%); color: black">
                            <td class="MyTdPadding" align="center">
                              <strong>Tipo de boleto</strong>
                            </td>
                            <td class="MyTdPadding">
                              <strong>Nombre del Boleto</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Precio Unitario</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Clave de transacción de Impresión</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Fecha Impresión</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Cantidad</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Descripción</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Solicitó</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(sitioTicket, index) in sitio.data"
                            :key="`sitioTicketsPrinted-${sitioTicket.id_ticket}-${index}`"
                          >
                            <td class="MyTdPadding" align="center" width="10%">
                              <strong>{{ sitioTicket.id_ticket }}</strong>
                            </td>
                            <td class="MyTdPadding" width="25%">
                              <strong>{{ sitioTicket.descripcion }} </strong>  
                            </td>
                            <td class="MyTdPadding" align="center" width="10%">
                              <strong>${{ formatPrice(sitioTicket.costo) }} MXN</strong>
                            </td>
                            <td class="MyTdPadding" align="center" width="20%">
                              <strong>{{ sitioTicket.clave }}</strong>
                            </td>
                            <td class="MyTdPadding" align="center" width="6.66%">
                              {{ sitioTicket.f_solicitud }}
                            </td>
                            <td class="MyTdPadding" align="center" width="6.66%">
                              {{ sitioTicket.cantidad }}
                            </td>
                            <td class="MyTdPadding" align="center" width="15%">
                              {{ sitioTicket.descript }}
                            </td>
                            <td class="MyTdPadding" align="center" width="6.66%">
                              <strong>{{ sitioTicket.solicito }}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div v-if="type == 2">
          <v-simple-table
          >
            <tbody>
              <tr style="background-color: rgba(255, 165, 0, 0.4)">
                <td>
                  <v-simple-table>
                    <thead>
                      <tr style="background: rgb(253 142 48 / 22%); color: black">
                        <td class="MyTdPadding" align="center" width="33.33%">
                          <strong>Solicitó</strong>
                        </td>
                        <td class="MyTdPadding" align="center" width="33.33%">
                          <strong>Fecha de Solicitud</strong>
                        </td>
                        <td class="MyTdPadding" align="center" width="33.33%">
                          <strong>Hora de Solicitud</strong>
                        </td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(ticket, index) in dataItemPrint"
                        :key="`TicketsP-${ticket}-${index}`"
                      >
                        <td class="MyTdPadding" align="center">
                          <strong>{{ticket.name}}</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>{{ticket.fecha}}</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>{{ticket.hora}}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </v-simple-table>
                </td>
              </tr>
            </tbody>
          </v-simple-table>

          <div :id="`history${this.type}-Tickets-wrap`" v-show="false">
            <table style="width:100%;">
              <tr align="right">
                <td colspan="6" style="font-size: 16px;">
                  <strong>Clave de transacción: </strong>  {{itemPrint.RefOri}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="6" style="font-size: 16px;">
                  <strong>Boleto: </strong>  {{ itemPrint.descripcion }} <span v-if="itemPrint.alias"> ({{ itemPrint.alias }})</span>
                </td>
              </tr>
              <tr align="right">
                <td colspan="6" style="font-size: 16px;">
                  <strong>Compañía: </strong>  {{itemPrint.CorpName}}
                </td>
              </tr>
              <tr></tr>
            </table>
            

            <table style="width:100%;" border="1" bordercolor="#272727" class="styled-table">
              <thead>
                <tr>
                  <td class="MyTdPadding" align="center" width="33.33%" colspan="2">
                    <strong>Solicitó</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="33.33%" colspan="2">
                    <strong>Fecha de Solicitud</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="33.33%" colspan="2">
                    <strong>Hora de Solicitud</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(ticket, index) in dataItemPrint"
                  :key="`TicketsPrinted-${ticket}-${index}`"
                >
                  <td class="MyTdPadding" align="center" colspan="2">
                    <strong>{{ticket.name}}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" colspan="2">
                    <strong>{{ticket.fecha}}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" colspan="2">
                    <strong>{{ticket.hora}}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { formatPrice } from "@/utils/NumericTools.js";
import { exportExcelJavascript, sumarDias, formatFecha } from "@/utils/ReportsTools.js";

export default {
  name: "ModalTable",
  props: [
    "dialog", "itemPrint", "dataItemPrint", "type", "noDataPrint", "textNoDataPrint"
  ],
  data(){
    return{
      formatPrice,
      nowDate: ''
    }
  },
  mounted(){
    this.nowDate = sumarDias(new Date(Date.now()), 0)
  },
  methods:{
    ExportarX: function(event){
      if(this.dataItemPrint.length == 0){
        this.$swal({
          icon: 'warning',
          title: 'no hay nada que exportar!',
        })
        return false
      }


      var namePre = ''
      switch (this.type) {
        case 1:
          namePre += 'impresiones_' + this.itemPrint.RefOri + '-a-' + formatFecha(this.nowDate, '-');
          break;
        case 2:
          namePre += 'historial_impresiones_' + this.itemPrint.RefOri + '_'+ this.itemPrint.id_ticket + '_'+  '-a-' + formatFecha(this.nowDate, '-');
          break;
      }
      var table= document.getElementById(`history${this.type}-Tickets-wrap`);
      var html = table.outerHTML;
      exportExcelJavascript(html, event, namePre);
    },
  }
};
</script>

<style scoped>
.modal-x {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.card-modal-x {
  min-width: 100%;
  min-height: 100%;
}
</style>