<template>
  <div>
    <v-card class="w-100 pa-5 bt-1">
      <div v-if="loading"><br><br><br></div>
      <v-card-text v-if="noDataItem">
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" >
          No hay boletos disponibles!
        </v-alert>
      </v-card-text>
      <div class="mt-0" v-else>

        <v-card-title class="pa-0">
          <v-spacer></v-spacer>
          <v-btn height="40" color="success" class="ma-2" @click="ExportarX">
            Exportar
          </v-btn>
        </v-card-title>
        <v-simple-table
          class="w-100"
          v-for="(sitio, index) in items"
          :key="'sitios-' + index"
        >
          <thead>
            <tr>
              <td
                style="
                  background: white;
                  color: black;
                  padding: 0 !important;
                  padding-left: 5px !important;
                  height: 40px;
                  font-size: 15px;
                "
              >
                <strong
                  ><h5>{{ sitio.shortname }}</h5></strong
                >
              </td>
            </tr>
          </thead>
          <tbody>
            <tr style="background-color: rgba(255, 165, 0, 0.4)">
              <td>
                <v-simple-table>
                  <thead>
                    <tr style="background: rgb(253 142 48 / 22%); color: black">
                      <td class="MyTdPadding" align="center">
                        <strong>Tipo de boleto</strong>
                      </td>
                      <td class="MyTdPadding">
                        <strong>Nombre del Boleto</strong>
                      </td>
                      <td class="MyTdPadding" align="center">
                        <strong>Fecha Limite de uso</strong>
                      </td>
                      <td class="MyTdPadding" align="center">
                        <strong>Comprado(s)</strong>
                      </td>
                      <td class="MyTdPadding" align="center">
                        <strong>Impreso(s)</strong>
                      </td>
                      <td class="MyTdPadding" align="center">
                        <strong>Disponible(s)</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(sitioTicket, index12) in sitio.data"
                      :key="`sitioTickets-${sitioTicket.id_ticket}-${index12}-${sitio.sitio}`"
                    >
                      <td class="MyTdPadding" align="center" width="14%">
                        <strong>{{ sitioTicket.id_ticket }}</strong>
                      </td>
                      <td class="MyTdPadding" width="30%">
                        <strong>{{ sitioTicket.descripcion }} </strong>  
                      </td>
                      <td class="MyTdPadding" align="center" width="14%">
                        <strong>{{ sitioTicket.fechaFin }}</strong>
                      </td>
                      <td class="MyTdPadding" align="center" width="14%">
                        <strong>{{ formatNum(sitioTicket.cantidad) }}</strong>
                      </td>
                      <td class="MyTdPadding" align="center" width="14%">
                        <strong>{{ formatNum(sitioTicket.usados) }}</strong>
                      </td>
                      <td class="MyTdPadding" align="center" width="14%">
                        <strong>{{ formatNum(sitioTicket.total) }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </td>
            </tr>
          </tbody>
        </v-simple-table>


        <div style="width:100%; overflow:auto" v-show="false" id="pageTA-wrap">

          <table style="width:100%;">
            <tr align="right">
              <td colspan="6" style="font-size: 16px;">
                <strong>Boletos Disponibles de: </strong> {{nameCorp}}
              </td>
            </tr>
            <tr align="right">
              <td colspan="6" style="font-size: 16px;">
                <strong>Al día: </strong> {{formatFecha(nowDate, '/')}}
              </td>
            </tr>
          </table>

          <div v-for="(sitio, index1) in items" :key="`sitio-${sitio.id}-${index1}`">
            <table style="width:100%;">
              <tr></tr>
              <tr align="center">
                <td colspan="6" style="font-size: 18px;">
                  <strong>{{ sitio.shortname }}</strong>
                </td>
              </tr>
            </table>
            <table
              border="1"
              bordercolor="#272727"
              class="styled-table"
              style="border-collapse: collapse; width:100%;"
            >
              <thead>
                <tr>
                  <td class="MyTdPadding" align="center">
                    <strong>Tipo de boleto</strong>
                  </td>
                  <td class="MyTdPadding">
                    <strong>Nombre del Boleto</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>Fecha Limite de uso</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>Comprado(s)</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>Impreso(s)</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>Disponible(s)</strong>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(sitioTicket, index13) in sitio.data"
                  :key="`sitioTicketsPrint-${sitioTicket.id_ticket}-${index13}-${sitio.sitio}`"
                >
                  <td class="MyTdPadding" align="center" width="14%">
                    {{ sitioTicket.id_ticket }}
                  </td>
                  <td class="MyTdPadding" width="30%">
                    {{ sitioTicket.descripcion }}
                  </td>
                  <td class="MyTdPadding" align="center" width="14%">
                    {{ sitioTicket.fechaFin }}
                  </td>
                  <td class="MyTdPadding" align="center" width="14%">
                    {{ formatNum(sitioTicket.cantidad) }}
                  </td>
                  <td class="MyTdPadding" align="center" width="14%">
                    {{ formatNum(sitioTicket.usados) }}
                  </td>
                  <td class="MyTdPadding" align="center" width="14%">
                    {{ formatNum(sitioTicket.total) }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </v-card>
    <v-progress-linear
      v-if="loading"
      color="orange"
      :height="5"
      indeterminate
    ></v-progress-linear>
  </div>
</template>

<script>
import { formatNum } from "@/utils/NumericTools.js";
import { exportExcelJavascript, formatFecha } from "@/utils/ReportsTools.js";

  export default {
    name: 'TablaTicketsAll',
    props: ["items", "noDataItem", "loading", "nameCorp", "nowDate"],
    data(){
      return{
        formatNum, formatFecha,
        Generete: false,
      }
    },
    methods:{
      ExportarX: function(event){
        if(this.items.length == 0){
          this.$swal({
            icon: 'warning',
            title: 'no hay nada que exportar!',
          })
          return false
        }
        var table= document.getElementById('pageTA-wrap');
        var html = table.outerHTML;
        exportExcelJavascript(html, event, `${this.nameCorp.replaceAll(/\s+/g, "_")}-boletos_disponibles-a-${formatFecha(this.nowDate, '-')}`)
      },
    }
  }
</script>

<style scoped>
.VcarRowT1 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

@media (max-width: 400px) {
  .VcarRowT1 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>