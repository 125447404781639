var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"width":"100%"},attrs:{"id":"api"}},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" Bitacoras de envio ")])],1),_c('v-tabs',{attrs:{"dark":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tabs-slider',{attrs:{"color":"orange"}}),_c('v-tab',{key:"tab-1",attrs:{"id":"tab1"}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-clipboard-text-outline")]),_vm._v(" Enviados ")],1),_c('v-tab',{key:"tab-2",attrs:{"id":"tab2"}},[_c('v-icon',{staticClass:"ma-2"},[_vm._v("mdi-clipboard-text-clock-outline")]),_vm._v(" Fuera del registro ")],1)],1),_c('v-tabs-items',{staticClass:"MyFullDiv",staticStyle:{"background-color":"transparent !important"},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{key:"tab-1"},[_c('v-toolbar',{staticStyle:{"background-color":"#1e1e1e"},attrs:{"dark":""}},[_c('v-spacer'),_c('v-menu',{attrs:{"absolute":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-text-style mr-5",staticStyle:{"margin-top":"30px","zoom":"80%","max-width":"300px"},attrs:{"label":"Seleccionar fecha","outlined":"","dark":"","color":"orange","placeholder":"Seleccionar fecha","prepend-icon":"mdi-calendar"},model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"locale":"esp","max":_vm.nowDate},on:{"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date1),callback:function ($$v) {_vm.date1=$$v},expression:"date1"}})],1),_c('v-text-field',{staticClass:"my-text-style",staticStyle:{"margin-top":"30px","zoom":"80%"},attrs:{"label":"","outlined":"","dark":"","clearable":"","color":"orange","placeholder":"Escribe lo que desea buscar","prepend-icon":"mdi-magnify"},model:{value:(_vm.search1),callback:function ($$v) {_vm.search1=$$v},expression:"search1"}})],1),_c('div',{staticStyle:{"margin-top":"0px","padding-top":"1px","height":"calc(100vh - 178px)","overflow":"auto"}},[_c('v-card',{staticStyle:{"margin":"20px","zoom":"90%"}},[(_vm.noData1)?_c('v-card-text',[_c('v-alert',{staticClass:"m-1",attrs:{"icon":"bx bxs-traffic-barrier","prominent":"","text":"","type":"warning","align":"left"}},[_vm._v(" No hay bitacoras para el día "+_vm._s(_vm.date1)+" ")])],1):_c('v-data-table',{staticClass:"styled-table m-4 mt-2",attrs:{"headers":_vm.headers1,"items":_vm.items1,"item-key":"id","sort-desc":[true, true],"search":_vm.search1,"multi-sort":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,index){return [_c('tr',{key:'ReportPayment-' + index},[_c('td',{attrs:{"align":"center"}},[_c('strong',[_vm._v(_vm._s(item.iFolioBitacora))])]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.sCodigoRecepcion))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.ConsecutivoEnvio))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.idSucursal))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.idEquipo))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.idCajero))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.cError))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.fechaEnvio))]),(_vm.$root.token.subrol != 4)?_c('td',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.sentBitaAafy(item, 0)}}},[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)],1):_vm._e()])]})],2)]}}])}),(_vm.loadingTable1)?_c('v-progress-linear',{attrs:{"color":"orange","height":5,"indeterminate":""}}):_vm._e()],1)],1)],1),_c('v-tab-item',{key:"tab-2"},[_c('v-toolbar',{staticStyle:{"background-color":"#1e1e1e"},attrs:{"dark":""}},[_c('v-spacer'),_c('v-menu',{attrs:{"absolute":"","close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"my-text-style mr-5",staticStyle:{"margin-top":"30px","zoom":"80%","max-width":"300px"},attrs:{"label":"Seleccionar fecha","outlined":"","dark":"","color":"orange","placeholder":"Seleccionar fecha","prepend-icon":"mdi-calendar"},model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"locale":"esp","max":_vm.nowDate},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date2),callback:function ($$v) {_vm.date2=$$v},expression:"date2"}})],1),_c('v-text-field',{staticClass:"my-text-style",staticStyle:{"margin-top":"30px","zoom":"80%"},attrs:{"label":"","outlined":"","dark":"","clearable":"","color":"orange","placeholder":"Escribe lo que desea buscar","prepend-icon":"mdi-magnify"},model:{value:(_vm.search2),callback:function ($$v) {_vm.search2=$$v},expression:"search2"}})],1),_c('div',{staticStyle:{"margin-top":"0px","padding-top":"1px","height":"calc(100vh - 178px)","overflow":"auto"}},[_c('v-card',{staticStyle:{"margin":"20px","zoom":"90%"}},[(_vm.noData2)?_c('v-card-text',[_c('v-alert',{staticClass:"m-1",attrs:{"icon":"bx bxs-traffic-barrier","prominent":"","text":"","type":"warning","align":"left"}},[_vm._v(" Se han enviado las del día "+_vm._s(_vm.date2)+" ")])],1):_c('v-data-table',{staticClass:"styled-table m-4 mt-2",attrs:{"headers":_vm.headers2,"items":_vm.items2,"item-key":"id","sort-desc":[true, true],"search":_vm.search2,"multi-sort":""},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,index){return [_c('tr',{key:'ReportPayment-' + index},[_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.sucursal))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.name))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.caja))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.IDEquipo))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.IDCajero))]),_c('td',{attrs:{"align":"center"}},[_vm._v(_vm._s(item.NombreCajero))]),(_vm.$root.token.subrol != 4)?_c('td',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"ma-2",attrs:{"small":"","dark":"","color":"red"},on:{"click":function($event){return _vm.sentNoRegistBitaAafy(item)}}},[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1)],1):_vm._e()])]})],2)]}}])}),(_vm.loadingTable2)?_c('v-progress-linear',{attrs:{"color":"orange","height":5,"indeterminate":""}}):_vm._e()],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }