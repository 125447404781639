<template>
  <div class="TablDiv">
    <span style="font-weight: bold; font-size: 20px">{{ titulo }}</span>
    <v-divider class="mx-4" inset vertical color="primary" dark></v-divider>
    <v-btn
      color="primary"
      dark
      class="mb-2"
      @click="nuevo"
      bottom
      v-if="BtnDelete"
    >
      Nuevo
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="desserts"
      sort-by="calories"
      :search="search"
      class="elevation-1 pointmanXX"
      height="70vh"
      :item-class="itemRowBackground"
      :loading="loadingX"
      loading-text="Loading... Please wait"
      :footer-props="{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        //prevIcon: 'mdi-minus',
        //nextIcon: 'mdi-plus'
      }"
      @click:row="handleClick"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-spacer></v-spacer>

          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
        </v-toolbar>
      </template>
      <template v-slot:[`item.actions`]="{ item }" v-if="false">
        <div :style="ButonWith">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="primary"
                class="mx-1 comiton"
                v-bind="attrs"
                v-on="on"
                @click="editItem(item)"
              >
                <v-icon small class="MyIcon"> mdi-pencil </v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>

          <v-tooltip bottom v-if="ShowComision">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="success"
                class="mx-1 comiton"
                v-bind="attrs"
                v-on="on"
                @click="OpenComision(item)"
              >
                <v-icon small class="MyIcon"> attach_money </v-icon>
              </v-btn>
            </template>
            <span>Cuotas</span>
          </v-tooltip>

          <v-tooltip bottom v-if="BtnDelete">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                fab
                dark
                small
                color="error"
                class="mx-1 comiton"
                v-bind="attrs"
                v-on="on"
                @click="deleteItem(item)"
              >
                <v-icon small class="MyIcon"> mdi-delete </v-icon>
              </v-btn>
            </template>
            <span>Eliminar</span>
          </v-tooltip>
        </div>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary"> Reset </v-btn>
      </template>

      <template v-slot:body="{ items }" v-if="usuarios">
        <tbody>
          <template v-for="(item, index) in items">
            <tr :key="'ReportPayment-' + index" @click="handleClick(item)" heigth="480px">
              <td>
                {{ item.id }}
              </td>
              <td>
                {{ item.user }}
              </td>
              <td>
                {{ item.pass }}
              </td>
              <td>
                {{ ifNull(item.name) + " " + ifNull(item.name2) + " " + ifNull(item.name3) }}
              </td>
              <td>
                {{ item.mail }}
              </td>
              <td>
                {{ item.cargo }}
              </td>
              <td>
                {{ userRol[item.rol] }}
              </td>
               <td>
                <i class="bx bxs-user-check" style="font-size: 40px; color: green;" v-if="item.activo"></i>
                <i class="bx bxs-user-x" style="font-size: 40px; color: orange;" v-else></i>
              </td>
              <td>
                <div align="center">
                  <span class="material-icons" v-if="item.admin" style="color: green; font-weight: bold;">
                    done
                  </span>
                </div>
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    loadingX: true,
    Nrow: 0,
    //ButonWith: 'width:120px',
    ShowComision: false,
    styleX: "",
    search: "",
    dialog: false,
    dialogDelete: false,
    //desserts: [],
    editedIndex: -1,
    userRol: ["Promtec", "Aafy", "Cultur", "Coordinador", "Mayorista"]
  }),
  props: [
    "ButonWith",
    "BtnDelete",
    "headers",
    "desserts",
    "titulo",
    "father",
    "usuarios",
  ],
  computed: {},

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.styleX = "style-1";

    switch (this.father) {
      case "CuentaContable":
        this.ShowComision = true;
        //this.ButonWith    = 'width:115px'
        break;
    }
  },
  methods: {
    handleClick(value) {
      this.editItem(value);
    },
    itemRowBackground: function (item) {
      //if(this.styleX == 'style-2'){
      //  this.styleX = 'style-1'
      //  return 'style-1'
      //}else{
      //  this.styleX = 'style-2'
      //  return 'style-2'
      //}
    },
    OpenComision(item) {
      switch (this.father) {
        case "CuentaContable":
          this.$parent.OpenComision(item);
          break;
      }
    },
    //----------------- Aqui cuando seleccionas un row en la tabla
    editItem(item) {
      switch (this.father) {
        case "CuentaContable":
          this.$parent.OpenFormEdit(item);
          break;
        case "Cajas":
          this.$parent.OpenCajaX(item);
          break;
        case "Users":
          this.$parent.OpenUsers(item);
          break;
      }
    },
    nuevo() {
      switch (this.father) {
        case "CuentaContable":
          this.$parent.OpenFormNew();
          break;
      }
    },
    deleteItem(item) {
      switch (this.father) {
        case "CuentaContable":
          this.$parent.deleteItem(item);
          break;
      }
    },
    deleteItemConfirm() {},
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }
      this.close();
    },
    ifNull: function (val) {
      return val == null ? '' : val;
    }
  },
};
</script>

<style scoped>
.pointmanXX:hover {
  cursor: pointer;
}
/* .pointmanXX {
  background: white;
}
.comiton {
  width: 30px !important;
  height: 30px !important;
  box-shadow: none !important;
}
.style-1 {
  background-color: rgb(243, 243, 243);
}
.style-2 {
  background-color: rgb(255, 255, 255);
}
tbody tr:nth-child(odd) {
  background-color: #f6f6f6;
}
.MyIcon {
  font-size: 18px !important;
}

@media only screen and (max-width: 500px) {
  .TablDiv {
    text-align: center;
  }
  .TablDiv hr {
    display: none;
  }
} */
</style>