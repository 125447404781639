<template>
  <div id="RemainingTickets" class="MyWidth">
    <v-toolbar dark>
      <v-toolbar-title> Boletos Restantes </v-toolbar-title>
    </v-toolbar>

    <div style="padding: 20px">
      <v-card style="padding: 20px">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-select
            v-model="select"
            :items="cashRegister"
            label="Seleccione una caja"
            prepend-icon="mdi-magnify"
            single-line
            hide-details
            class="itemCash"
            item-text="caja"
            @change="seachRemainingTickets"
             v-if="false"
          >
            <template v-slot:item="{ item }"> Caja {{ item.caja }} </template>
            <template v-slot:selection="{ item }">
              Caja {{ item.caja }}
            </template>
          </v-select>
          <v-btn class="success" @click="getCashRegisters()">
            Refrescar
          </v-btn>
        </v-card-title>
        <v-data-table :headers="headers" :items="items" class="elevation-1">

          <template v-slot:item="{ item }">
            <tr v-if="!loading">
              <td class="text-center">
                {{ item.caja == 0 ? 29 : item.caja }}
              </td>
              <td class="text-center">
                {{ item.total }}
              </td>
              <td class="text-center">100000</td>
              <td class="text-center" v-if="(100000 - item.total) <= 0">
                {{ 100000 - item.total }}
              </td>
              <td class="text-center red--text" v-else-if="(100000 - item.total) < 15000">
                <strong>{{ 100000 - item.total }}</strong>
              </td>
              <td class="text-center orange--text" v-else-if="(100000 - item.total) < 30000">
                <strong>{{ 100000 - item.total }}</strong>
              </td>
              <td class="text-center green--text" v-else>
                <strong>{{ 100000 - item.total }}</strong>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-progress-linear
          v-if="loading"
          color="orange"
          :height="10"
          indeterminate
        ></v-progress-linear>
      </v-card>
    </div>
  </div>
</template>

<script>
import { setColorHeadFooter } from "@/utils/StyleTools.js";

export default {
  name: "RemainingTickets",
  data() {
    return {
      select: [],
      cashRegister: [],
      headers: [
        { text: "Caja", value: "caja", align: "center" },
        { text: "Boletos Usados", value: "total", align: "center" },
        { text: "Total de Boletos", value: "totalTickets", align: "center" },
        {
          text: "Boletos Restantes",
          value: "remainingTickets",
          align: "center",
        },
      ],
      items: [],
      loading: false,
    };
  },
  mounted() {
    setColorHeadFooter();
    this.getCashRegisters();
  },
  methods: {
    getCashRegisters: function () {
      this.loading = true;
      this.$root.post("/CC/GetCajasMayo").then(
        (response) => {
          let info = response.data;
          console.log(info);

          //this.cashRegister = info;
          //this.loading = false;
          this.items = response.data;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
    seachRemainingTickets: function (item) {
      this.loading = true;
      this.$root.post("/CC/GetDataCajasMayo", {caja: item}).then(
        (response) => {
          this.items = response.data;
          this.loading = false;
        },
        (error) => {
          console.log(error);
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style scoped>
.MyWidth {
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}

.itemCash {
  max-width: 600px !important;
}
</style>