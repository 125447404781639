<template>
  <div>
    <v-toolbar dark style="background-color: #1e1e1e;">
      <v-spacer></v-spacer>

      <v-text-field
        v-if="vencidos == 1"
        v-model="search"
        label=""
        outlined
        dark
        clearable
        color="orange"
        placeholder="Escribe lo que desea buscar"
        prepend-icon="mdi-magnify"
        class="my-text-style"
        style="margin-top: 30px; zoom: 80%;"
      ></v-text-field>
      <v-text-field
        v-if="vencidos == 2"
        v-model="search2"
        label=""
        outlined
        dark
        color="orange"
        placeholder="Escribe la línea de referencia y presione enter"
        v-on:keyup.enter="onEnter"
        clearable
        @click:clear="onClear"
        prepend-icon="mdi-account-search"
        class="my-text-style"
        style="margin-top: 30px; zoom: 80%;"
      ></v-text-field>
    </v-toolbar>
    <v-card style="margin: 20px;" :class="[noData == null ? 'pb-2 pt-1' : '']">
      <v-card-title v-if="noData == null" style="padding: 5px 25px 5px 26px;" >
        <strong  v-if="vencidos == 2">
          <h4>*Limite de busqueda 20 resultados {{`${searchTitle == '' ? '' : ' para: ' + searchTitle }`}}</h4>
        </strong>
        <v-spacer></v-spacer>
        <v-btn height="40" color="success" class="ma-2 ml-7" @click="ExportarX">
          Exportar
        </v-btn>
      </v-card-title>
      <v-card-text v-if="noData" v-show="noDataText == ''">
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" > 
          {{vencidos == 1 ? 'No hay pendientes por validar!' : 'No hay vencidos sin validar!'}}
        </v-alert>
      </v-card-text>
      <v-card-text v-if="noData" v-show="noDataText != ''">
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" v-if="vencidos == 2"> 
          {{noDataText}}
        </v-alert>
      </v-card-text>
      <div v-else>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          class="styled-table m-4 mt-2"
          :sort-desc="[true, true]"
          :search="search"
          multi-sort
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(item, index) in items">
                <tr
                  :key="'ReportPayment-' + index"
                  @click="clickrow(item.id)"
                  class="pointman"
                  style="color: black"
                  :style="{ 'background-color': backgrondRowTable(index) }"
                >
                  <td style="padding: 5px; max-width: 14px !important;">
                    <v-icon class="ma-2" v-if="item.id == expanded">mdi-minus</v-icon>
                    <v-icon class="ma-2" v-if="item.id != expanded">mdi-plus</v-icon> 
                  </td>
                  <td>
                    <strong>{{ item.RefOri }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ item.Date }}</strong>
                  </td>
                  <td
                    :style="[
                      item.valido == 'SI' ? { color: 'green' } : { color: 'red' },
                    ]"
                  >
                    <strong>
                      {{ item.valido }}
                    </strong>
                  </td>
                  <td>{{ item.CorpName }}</td>
                  <td align="center">{{ item.fecha }}</td>
                  <td>
                    <strong>${{ formatPrice(item.total) }} MXN</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.cBoletos) }}</strong>
                  </td>
                  <td align="center">
                    <v-btn small color="success" @click="validar(item)">
                      <v-icon>mdi-clipboard-check-multiple-outline</v-icon>
                    </v-btn>
                  </td>
                  <td align="center">
                    <v-btn
                      small
                      dark
                      color="orange"
                      class="ma-2"
                      @click="cancelo(item)"
                    >
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr
                  :key="'ReportPayment2-' + index"
                  v-if="item.id == expanded"
                  style="background-color: white !important"
                >
                  <td colspan="10" style="padding-top: 10px !important; ">
                    <v-simple-table
                      style="zoom: 90%"
                      v-for="(item2, index2) in item.sitio"
                      :key="'SubReportPayment2-' + index + '-' + index2"
                    >
                      <thead>
                        <tr>
                          <td
                            style="background: white; color: black; padding: 0 !important; padding-left: 5px !important; height: 40px; font-size:15px;"
                          >
                            <strong>{{ item2.SitioName }}</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="background-color: rgba(255, 165, 0, 0.4);">
                          <td>
                            <v-simple-table style="zoom: 90%">
                              <thead>
                                <tr
                                  style="background: rgb(253 142 48 / 22%); color: black"
                                >
                                  <td class="MyTdPadding" align="center" width="15%">
                                    <strong>Tipo de boleto</strong>
                                  </td>
                                  <td style="width:30%;">
                                    <strong>Nombre del Boleto</strong>
                                  </td>
                                  <td align="center" style="width:15%">
                                    <strong>Cantidad</strong>
                                  </td>
                                  <td align="right" style="width:20%;">
                                    <strong>Precio Unitario</strong>
                                  </td>
                                  <td align="right" style="width:20%;">
                                    <strong>Costo</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item3, index3) in item2.ticket"
                                  :key="
                                    'SubReportPayment2-' +
                                      index +
                                      '-' +
                                      index2 +
                                      '-' +
                                      index3"
                                >
                                  <td class="MyTdPadding" align="center">
                                    {{ item3.id_ticket }}
                                  </td>
                                  <td>
                                    {{ item3.NameTicket }}
                                    <span v-if="item3.alias"> ({{ item3.alias }})</span>
                                  </td>
                                  <td align="center">
                                    {{ formatNum(item3.cantidad) }}
                                  </td>
                                  <td align="right">
                                    <strong>${{ formatPrice(item3.costo) }} MXN</strong>
                                  </td>
                                  <td align="right">
                                    <strong>${{ formatPrice(item3.subtotal) }}MXN</strong>
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4" align="right">
                            <strong>${{ formatPrice(item2.subtotal2) }} MXN</strong>
                          </td>
                        </tr>
                      </tfoot>
                    </v-simple-table>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>


        <div style="width: 100%; overflow:auto"  v-show="false" :id="`${vencidos == 1 ? 'Validar' : 'Vencidos'}-Tickets-wrap2`">
          <table style="width:100%;">
            <tr align="right">
              <td colspan="7" style="font-size: 16px;">
                <strong>{{vencidos == 1 ? 'Por Validar' : 'Vencidos' + `${this.searchTitle == '' ? '' : ' para: ' }` }}</strong>
                {{`${this.searchTitle == '' ? '' :  + this.searchTitle}`}}
              </td>
            </tr>
            <tr align="right">
              <td colspan="7" style="font-size: 16px;">
                <strong>Al día: </strong> {{ formatFecha(nowDate, '/') }}
              </td>
            </tr>
            <tr></tr>
          </table>
          <div v-for="(item, index2) in items" :key="`sitio-${vencidos == 1 ? 'Validar' : 'Vencidos'}-${index2}`">
            <table
              border="1"
              bordercolor="#272727"
              class="styled-table"
              style="border-collapse: collapse; width:100%;"
            >
              <thead>
                <tr>
                  <td class="MyTdPadding" align="star"><strong>Línea de Referencia</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Fecha de vencimiento</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Válido</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Nombre de la Empresa</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Fecha Orden de Pago</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Costo Total</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Total de Boletos</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>{{ item.RefOri }}  &nbsp;</strong>
                  </td>
                  <td align="center">
                    <strong>{{ item.Date }}</strong>
                  </td>
                  <td align="center">
                      {{ item.valido }}
                  </td>
                  <td>{{ item.CorpName }}</td>
                  <td align="center">{{ item.fecha }}</td>
                  <td>
                    <strong>${{ formatPrice(item.total) }} MXN</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.cBoletos) }}</strong>
                  </td>
                </tr>
                <tr>
                  <td colspan="7" class="MyTdPadding">
                    <div v-for="(item2, index2) in item.sitio" :key="`ImpreSitio-${item2.id}-${index2}`">
                      <table style="width:100%;">
                        <tr>
                          <td colspan="7" style="font-size: 18px;">
                            <strong>{{ item2.SitioName }}</strong>
                          </td>
                        </tr>
                      </table>
                      <table
                        border="1"
                        bordercolor="#272727"
                        class="styled-table"
                        style="border-collapse: collapse; width:100%;"
                      >
                        <thead>
                          <tr>
                            <td class="MyTdPadding" align="center">
                              <strong>Tipo de boleto</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Nombre del Boleto</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Cantidad</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Precio Unitario</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Costo</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item3, index3) in item2.ticket" :key="'ImpDetTickets2-' + index3">
                            <td class="MyTdPadding" align="center">
                              {{ item3.id_ticket }}
                            </td>
                            <td>
                              {{ item3.NameTicket }}
                              <span v-if="item3.alias"> ({{ item3.alias }})</span>
                            </td>
                            <td align="center">
                              {{ formatNum(item3.cantidad) }}
                            </td>
                            <td align="right">
                              <strong>${{ formatPrice(item3.costo) }} MXN</strong>
                            </td>
                            <td align="right">
                              <strong>${{ formatPrice(item3.subtotal) }} MXN</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                        
                      <table style="width:100%;">
                        <tr>
                          <td colspan="5" align="right">
                            <strong>${{ formatPrice(item2.subtotal2) }} MXN</strong>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <br>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <v-progress-linear
        v-if="TLoading"
        color="orange"
        :height="5"
        indeterminate
      ></v-progress-linear>
    </v-card>

    <v-dialog v-model="dialog" max-width="700px">
      <v-card class="pb-2">
        <v-card-title dark class="text-h5 grey lighten-2" style="background: #272727 !important; color: white;">
          Confirmación de la línea de referencia
        </v-card-title>

        <v-card-text>
          <label></label>
          <v-text-field
            label="Escriba la línea de referencia"
            v-model="ConfirmLinea"
          ></v-text-field>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="GeneAutorizacion"> 
            Aceptar 
            <template v-slot:loader>
              <span>Generando...</span>
            </template>
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            :color="'#dd8159'"
            style="color: white !important"
            @click="dialog = false"
          >
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </div>
</template>

<script>
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";
import { formatPrice, formatNum } from "@/utils/NumericTools.js";
import { exportExcelJavascript, sumarDias, formatFecha } from "@/utils/ReportsTools.js";
import $ from "jquery";

export default {
  name:'TabalMayoGob',
  data() {
    return {
      formatPrice, formatNum, backgrondRowTable, formatFecha,
      itemtemp: null,
      dialog: false,
      ConfirmLinea: '',
      search: "",
      flag: false,
      selected: [],
      TmpSelected: [],
      flagSelect: false,
      expanded: "",
      search2: '',
      searchTitle: '',
      noDataText: '',
      nowDate: '',
    };
  },
  props: ["headers", "items","TLoading", "noData", "vencidos"],
  watch: {
    search2: function (val) {
      if (val == '' && this.searchTitle != '') {
        setTimeout(() => {
          this.searchTitle = ''
          this.onEnter()
        }, 10);
      }
    }
  },
  updated(){
    setColorHeadFooter();
  },
  mounted(){
    setColorHeadFooter();
    this.nowDate = sumarDias(new Date(Date.now()), 0)
  },
  methods: {
    clickrow(id) {
      if (this.flag == false) {
        this.expanded = this.expanded == id ? "" : id;
      } else {
        this.flag = false;
      }
    },
    ChangeStatus(item, estatus) {
      this.$root
        .post("/UpdateTransMayo", {
          idX: item.id_cartrans,
          estatusX: estatus,
        })
        .then((response) => {
          if (this.vencidos == 2) {
            this.$parent.$parent.$parent.beginVencidos();
          } else {
            this.$parent.$parent.$parent.begin();
          }
        });
    },
    validar(item) {
      this.flag = true;
      this.dialog = true
      this.itemtemp = item
    },
    cancelo(item) {
      this.flag = true;
      this.MSGBOXFIX(
        "warning",
        "¿Desea bloquear esta Orden de Pago?",
        item.CorpName + ": " + item.RefOri,
        2,
        item
      );
    },
    GeneAutorizacion(){
      if(this.itemtemp.RefOri == this.ConfirmLinea){
        this.ChangeStatus(this.itemtemp, 2);
        this.dialog = false
        this.itemtemp = null
        this.ConfirmLinea = ''
        this.$root.swalAlert('success','Se ha autorizado correctamente la línea de referencia')
      }else{
        this.$root.swalAlert('error','No coinciden las lineas de referencias')
      }

    },
    MSGBOXFIX(icon, title, text, opt, item) {
      this.$swal({
        icon: icon,
        title: title,
        text: text,
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "primary",
        confirmButtonText: "SI",
        denyButtonColor: "#dd8159",
      }).then((result) => {
        if (result.isConfirmed) {
          switch (opt) {
            //Validar
            case 1:
              this.ChangeStatus(item, 2);
              break;
            //Cancelar
            case 2:
              this.ChangeStatus(item, 3);
              break;
          }
        }
      });
      //Fix bug add padging
      $("body").css({ "padding-right": "0px" });
    },
    onClear: function () {
      setTimeout(() => {
        this.search2 = '';
        this.$parent.$parent.$parent.beginVencidos();
      }, 10);
    },
    onEnter: function () {
      this.$parent.$parent.$parent.beginVencidos();
      this.searchTitle = this.search2;
    },
    ExportarX: function(event){
      if(this.items.length == 0){
        this.$swal({
          icon: 'warning',
          title: 'no hay nada que exportar!',
        })
        return false
      }

      var namePre = '';

      switch (this.vencidos) {
        case 1:
          namePre += 'Por_Validar' + '-a-' + formatFecha(this.nowDate, '-');
          break;
        case 2:
          namePre += 'Vencidos' + `${this.searchTitle == '' ? '' : '_para_'+ this.searchTitle}` + '-a-' + formatFecha(this.nowDate, '-');;
          break;
      }
      var table= document.getElementById(`${this.vencidos == 1 ? 'Validar' : 'Vencidos'}-Tickets-wrap2`);
      var html = table.outerHTML;
      exportExcelJavascript(html, event, `${this.vencidos == 1 ? 'Por_Validar' : 'Vencidos'}-a-${formatFecha(this.nowDate, '-')}`)
    },
  },
};
</script>

<style scoped>
.my-text-style >>> .v-text-field__slot input {
  text-transform: uppercase;
}
</style>
