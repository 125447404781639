var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"TablDiv"},[_c('span',{staticStyle:{"font-weight":"bold","font-size":"20px"}},[_vm._v(_vm._s(_vm.titulo))]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":"","color":"primary","dark":""}}),(_vm.BtnDelete)?_c('v-btn',{staticClass:"mb-2",attrs:{"color":"primary","dark":"","bottom":""},on:{"click":_vm.nuevo}},[_vm._v(" Nuevo ")]):_vm._e(),_c('v-data-table',{staticClass:"elevation-1 pointmanXX",attrs:{"headers":_vm.headers,"items":_vm.desserts,"sort-by":"calories","search":_vm.search,"height":"70vh","item-class":_vm.itemRowBackground,"loading":_vm.loadingX,"loading-text":"Loading... Please wait","footer-props":{
      showFirstLastPage: true,
      firstIcon: 'mdi-arrow-collapse-left',
      lastIcon: 'mdi-arrow-collapse-right',
      //prevIcon: 'mdi-minus',
      //nextIcon: 'mdi-plus'
    }},on:{"click:row":_vm.handleClick},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},(false)?{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{style:(_vm.ButonWith)},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 comiton",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){return _vm.editItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"MyIcon",attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])]),(_vm.ShowComision)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 comiton",attrs:{"fab":"","dark":"","small":"","color":"success"},on:{"click":function($event){return _vm.OpenComision(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"MyIcon",attrs:{"small":""}},[_vm._v(" attach_money ")])],1)]}}],null,true)},[_c('span',[_vm._v("Cuotas")])]):_vm._e(),(_vm.BtnDelete)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 comiton",attrs:{"fab":"","dark":"","small":"","color":"error"},on:{"click":function($event){return _vm.deleteItem(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"MyIcon",attrs:{"small":""}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar")])]):_vm._e()],1)]}}:null,{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" Reset ")])]},proxy:true},(_vm.usuarios)?{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item,index){return [_c('tr',{key:'ReportPayment-' + index,attrs:{"heigth":"480px"},on:{"click":function($event){return _vm.handleClick(item)}}},[_c('td',[_vm._v(" "+_vm._s(item.id)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.user)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.pass)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.ifNull(item.name) + " " + _vm.ifNull(item.name2) + " " + _vm.ifNull(item.name3))+" ")]),_c('td',[_vm._v(" "+_vm._s(item.mail)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.cargo)+" ")]),_c('td',[_vm._v(" "+_vm._s(_vm.userRol[item.rol])+" ")]),_c('td',[(item.activo)?_c('i',{staticClass:"bx bxs-user-check",staticStyle:{"font-size":"40px","color":"green"}}):_c('i',{staticClass:"bx bxs-user-x",staticStyle:{"font-size":"40px","color":"orange"}})]),_c('td',[_c('div',{attrs:{"align":"center"}},[(item.admin)?_c('span',{staticClass:"material-icons",staticStyle:{"color":"green","font-weight":"bold"}},[_vm._v(" done ")]):_vm._e()])])])]})],2)]}}:null],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }