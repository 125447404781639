<template>
  <div>
    <div class="sidebarXY closeXY">
      <div class="logo-details">
        <div class="pointman2" @click="MenuPri">
          <i class="bx bx-menu"></i>
        </div>

        <span class="logo_name">DashBoard</span>
      </div>

      <ul class="nav-linksXY">
        <li v-for="(item, index1) in DataMenu" :key="`DataMenu-${index1}`">
          <div class="iocn-link" @click="ShowVista(item)">
            <a href="#">
              <i :class="item.icon"></i>
              <span
                class="link_name"
                v-bind:class="item.sub.length ? 'arrow1' : ''"
                >{{ item.name }}</span
              >
            </a>
            <i class="bx bxs-chevron-down arrow" v-if="item.sub.length"></i>
          </div>
          <ul class="sub-menu">
            <li>
              <a class="link_name" href="#" @click="ShowVista(item)">{{
                item.name
              }}</a>
            </li>

            <li
              v-for="(item2, index2) in item.sub"
              :key="`DataSubMenu-${index2}`"
              @click="ShowVista(item2)"
            >
              <a href="#">{{ item2.name }}</a>
            </li>
          </ul>
        </li>
        <li>
          <div class="profile-details pointman" @click="UserInfo">
            <div class="profile-content">
              <img :src="User.avatar" alt="profileImg" />
            </div>
            <div class="name-job pointman" @click="UserInfo">
              <div class="profile_name">{{ User.name }}</div>
              <div class="job">{{ User.cargo }}</div>
            </div>
            <i style="min-width: 0"></i>
          </div>
        </li>
      </ul>
    </div>
    <section class="home-section">
      <div class="home-content">
        <Home ref="Home" v-if="ShowHome" />
        <CuentaContable v-if="ShowCuentaContable" />
        <Cajas ref="Cajas" v-if="ShowCajas" />
        <Users ref="Users" v-if="ShowUsers" />
        <UserInfo v-if="ShowUserInfo" />
        <Reports ref="Report" v-if="ShowReports" />
        <ReportPayment ref="ReportPayment" v-if="ShowReportPayment" />
        <Api v-if="ShowApi" />
        <CancelManu v-if="ShowCancelManu" />
        <RemainingTickets v-if="ShowRemainingTickets" />

        <Mayo ref="MayoX" v-if="ShowMayo" />

        <div class="DivMode" v-if="showDivMode">MODO PRUBAS</div>
      </div>
    </section>
  </div>
</template>

<script>
//import $                from "jquery";
//import clippy           from 'clippyjs'
import axios from "axios";
//WebSocket
import { io } from "socket.io-client";
var socket;

import Home from "../components/Home.vue";
import Wizzard from "../components/Wizzard.vue";
import CuentaContable from "../components/Promtec/CuentaContable.vue";
import RemainingTickets from "../components/Promtec/RemainingTickets.vue";
import Cajas from "../components/Promtec/Cajas.vue";
import Users from "../components/Promtec/Users.vue";
import UserInfo from "../components/UserInfo.vue";
import Reports from "../components/Reports.vue";
import ReportPayment from "../reports/reportefectivo.vue";
import DivLoading from "../components/Loading.vue";
import Mayo from "../components/GobMayoristas/MayoristaMaster.vue";
import Api from "../components/Promtec/Api.vue";
import CancelManu from "../components/Promtec/CancelManu.vue";

export default {
  name: "Dashboard",
  components: {
    Home,
    Wizzard,
    CuentaContable,
    RemainingTickets,
    UserInfo,
    Reports,
    DivLoading,
    ReportPayment,
    Cajas,
    Mayo,
    Users,
    Api,
    CancelManu,
  },
  data() {
    return {
      //Vistas
      ShowHome: true,
      ShowCuentaContable: false,
      ShowUserInfo: false,
      ShowReports: false,
      ShowReportPayment: false,
      ShowCajas: false,
      ShowMayo: false,
      ShowUsers: false,
      ShowApi: false,
      ShowCancelManu: false,
      ShowRemainingTickets: false,

      ShowLoading: false,

      switch1: false,
      showDivMode: false,

      //Variables
      User: {
        avatar: "",
        name: "",
        cargo: "",
      },
      DataMenu: [
        // {name:'Wizzard', icon:'bx bxs-component', url:'Wizzard', sub:[]},
        { name: "Inicio", icon: "bx bxs-component", url: "Home", sub: [] },
        { name: "Reportes", icon: "bx bx-grid-alt", url: "Reports", sub: [] },
        { name: "Api Service", icon: "bx bx-code-alt", url: "API", sub: [] },
        {
          name: "Catalogos",
          icon: "bx bx-collection",
          sub: [
            { name: "Configuración Boletos", url: "CuentaContable" },
            { name: "Boletos Restantes", url: "ShowRemainingTickets" },
            { name: "Configuración Cajas", url: "ShowCajas" },
            { name: "Configuración usuarios", url: "ShowUsers" },
            { name: "Cancelacion Manual", url: "ShowCancelManu" },
          ],
        },
        { name: "Mayoristas", icon: "bx bx-layer", url: "Mayo", sub: [] },
        { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
      ],
    };
  },
  computed: {},
  created() {
    this.$root.socket = io(this.$root.mySock + this.$root.myport + "/", {
      transports: ["websocket"],
    });

    this.$root.socket.connect();

    var vm = this;

    vm.$root.socket.on("MayoReload", (val) => {
      if (vm.$refs.MayoX) {
        vm.$refs.MayoX.begin();
      }
    });
  },
  mounted() {
    if (this.$cookies.get("user")) {
      this.$root.token = this.$cookies.get("user");
    } else {
      this.$router.push("/");
    }

    let subrol = this.$root.token.subrol;

    switch (this.$root.token.rol) {
      //Administrador
      case 0:
        break;
      case 1:
        if (subrol == 4) {
          // subrol para Jesus De contabilidad
          this.DataMenu = [
            { name: "Inicio", icon: "bx bxs-component", url: "Home", sub: [] },
            {
              name: "Reportes",
              icon: "bx bx-grid-alt",
              url: "Reports",
              sub: [],
            },
            { name: "Api Service", icon: "bx bx-code-alt", url: "API", sub: [] },
            { name: "Mayoristas", icon: "bx bx-layer", url: "Mayo", sub: [] },
            { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
          ];
        } else if (subrol == 3) {
          this.DataMenu = [
            { name: "Inicio", icon: "bx bxs-component", url: "Home", sub: [] },
            { name: "Mayoristas", icon: "bx bx-layer", url: "Mayo", sub: [] },
            { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
          ];
        } else {
          this.DataMenu = [
            { name: "Inicio", icon: "bx bxs-component", url: "Home", sub: [] },
            {
              name: "Reportes",
              icon: "bx bx-grid-alt",
              url: "Reports",
              sub: [],
            },
            { name: "Mayoristas", icon: "bx bx-layer", url: "Mayo", sub: [] },
            { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
          ];
        }
        break;

      //Cajero
      case 5:
        this.DataMenu = [
          {
            name: "Reporte",
            icon: "bx bx-grid-alt",
            url: "ShowReportPayment",
            sub: [],
          },
          { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
        ];
        this.HideVistas();
        this.ShowReportPayment = true;
        break;

      //Informatica
      case 6:
        this.DataMenu = [
          { name: "Api Service", icon: "bx bx-code-alt", url: "API", sub: [] },
          { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
        ];
        this.HideVistas();

        break;
      //solo reportes
      case 7:
        this.DataMenu = [
          { name: "Reportes", icon: "bx bx-grid-alt", url: "Reports", sub: [] },
          { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
        ];
        this.HideVistas();

        this.ShowReports = true;
        if (this.$refs.Report) {
          this.$refs.Report.ShowReports = true;
          this.$refs.Report.ShowPrinter = false;
          this.$refs.Report.HideReports();
        }
        break;
      //usuario de mesa de ayuda
      case 8:
        this.DataMenu = [
          { name: "Reportes", icon: "bx bx-grid-alt", url: "Reports", sub: [] },
          {
            name: "Catalogos",
            icon: "bx bx-collection",
            sub: [
              { name: "Boletos Restantes", url: "ShowRemainingTickets" },
              { name: "Configuración Cajas", url: "ShowCajas" },
              { name: "Cancelacion Manual", url: "ShowCancelManu" },
            ],
          },
          { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
        ];
        this.HideVistas();

        this.ShowReports = true;
        if (this.$refs.Report) {
          this.$refs.Report.ShowReports = true;
          this.$refs.Report.ShowPrinter = false;
          this.$refs.Report.HideReports();
        }
        break;
      //Usuario estandar
      default:
        this.DataMenu = [
          { name: "Inicio", icon: "bx bxs-component", url: "Home", sub: [] },
          { name: "Reportes", icon: "bx bx-grid-alt", url: "Reports", sub: [] },
          { name: "Salir", icon: "bx bx-log-out", url: "LogOut", sub: [] },
        ];
        break;
    }

    if (this.$root.token.rol != 5 && this.$root.token.rol != 6) {
      this.LoadSiteX(this.$root.token);
    }

    let arrow = document.querySelectorAll(".arrow");
    let arrow1 = document.querySelectorAll(".arrow1");

    for (var i = 0; i < arrow.length; i++) {
      arrow[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement; //selecting main parent of arrow
        arrowParent.classList.toggle("showMenu");
      });
    }

    for (var i = 0; i < arrow1.length; i++) {
      arrow1[i].addEventListener("click", (e) => {
        let arrowParent = e.target.parentElement.parentElement.parentElement; //selecting main parent of arrow
        arrowParent.classList.toggle("showMenu");
      });
    }

    this.User = this.$root.token;
  },
  methods: {
    LoadSiteX(token) {
      axios
        .post(this.$root.myserver + this.$root.myport + "/Login/Catalogo", {
          token: token.token,
        })
        .then((response) => {
          var info = response.data;

          this.$root.siteX = info[0];
          this.$root.entidad = info[1];

          this.$refs.Home.IniHome();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {});
    },
    UserInfo() {
      this.HideVistas();
      this.ShowUserInfo = true;
    },
    HideVistas() {
      this.ShowHome = false;
      this.ShowCuentaContable = false;
      this.ShowUserInfo = false;
      this.ShowReports = false;
      this.ShowReportPayment = false;
      this.ShowCajas = false;
      this.ShowMayo = false;
      this.ShowUsers = false;
      this.ShowApi = false;
      this.ShowCancelManu = false;
      this.ShowRemainingTickets = false;
    },
    ShowVista(item) {
      if (item.url == "LogOut") {
        this.LogOut();
        return false;
      }

      if (item.url) {
        this.HideVistas();

        switch (item.url) {
          case "Home":
            this.ShowHome = true;
            break;
          case "CuentaContable":
            this.ShowCuentaContable = true;
            break;
          case "Reports":
            this.ShowReports = true;
            if (this.$refs.Report) {
              this.$refs.Report.ShowReports = true;
              this.$refs.Report.ShowPrinter = false;
              this.$refs.Report.HideReports();
            }
            break;
          case "ShowReportPayment":
            this.ShowReportPayment = true;
            break;
          case "ShowCajas":
            this.ShowCajas = true;
            break;
          case "ShowUsers":
            this.ShowUsers = true;
            break;
          case "Mayo":
            this.ShowMayo = true;
            break;
          case "API":
            this.ShowApi = true;
            break;
          case "ShowCancelManu":
            this.ShowCancelManu = true;
            break;
          case "ShowRemainingTickets":
            this.ShowRemainingTickets = true;
            break;
        }
      }
    },
    MenuPri() {
      let sidebar = document.querySelector(".sidebarXY");
      sidebar.classList.toggle("closeXY");
    },
    arrow(ele) {},
    LogOut() {
      this.$swal({
        title: "Esta seguro?",
        text: "Realmente quieres salir del sistema!",
        icon: "warning",
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "primary",
        confirmButtonText: "SI",
        denyButtonColor: "#dd8159",
      }).then((result) => {
        if (result.isConfirmed) {
          this.Exit();
        }
      });
    },
    Exit() {
      this.$root.token = null;
      this.$root.animation = false;
      this.$root.dasini = [];

      this.$cookies.remove("user");
      this.$router.push("/");
    },
    SetToken() {
      this.$cookies.remove("user");
      this.$cookies.set("user", this.$root.token);
    },
  },
};
</script>

<style scoped>
.DivMode {
  position: absolute;
  top: calc(100% - 21px);
  width: 100%;
  text-align: center;
  font-size: 12px;
  background: rgb(255 152 0 / 80%);
  z-index: 1;
  color: white;
  font-weight: bold;
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}
.bx-menu {
  border-radius: 12px !important;
}
.pointman:hover {
  cursor: pointer;
  background: none;
}
.pointman2:hover i {
  background: #e4e9f7 !important;
  color: #272727 !important;
  cursor: pointer;
}
.sidebarXY {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 260px;
  background: #272727;
  z-index: 100;
  transition: all 0.5s ease;
}
.sidebarXY.closeXY {
  width: 68px;
}
.sidebarXY .logo-details {
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
}
.sidebarXY .logo-details i {
  font-size: 30px;
  color: #fff;
  height: 50px;
  min-width: 68px;
  text-align: center;
  line-height: 50px;
}
.sidebarXY .logo-details .logo_name {
  font-size: 22px;
  color: #fff;
  font-weight: 600 !important;
  transition: 0.3s ease;
  transition-delay: 0.1s;
  padding-left: 5px;
}
.sidebarXY.closeXY .logo-details .logo_name {
  transition-delay: 0s;
  opacity: 0;
  pointer-events: none;
}
.sidebarXY .nav-linksXY {
  height: 100%;
  padding: 30px 0 150px 0;
  overflow: auto;
  zoom: 90%;
}
.sidebarXY.closeXY .nav-linksXY {
  overflow: visible;
}
.sidebarXY .nav-linksXY::-webkit-scrollbar {
  display: none;
}
.sidebarXY .nav-linksXY li {
  position: relative;
  list-style: none;
  transition: all 0.4s ease;
}
.sidebarXY .nav-linksXY li:hover {
  background: #1d1b31;
}
.sidebarXY .nav-linksXY li .iocn-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sidebarXY.closeXY .nav-linksXY li .iocn-link {
  display: block;
}
.sidebarXY .nav-linksXY li i {
  height: 50px;
  min-width: 68px;
  text-align: center;
  line-height: 50px;
  color: #fff;
  font-size: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-weight: normal;
}
.sidebarXY .nav-linksXY li.showMenu i.arrow {
  transform: rotate(-180deg);
}
.sidebarXY.closeXY .nav-linksXY i.arrow {
  display: none;
}
.sidebarXY .nav-linksXY li a {
  display: flex;
  align-items: center;
  text-decoration: none;
}
.sidebarXY .nav-linksXY li a .link_name {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  transition: all 0.4s ease;
}
.sidebarXY.closeXY .nav-linksXY li a .link_name {
  opacity: 0;
  pointer-events: none;
}
.sidebarXY .nav-linksXY li .sub-menu {
  padding: 6px 6px 14px 80px;
  margin-top: -10px;
  background: #1d1b31;
  display: none;
}
.sidebarXY .nav-linksXY li.showMenu .sub-menu {
  display: block;
}
.sidebarXY .nav-linksXY li .sub-menu a {
  color: #fff;
  font-size: 15px;
  padding: 5px 0;
  white-space: nowrap;
  opacity: 0.6;
  transition: all 0.3s ease;
  font-weight: normal;
}
.sidebarXY .nav-linksXY li .sub-menu a:hover {
  opacity: 1;
}
.sidebarXY.closeXY .nav-linksXY li .sub-menu {
  position: absolute;
  left: 100%;
  top: -10px;
  margin-top: 0;
  padding: 10px 20px;
  border-radius: 0 6px 6px 0;
  opacity: 0;
  display: block;
  pointer-events: none;
  transition: 0s;
}
.sidebarXY.closeXY .nav-linksXY li:hover .sub-menu {
  top: 0;
  opacity: 1;
  pointer-events: auto;
  transition: all 0.4s ease;
}
.sidebarXY .nav-linksXY li .sub-menu .link_name {
  display: none;
}
.sidebarXY.closeXY .nav-linksXY li .sub-menu .link_name {
  font-size: 18px;
  opacity: 1;
  display: block;
}
.sidebarXY .nav-linksXY li .sub-menu.blank {
  opacity: 1;
  pointer-events: auto;
  padding: 3px 20px 6px 16px;
  opacity: 0;
  pointer-events: none;
}
.sidebarXY .nav-linksXY li:hover .sub-menu.blank {
  top: 50%;
  transform: translateY(-50%);
}
.sidebarXY .profile-details {
  position: fixed;
  bottom: 0;
  width: 289px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #1d1b31;
  padding: 12px 0;
  transition: all 0.5s ease;
}
.sidebarXY.closeXY .profile-details {
  background: none;
}
.sidebarXY.closeXY .profile-details {
  width: 68px;
}
.sidebarXY .profile-details .profile-content {
  display: flex;
  align-items: center;
}
.sidebarXY .profile-details img {
  height: 52px;
  width: 52px;
  object-fit: cover;
  border-radius: 16px;
  margin: 0 14px 0 12px;
  background: #272727;
  transition: all 0.5s ease;
}
.sidebarXY.closeXY .profile-details img {
  padding: 10px;
}
.sidebarXY .profile-details .profile_name,
.sidebarXY .profile-details .job {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  white-space: nowrap;
}
.sidebarXY.closeXY .profile-details i,
.sidebarXY.closeXY .profile-details .profile_name,
.sidebarXY.closeXY .profile-details .job {
  display: none;
}
.sidebarXY .profile-details .job {
  font-size: 12px;
}
.home-section {
  position: relative;
  background: #e4e9f7;
  height: 100vh;
  left: 260px;
  width: calc(100% - 260px);
  transition: all 0.5s ease;
}
.sidebarXY ~ .home-section {
  overflow: auto;
}
.sidebarXY.closeXY ~ .home-section {
  left: 68px;
  width: calc(100% - 68px);
  /*overflow: auto;*/
}
.home-section .home-content {
  height: 100vh;
  display: flex;
  align-items: baseline;
  text-align: left !important;
  min-width: 95vw;
}
.home-section .home-content .bx-menu,
.home-section .home-content .text {
  color: #272727;
  font-size: 35px;
}
.home-section .home-content .bx-menu {
  margin: 0 15px;
  cursor: pointer;
}
.home-section .home-content .text {
  font-size: 26px;
  font-weight: 600;
}

@media only screen and (max-width: 500px) {
  .home-section .home-content {
    opacity: 0;
  }
  .sidebarXY.closeXY ~ .home-section .home-content {
    transition: all 0.5s ease;
    opacity: 1;
  }
}
</style>
