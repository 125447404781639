<template>
    <div>
        <div class="MyPaddingV">
            <v-card class="MyTdPadding" style="padding: 20px;">
                <v-row>
                    <v-col cols="12" align="center">
                        <h3><strong>Reporte General De Comisiones Promtec Interno</strong></h3>
                    </v-col>
                    <v-col cols="12" md="6" lg="3" class="MyForm">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field                   
                                v-model="date"
                                style="height: 10px; !important"
                                label="Seleccione una fecha de inicio"
                                prepend-icon="mdi-calendar"
                                outlined
                                v-bind="attrs"
                                class="Mytext"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            locale="esp"
                            v-model="date"
                            @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" lg="3" class="MyForm">
                        <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field                   
                                v-model="date2"
                                style="height: 10px; !important"
                                label="Seleccione una fecha de fin"
                                prepend-icon="mdi-calendar"
                                outlined
                                v-bind="attrs"
                                class="Mytext"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            locale="esp"
                            v-model="date2"
                            @input="menu3 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" md="6" lg="2" align="center">
                        <v-switch
                        v-model="statistics"
                        inset
                        label="Estadísticas de 6 meses"
                        ></v-switch>
                    </v-col>
                    <v-col cols="12" md="6" lg="3" class="Mypadding2 MyForm" align="center">
                        <v-btn :loading="Generete" :disabled="Generete" height="40" color="primary" class="ma-2" @click="GenereteX">
                            GENERAR
                        </v-btn>

                        <v-btn :loading="Generete" :disabled="Generete" height="40" color="success" class="ma-2" @click="ExportarX">
                            Exportar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div id="page-wrap" align="center" v-if="DetSitios.length > 0">
            <TablaReportInterno ref="TablaReport6" :date="date" :date2="date2" :DetSitios="DetSitios" :DetTotales="DetTotales" :TotalCuota="TotalCuota" :averages="averages" :statistics="statisticsReport"/>          
        </div>

    </div>
</template>

<script>
import { exportExcelJavascript, sumarDias } from "@/utils/ReportsTools.js";
import TablaReportInterno from './TablaReport6Interno.vue'

export default {
    components: {
        TablaReportInterno,
    },
    data(){
        return{
            titulox:        '',
            DataEmpty:      true,
            TxtEmpty:       '',
            DetTotales:     [],
            TotalCuota:     0,
            DetSitios:      [],
            DetMayo:        [],
            DetUserMayo:    [],
            averages:       {},
            Generete:       false,
            menu2:          false,
            menu3:          false,
            date:           '',
            date2:          '',
            sixMonthsAgo:   '',
            oneDayAgo:      '',
            statistics:     false,
            statisticsReport: false
        }
    },
    watch: {
        date: function (val) {
            this.sixMonthsAgo = this.generateFechaSixMonthsAgo(val)
            this.oneDayAgo = sumarDias(new Date(val + ' 00:00:00'), -1)
        }
    },
    mounted(){
        this.date   = sumarDias(new Date(Date.now()), -1)
        this.date2  = sumarDias(new Date(Date.now()), -1)
    },
    methods: {
        ExportarX: function(event){
            if(this.DetTotales.length == 0){
                this.$swal({
                    icon: 'warning',
                    title: 'Genera el reporte!',
                })
                return false
            }
            var table= document.getElementById('page-wrap');
            var html = table.outerHTML;
            exportExcelJavascript(html, event, this.titulox)
        },
        generateFechaSixMonthsAgo: function (firstDay) {
            let date = new Date(firstDay + ' 00:00:00')
            let sixMonthsAgo = date.getFullYear() 
            sixMonthsAgo += '-' 
            sixMonthsAgo += (date.getMonth() - 5) <= 9 ? '0' + (date.getMonth() - 5) : (date.getMonth() - 5)
            sixMonthsAgo += '-' 
            sixMonthsAgo += date.getDate() <= 9 ? '0' + date.getDate() : date.getDate()

            return sixMonthsAgo
        },
        getAverageSixMonthsAgo: async function (res) {
            
        },
        GenereteX: function () {
            this.DetTotales = []
            this.averages = {}
            this.DetSitios        = []
            this.Generete       = true
            this.TotalCuota = 0;

            var name = 'comisiones_Promtec_Interno_de '+this.date+' a '+this.date2;
            this.titulox = name
            
            this.$root
            .post("/Report/ReportPromtecFinal", {
                dateX: this.date,
                dateY: this.date2
            })
            .then(async (response) => {
                if(!response.data) throw({message: 'Error al obtener el reporte'})

                if (this.statistics) {
                    let response2 = await this.$root
                    .post("/Report/ReportPromtecFinal", {
                        dateX: this.sixMonthsAgo,
                        dateY: this.oneDayAgo
                    })

                    if (!response2.data) throw({message: 'No hay datos de 6 meses atras'})
                    response2 = response2.data
                    
                    let sitiosAverage = {}
                    if(response2.sitios) {
                        response2.sitios.forEach(sitio => {
                            let tickets = {}
                            let coutaTotal = 0
                            sitio.data.forEach(ticket => {
                                coutaTotal += ticket.cuotaX
                                tickets[ticket.IDNodoCuentaContable] = ticket.cuotaX / 6
                            })
                            tickets['averageMensual'] = coutaTotal / 6
                            sitiosAverage[sitio.sucursal] = tickets
                        })
                    }

                    let mayoAverage = {}
                    if(response2.mayo) {
                        response2.mayo.forEach(sitio => {
                            let tickets = {}
                            let coutaTotal = 0
                            sitio.data.forEach(ticket => {
                                coutaTotal += ticket.cuotaX
                                tickets[ticket.id_ticket] = ticket.cuotaX / 6
                            })
                            sitiosAverage[sitio.sitioId]['averageMensual'] += coutaTotal / 6
                            mayoAverage[sitio.sitioId] = tickets
                        })
                    }
                    this.averages = {
                        sitiosAverage, mayoAverage
                    }
                }
                    
                for(var x = 0; x < response.data.sitios.length; x++){
                    let Cantidad = 0;
                    let Canje = 0;
                    for(var y = 0; y < response.data.sitios[x].data.length; y++){
                        Cantidad += response.data.sitios[x].data[y].Cantidad
                        Canje += response.data.sitios[x].data[y].Canje
                    }
                    response.data.sitios[x].Cantidad = Cantidad
                    response.data.sitios[x].Canje = Canje
                    response.data.sitios[x].Total = Cantidad + Canje
                }

                let mayoData = response.data.mayo
                let placeData = response.data.sitios

                for(var x = 0; x < mayoData.length; x++){
                    let { data : dataMayo, sitioId } = mayoData[x]
                    for(var y = 0; y < placeData.length; y++){
                        let { data, sucursal } = placeData[y]
                        if (sitioId === sucursal) {
                            for (let z = 0; z < dataMayo.length; z++) {
                                const ticketMayo = dataMayo[z];
                                placeData[y].Total += ticketMayo.cantidad
                                placeData[y].totalF += ticketMayo.total
                                placeData[y].cuotaF += ticketMayo.cuotaX
                                data.push({
                                    Canje: 0,
                                    Cantidad: 0,
                                    Concepto: ticketMayo.nombre+" (WEB MAYORISTA)",
                                    Cuenta: "",
                                    IDNodoCuentaContable: ticketMayo.id_ticket,
                                    Importe: ticketMayo.total,
                                    PrecioIndividual: ticketMayo.costo,
                                    cuotaX: ticketMayo.cuotaX,
                                    promtec: ticketMayo.promtec,
                                    total: ticketMayo.cantidad
                                })
                            }
                        }
                    }
                }

                for(var y = 0; y < placeData.length; y++){
                    placeData[y].data.sort(function(a, b) {
                        if (a.total < b.total) {
                            return 1;
                        }
                        if (a.total > b.total) {
                            return -1;
                        }
                        return 0;
                    });
                }
                placeData.sort(function(a, b) {
                    if (a.totalF < b.totalF) {
                        return 1;
                    }
                    if (a.totalF > b.totalF) {
                        return -1;
                    }
                    return 0;
                });
                let tickets = 0, 
                collection = 0, 
                commissions = 0;

                placeData.forEach(element => {
                    element.sitio = element.sitio.toUpperCase()
                    tickets += element.Total
                    collection += element.totalF
                    commissions += element.cuotaF
                    this.DetTotales.push({
                        sitio: element.sitio,
                        tickets: element.Total,
                        totalF: element.totalF,
                        cuotaF: element.cuotaF,
                    })
                });
                this.DetTotales.push({
                    sitio: "Sumatoria".toUpperCase(),
                    tickets: tickets,
                    totalF: collection,
                    cuotaF: commissions,
                })

                this.TotalCuota = commissions
                this.DetSitios      = placeData
                this.statisticsReport = this.statistics
            })
            .catch(error => {
                console.log(error)
                if(error.message) {
                    this.$swal({
                        icon: 'error',
                        title: error.message,
                    })
                }
            })
            .finally( () => {
                this.Generete = false
            })            
        },
    }
}
</script>


<style scoped>
    .FullDivR4{
        width: 100%;
        padding: 30px;
        height: auto;
    }
    .PieX{
        min-height: 400px !important;
    }
    .PieX div{
        height: 100% !important;
    }
    .MyForm{
        height: 75px;
    }
    #page-wrap{
        margin-top:20px;
        padding: 7px 10px 26px 24px;
        margin-right: 10px;
        height: auto;
    }
    .MySeparator{
        background: #9a9a9a;
        height: 20px;
    }
    .MyBorder{
        border-top: 1px solid black;
    }
    .MyTdPadding{
        padding: 5px;
    }
    .MyPaddingV{
        margin: 25px 20px 25px 20px;
        zoom: 90%;
    }
    .Mytext .v-input__control div fieldset{
        max-height: 45px;
    }
    .Mytext .v-input__control div input{
            margin-top: -8px;
    }
    .Mytext .v-input__prepend-outer{
        margin: 9px !important;
    }
    .MyPadding{
        padding: 20px;
    }
    .Mypadding2{
        padding: 4px !important;
    }
    .switch-center {
        display: flex;
        justify-content: center;
    }
</style>