<template>
  <div>
    <table
      border="1"
      bordercolor="#272727"
      class="styled-table"
      style="border-collapse: collapse; min-width: 80%"
    >
      <thead>
        <tr>
          <td colspan="6" align="center">
            <h5>Anexos</h5>
          </td>
        </tr>
        <tr>
          <td class="MyTdPadding">
            <strong>Usuario</strong>
          </td>
          <td class="MyTdPadding">
            <strong>No. Caja</strong>
          </td>
          <td class="MyTdPadding">
            <strong>Descripcion</strong>
          </td>
          <td class="MyTdPadding" style="width: 100px" align="center">
            <strong>Fecha</strong>
          </td>
          <td class="MyTdPadding" align="center">
            <strong>Monto</strong>
          </td>
          <td class="MyTdPadding" style="width: 80px" align="center">
            <strong>Abrir</strong>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr v-if="Anexos.length == 0">
          <td colspan="6" align="center">
            <strong>Sin Registro</strong>
          </td>
        </tr>
        <tr v-for="(item, index) in Anexos" :key="'Anexos' + index">
          <td>{{ item.cajero }}</td>
          <td align="center">{{ item.caja }}</td>
          <td>{{ item.description }}</td>
          <td align="center">{{ item.fecha }}</td>
          <td align="right">${{ formatPrice(item.monto) }}</td>
          <td>
            <v-btn class="success" :href="item.url" target="_black">
              <v-icon> mdi-file-pdf-box </v-icon>
            </v-btn>
          </td>
        </tr>
        <!-- <tr>
          <td align="right" colspan="4">
            <strong>TOTAL</strong>
          </td>
          <td class="MyTdPadding" align="right" colspan="2">
            <strong>${{ formatPrice(totalW) }}</strong>
          </td>
        </tr> -->
      </tbody>
    </table>
  </div>
</template>

<script>
import { formatPrice, formatNum } from "@/utils/NumericTools.js";
export default {
  name: "AnexosReports",
  data() {
    return {
      formatPrice,
      formatNum,
      totalW: 0,
    };
  },
  props: ["Anexos"],
  mounted() {
    this.sumarX();
  },
  methods: {
    sumarX() {
      let sum = 0;
      for (var x = 0; x < this.Anexos.length; x++) {
        sum += this.Anexos[x].monto;
      }
      this.totalW = sum;
    },
  },
};
</script>

<style scoped>
.VcarRowT1 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 7px 10px 26px 24px;
  zoom: 80%;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

@media (max-width: 400px) {
  .VcarRowT1 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>