<template>
  <div>
    <v-card class="w-100 pb-6">
      <v-card-text v-if="noDataItem">
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" >
          No hay boletos generados!
        </v-alert>
      </v-card-text>
      <div v-else>
        <v-card-title style="padding: 5px 25px 5px 26px;">
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label=""
            color="orange"
            placeholder="Buscar"
            prepend-icon="mdi-magnify"
            clearable
            single-line
            hide-details
            style="max-width: 400px"
          ></v-text-field>
          <v-btn height="40" color="success" class="ma-2 ml-7" @click="ExportarX">
            Exportar
          </v-btn>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :single-select="false"
          item-key="clave"
          class=" m-4 mt-2"
          :sort-desc="[true, true]"
          :search="search"
          multi-sort
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(ticket, index) in items">
                <tr
                  :key="'ReportPayment-' + index"
                  @click="clickrow(ticket.id)"
                  class="pointman"
                  style="color: black"
                  :style="{ 'background-color': backgrondRowTable(index), 'border-top':'2px solid #75695f' }"
                >
                  <td style="max-width: 10px">
                    <v-icon class="ma-2" v-if="ticket.id == expanded">mdi-minus</v-icon>
                    <v-icon class="ma-2" v-if="ticket.id != expanded">mdi-plus</v-icon> 
                  </td>
                  <td class="MyTdPadding" width="27%;">
                    <strong>{{ ticket.clave }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="25%;">
                    <strong>{{ ticket.f_solicitud }}</strong>
                  </td>
                  <td class="MyTdPadding"  align="center" width="25%;">
                    <strong>{{ formatNum(ticket.cantidad) }}</strong>
                  </td>
                  <!-- <td class="MyTdPadding" align="center" width="17%;">
                    <strong>{{
                      ticket.name + " " + ticket.name2 + " " + ticket.name3
                    }}</strong>
                  </td> -->
                  <td width="23%;">
                    {{ticket.descript}}
                  </td>
                </tr>

                <tr
                  :key="'ReportPayment2-' + index"
                  v-if="ticket.id == expanded"
                  style="background-color: white !important"
                >
                  <td colspan="7">
                    <v-simple-table
                      style="zoom: 90%"
                      v-for="(sitio, index2) in ticket.data"
                      :key="'SubReportPayment2-' + index + '-' + index2"
                    >
                      <thead>
                        <tr>
                          <td
                            style="background: white; color: black; padding: 0 !important; padding-top: 20px !important; padding-left: 5px !important; height: 40px; font-size:15px;"
                          >
                            <strong>{{ sitio.nameSitio  }}</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="background-color: rgba(255, 165, 0, 0.4);">
                          <td>
                            <v-simple-table style="zoom: 90%">
                              <thead>
                                <tr
                                  style="background: rgb(253 142 48 / 22%); color: black"
                                >
                                  <td class="MyTdPadding" align="center" width="15%;">
                                    <strong>Tipo de boleto</strong>
                                  </td>
                                  <td class="MyTdPadding" width="35%;">
                                    <strong>Nombre del Boleto</strong>
                                  </td>
                                  <td class="MyTdPadding" align="center" width="17%;">
                                    <strong>Cantidad</strong>
                                  </td>
                                  <td class="MyTdPadding" align="center" width="17%;">
                                    <strong>Conteo de impresiones</strong>
                                  </td>
                                  <td class="MyTdPadding" align="center" width="17%;">
                                    <strong>Historial de impresiones</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  class="w-100"
                                  v-for="(ticketType, index) in sitio.tickets"
                                  :key="'sitios-' + index"
                                >
                                  <td class="MyTdPadding" align="center">
                                    <strong>{{ ticketType.id_ticket }}</strong>
                                  </td>
                                  <td class="MyTdPadding" width="35%">
                                    <strong>{{ ticketType.descripcion }} </strong> 
                                    <span v-if="ticketType.alias"> ({{ ticketType.alias }})</span>
                                  </td>
                                  <td class="MyTdPadding" align="center">
                                    <strong>{{ formatNum(ticketType.cantidad) }}</strong>
                                  </td>
                                  <td class="MyTdPadding pt-2" align="center">
                                    <v-badge
                                      :content="ticketType.printed"
                                      :value="ticketType.printed"
                                      color="orange"
                                      overlap
                                    >
                                      <v-icon style="font-size: 30px" v-if="ticketType.printed">
                                        mdi-printer
                                      </v-icon>
                                    </v-badge>
                                  </td>
                                  <td align="center">
                                    <v-btn
                                      small
                                      color="blue lighten-1"
                                      class="ma-2"
                                      @click="$emit('print-history', ticket, ticketType)"
                                      :disabled="ticketType.printed < 1"
                                    >
                                      <v-icon color="white">mdi-printer-eye</v-icon>
                                    </v-btn>
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </tr>                  
                      </tbody>
                    </v-simple-table>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
        <div style="width:100%; overflow:auto"  v-show="false" id="pageTG-wrap">

          <table style="width:100%;">
            <tr align="right">
              <td colspan="4" style="font-size: 16px;">
                <strong>Boletos Disponibles de: </strong> {{nameCorp}}
              </td>
            </tr>
            <tr align="right">
              <td colspan="4" style="font-size: 16px;">
                <strong>Al día: </strong> {{formatFecha(nowDate, '/')}}
              </td>
            </tr>
            <tr></tr>
          </table>

          <div v-for="(ticket, index2) in items" :key="`sitio-${ticket.id}-${index2}`">
            <table
              border="1"
              bordercolor="#272727"
              class="styled-table"
              style="border-collapse: collapse; width:100%;"
            >
              <thead>
                <tr>
                  <td class="MyTdPadding" align="star"><strong>Clave de transaccion</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Fecha de solicitud</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Cantidad</strong></td>
                  <!-- <td class="MyTdPadding" align="center"><strong>Nombre de Solitante</strong></td> -->
                  <td class="MyTdPadding" align="center"><strong>Descripcion</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td class="MyTdPadding" style="width: 250px;">
                    {{ ticket.clave }}
                  </td>
                  <td class="MyTdPadding" align="center" style="width: 250px;">
                    {{ ticket.f_solicitud }}
                  </td>
                  <td class="MyTdPadding"  align="center" style="width: 90px;">
                    {{ formatNum(ticket.cantidad) }}
                  </td>
                  <!-- <td class="MyTdPadding" align="center" style="width: 450px;">
                    {{
                      ticket.name + " " + ticket.name2 + " " + ticket.name3
                    }}
                  </td> -->
                  <td  class="MyTdPadding" align="center">
                    {{ticket.descript}}
                  </td>
                </tr>
                <tr>
                  <td colspan="4" class="MyTdPadding">
                    <div v-for="(sitio, index3) in ticket.data" :key="`sitio-${sitio.id}-${index3}`">
                      <table style="width:100%;">
                        <tr>
                          <td colspan="4" style="font-size: 18px;">
                            <strong>{{ sitio.nameSitio }}</strong>
                          </td>
                        </tr>
                      </table>
                      <table
                        border="1"
                        bordercolor="#272727"
                        class="styled-table"
                        style="border-collapse: collapse; width:100%;"
                      >
                        <thead>
                          <tr>
                            <td class="MyTdPadding" align="center">
                              <strong>Tipo de boleto</strong>
                            </td>
                            <td class="MyTdPadding">
                              <strong>Nombre del Boleto</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Cantidad</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Conteo de impresiones</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(ticketType, index4) in sitio.tickets" :key="`DatosSitio-${index4}`">
                            <td class="MyTdPadding" align="center">
                              {{ ticketType.id_ticket }}
                            </td>
                            <td class="MyTdPadding" width="35%">
                              {{ ticketType.descripcion }}
                              <span v-if="ticketType.alias"> ({{ ticketType.alias }})</span>
                            </td>
                            <td class="MyTdPadding" align="center">
                              {{ formatNum(ticketType.cantidad) }}
                            </td>
                            <td class="MyTdPadding pt-2" align="center">
                              {{ticketType.printed}}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <br>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </v-card>
    <v-progress-linear
      v-if="loading"
      color="orange"
      :height="5"
      indeterminate
    ></v-progress-linear>
  </div>
</template>

<script>
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";
import { formatNum } from "@/utils/NumericTools.js";
import { exportExcelJavascript, formatFecha } from "@/utils/ReportsTools.js";
import $ from "jquery";

export default {
  name: 'TablaTicketsGenerated',
  props: ["items", "noDataItem", "loading", "nameCorp", "corpX", "nowDate"],
  data(){
    return{
      formatNum, backgrondRowTable, formatFecha,
      flag: false,
      search: '',
      expanded: '',
      headers: [
        { text: '', value: 'icon'},
        { text: "Clave de transaccion", align: "start", value: "clave", },
        { text: "Fecha de solicitud", value: "f_solicitud", align: "center" },
        { text: "Cantidad", value: "cantidad", align:"center" },
        // { text: "Nombre de Solitante", value: "name", align:"center" },
        { text: "Descripcion", value: "descript", align:"center" },
      ]
    }
  },
  mounted(){
    setColorHeadFooter();
  },
  methods: {
    clickrow(id) {
      if (this.flag == false) {
        this.expanded = this.expanded == id ? "" : id;
      } else {
        this.flag = false;
      }
    },
    ExportarX: function(event){
      if(this.items.length == 0){
        this.$swal({
          icon: 'warning',
          title: 'no hay nada que exportar!',
        })
        return false
      }
      var table= document.getElementById('pageTG-wrap');
      var html = table.outerHTML;
      exportExcelJavascript(html, event, `${this.nameCorp}-boletosGenerados-${formatFecha(this.nowDate, '-')}`)
    },
  }
}
</script>

<style scoped>
  .row-boleto{
    display: flex;
    justify-content: space-between;
  }
  .content-pdf{
    margin: 0px;
    display: flex;
    justify-content: center;
    width: 700px;
    flex-wrap: wrap;
  }
  .fill-width-x{
    width: 370px;
    border: 1px solid black;
    padding: 15px;
  }
.VcarRowT1 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

@media (max-width: 400px) {
  .VcarRowT1 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>