<template>
  <v-card class="VcarRowT1" id="card-prueba-12" style="min-width: 70%">
    <table style="width: 100%" id="total-reports-header">
      <tr align="center">
        <td colspan="4" style="font-weight: bold; font-size: 20px">
          <strong>Reporte General De Ventas Mayoristas</strong>
        </td>
      </tr>
      <br />
      <tr align="center">
        <td colspan="4" style="font-size: 20px">
          <strong>Fecha: </strong> {{ parseDate(date) }} al
          {{ parseDate(date2) }}
        </td>
      </tr>
    </table>
    <div style="width: 100%" v-if="DetMayo.length">
      <table>
        <tr></tr>
        <tr></tr>
      </table>

      <table
        id="subtitle-mayo"
        border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse; "
      >
        <thead>
          <tr>
            <th class="text-center">Cajero</th>
            <th class="text-center">Total de Transacciones</th>
            <th class="text-center">Total</th>
          </tr>
        </thead>
        <tbody>
          <tr
            align="right"
            :key="DetUserMayo.name"
            v-for="DetUserMayo in DetUserMayo"
          >
            <td class="text-left">
              {{ DetUserMayo.name }} {{ DetUserMayo.name2 }}
              {{ DetUserMayo.name3 }}
            </td>
            <td class="text-center">
              {{ formatNum(DetUserMayo.operaciones) }}
            </td>
            <td class="text-right">
              ${{ formatPrice(DetUserMayo.total) }}
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <th class="text-right">Totales:</th>
            <th class="text-center">
              {{ formatNum(getTotalOperaciones) }}
            </th>
            <th class="text-right">${{ formatPrice(TotalMayo) }}</th>
          </tr>
        </tfoot>
      </table>

			<br>
      <table>
        <tr></tr>
        <tr></tr>
      </table>
			<v-divider></v-divider>
			<table style="width: 100%">
				<tr align="center">
					<td colspan="4" style="font-size: 20px">
						<strong>Detalle de ventas por sitio</strong>
					</td>
				</tr>
			</table>
      <div
        v-for="(item1, index1) in DetMayo"
        :key="`sitio-${item1.id}-${index1}`"
      >
        <table
          :id="'details-parador-mayo' + index1"
          border="1"
          bordercolor="#272727"
          class="styled-table"
          style="border-collapse: collapse; width: 100%"
        >
          <thead>
						<tr>
							<td class="MyTdPadding" align="center" colspan="4">
								<strong>{{ item1.sitio }}</strong>
							</td>
						</tr>
            <tr>
              <td style="width: 40%" class="MyTdPadding" align="center">
                <strong>Nombre del Boleto</strong>
              </td>
              <td style="width: 20%" class="MyTdPadding" align="center">
                <strong>Cantidad</strong>
              </td>
              <td style="width: 20%" class="MyTdPadding" align="center">
                <strong>Precio Unitario</strong>
              </td>
              <td style="width: 20%" class="MyTdPadding" align="center">
                <strong>Costo</strong>
              </td>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item2, index2) in item1.data"
              :key="`DatosSitio-${index2}`"
            >
              <td class="MyTdPadding">{{ item2.nombre }}</td>
              <td class="MyTdPadding" align="center">
                {{ formatNum(item2.cantidad) }}
              </td>
              <td class="MyTdPadding" align="right">
                ${{ formatPrice(item2.costo) }}
              </td>
              <td class="MyTdPadding" align="right">
                ${{ formatPrice(item2.total) }}
              </td>
            </tr>
            <tr>
              <td
                class="MyTdPadding total-for-jspdf"
                align="right"
                style="border-top: 2px double black"
              >
                <strong>Total</strong>
              </td>
              <td
                class="MyTdPadding total-for-jspdf"
                align="center"
                style="border-top: 2px double black"
              >
                <strong>{{ formatNum(item1.Cantidad) }}</strong>
              </td>
              <td
                class="MyTdPadding total-for-jspdf"
                align="right"
                style="border-top: 2px double black"
              >
                <strong>Total</strong>
              </td>
              <td
                align="right"
                class="MyTdPadding total-for-jspdf"
                style="border-top: 2px double black"
              >
                <strong>${{ formatPrice(item1.total) }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>


			<br>
      <table>
        <tr></tr>
        <tr></tr>
      </table>
			<v-divider></v-divider>
			<table style="width: 100%">
				<tr align="center">
					<td colspan="4" style="font-size: 20px">
						<strong>Detalle de ventas por cajero</strong>
					</td>
				</tr>
			</table>
      <div v-for="(cajero, index2) in DetailCashier" :key="`detailCashier-${cajero.cajero}-${index2}`">
        <table>
          <tr></tr>
          <tr></tr>
        </table>
        <table
          border="1"
          bordercolor="#272727"
          class="styled-table"
          style="border-collapse: collapse; width:100%;"
        >
          <thead>
            <tr>
              <td colspan="2" class="MyTdPadding" align="center"><strong>Cajero</strong></td>
              <td class="MyTdPadding" align="center"><strong>Cantidad de boletos</strong></td>
              <td class="MyTdPadding" align="center"><strong>Total</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colspan="2" class="MyTdPadding" align="center" width="40%">
                {{ cajero.name }} {{ cajero.name2 }}
                {{ cajero.name3 }}
              </td>
              <td class="MyTdPadding" align="center" width="30%">{{ formatNum(cajero.cantidadCajero) }}</td>
              <td class="MyTdPadding" align="right"  width="30%">
                ${{ formatPrice(cajero.totalCajero) }}
              </td>
            </tr>
            <tr>
              <td colspan="4" class="MyTdPadding">
                <div v-for="(sitio, index3) in cajero.sitios" :key="`sitio-${sitio.sitioId}-${index3}`">
                  <table>
                    <tr></tr>
                  </table>
                  <table
                    border="1"
                    bordercolor="#272727"
                    class="styled-table"
                    style="border-collapse: collapse; width:100%;"
                  >
                    <thead>
                      <tr>
                        <td class="MyTdPadding" align="center" colspan="4">
                          <strong>{{ sitio.sitioName }}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td class="MyTdPadding" align="center"><strong>Nombre del Boleto</strong></td>
                        <td class="MyTdPadding" align="center">
                          <strong>Cantidad</strong>
                        </td>
                        <td class="MyTdPadding" align="center">
                          <strong>Precio Unitario</strong>
                        </td>
                        <td class="MyTdPadding" align="center"><strong>Costo</strong></td>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(ticketSitio, index4) in sitio.detalleSitio" :key="`DatosSitioCashiert-${index4}`">
                        <td class="MyTdPadding">{{ ticketSitio.nombre }}</td>
                        <td class="MyTdPadding" align="center" width="18%">{{ formatNum(ticketSitio.cantidad) }}</td>
                        <td class="MyTdPadding" align="right" width="18%">
                          ${{ formatPrice(ticketSitio.costo) }}
                        </td>
                        <td class="MyTdPadding" align="right" width="18%">
                          ${{ formatPrice(ticketSitio.total) }}
                        </td>
                      </tr>
                      <tr>
                        <td
                          class="MyTdPadding total-for-jspdf"
                          align="right"
                          style="border-top: 2px double black"
                        >
                          <strong>Total</strong>
                        </td>
                        <td
                          class="MyTdPadding total-for-jspdf"
                          align="center"
                          style="border-top: 2px double black"
                        >
                          <strong>{{ formatNum(sitio.cantidadSitio) }}</strong>
                        </td>
                        <td
                          class="MyTdPadding total-for-jspdf"
                          align="right"
                          style="border-top: 2px double black"
                        >
                          <strong>Total</strong>
                        </td>
                        <td
                          align="right"
                          class="MyTdPadding total-for-jspdf"
                          style="border-top: 2px double black"
                        >
                          <strong>${{ formatPrice(sitio.total) }}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <br>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </v-card>
</template>

<script>
import { formatPrice, formatNum } from "@/utils/NumericTools.js";

export default {
  data() {
    return {
      formatPrice,
      formatNum,
    };
  },
  props: ["date", "date2", "DetMayo", "DetUserMayo", "TotalMayo", "DetailCashier"],
  computed: {
    getTotalOperaciones: function () {
      let total = 0;
      this.DetUserMayo.forEach((element) => {
        total += element.operaciones;
      });
      return total;
    },
  },
  methods: {
    obtenerSubtotal(total) {
      return total / 1.16;
    },
    obtenerIVA: function (total) {
      return (total / 1.16) * 0.16;
    },
    FixCanje(item) {
      let ite = 0;
      if (item) {
        ite = item;
      }
      return ite;
    },
    parseDate: function (fecha) {
      try {
        var tempFecha = fecha.split("-");
        return tempFecha[2] + "/" + tempFecha[1] + "/" + tempFecha[0];
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style scoped>
.VcarRowT1 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 7px 10px 26px 24px;
  zoom: 80%;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

.table-total {
  border: 1px solid #272727;
  width: 14%;
}

@media (max-width: 400px) {
  .VcarRowT1 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>
