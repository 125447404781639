<template>
  <div class="MyWidth">
    <v-toolbar dark>
      <v-toolbar-title> Configuración de usuarios </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        dark
        class="ma-2"
        color="green"
        @click="addUser"
      >
        Añadir Usuario
      </v-btn>
    </v-toolbar>

    <v-row class="MyPadding2" style="padding-top: 20px">
      <v-col cols="12" class="MyPadding3" style="padding: 0px 40px 0px 40px">
        <VueTable
          ref="UsersTable"
          :ButonWith="'width:80px'"
          :headers="headers"
          :desserts="detUsers"
          :titulo="''"
          :father="'Users'"
          :usuarios="true"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="dialog" max-width="900px">
      <v-card style="zoom: 90%; opacity: 1">
        <v-card-title style="background: #272727; color: white">
          <span class="text-h5">Configuracion de Usuario</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-form ref="formUser">
              <v-row style="margin-top: 5px">
                <v-col cols="12" sm="6" md="4" class="MyPadding">
                  <v-text-field
                    v-model="formUser.user"
                    label="Nombre de usuario"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="MyPadding">
                  <v-text-field
                    v-model="formUser.mail"
                    label="Correo electronico"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="MyPadding">
                  <v-text-field
                    v-model="formUser.pass"
                    label="Contraseña"
                    :rules="[rules.required]"
                    :type="hidePassword ? 'password' : 'text'"
                    :append-icon="hidePassword ? 'visibility_off' : 'visibility'"
                    @click:append="hidePassword = !hidePassword"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="MyPadding">
                  <v-text-field
                    v-model="formUser.name"
                    label="Nombre"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="MyPadding">
                  <v-text-field
                    v-model="formUser.name2"
                    label="Apellido Paterno"
                    :rules="[]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6" md="4" class="MyPadding">
                  <v-text-field
                    v-model="formUser.name3"
                    label="Apellido Materno"
                    :rules="[]"
                  ></v-text-field>
                </v-col>

                <!--
                  ////////////////////////
                        rol principal
                  ///////////////////////
                 -->
                <v-col cols="12" sm="6" md="4">
                  <v-select v-if="(!isEdit || formUser.rol != 0) && formUser.rol != 4" v-model="formUser.rol" :items="selectRol" :disabled="isEdit && formUser.rol == 4" item-text="name" item-value="id" name="rol" label="rol" :rules="[rules.required]" @change="rolChanged"></v-select>

                  <v-text-field v-if="formUser.rol == 0 && isEdit"
                    label="rol"
                    :rules="[]"
                    value="Promtec"
                    disabled
                  ></v-text-field>

                  <v-text-field v-if="formUser.rol == 4"
                    label="rol"
                    :rules="[]"
                    value="Mayorista"
                    disabled
                  ></v-text-field>
                </v-col>

                <!--
                  ////////////////////////
                        subroles cargo en sus areas
                  ///////////////////////
                 -->
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 0 && isEdit">
                  <v-select v-model="formUser.subrol" :items="selectSubrolPromtec" item-text="name" item-value="id" name="cargo" label="cargo" :rules="[rules.required]"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 1">
                  <v-select v-model="formUser.subrol" :items="selectSubrolAafy" item-text="name" item-value="id" name="cargo" label="cargo" :rules="[rules.required]" @change="subrolChanged"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 2">
                  <v-select v-model="formUser.subrol" :items="selectSubrolCultur" item-text="name" item-value="id" name="cargo" label="cargo" :rules="[rules.required]" @change="subrolChanged"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 3">
                  <v-select v-model="formUser.subrol" :items="selectSubrolParador" item-text="name" item-value="id" name="cargo" label="cargo" :rules="[rules.required]" @change="subrolChanged"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 4">
                  <v-select v-if="!isEdit || formUser.subrol != 0" v-model="formUser.subrol" :items="selectSubrolMayo" item-text="name" item-value="id" name="cargo" label="cargo" :rules="[rules.required]"></v-select>
                  <v-text-field v-else
                    label="subrol"
                    :rules="[]"
                    value="Mayorista"
                    disabled
                  ></v-text-field>
                </v-col>

                <!--
                  ////////////////////////
                        pertenece a ...
                  ///////////////////////
                 -->
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 1 && formUser.subrol != 1">
                  <v-select v-model="formUser.pertenencia" :items="selectSitioAafy" item-text="NAME" item-value="id" name="pertenencia" label="pertenencia" :rules="[rules.required]"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 2 && formUser.subrol != 1">
                  <v-select v-model="formUser.pertenencia" :items="selectSitioCultur" item-text="NAME" item-value="id" name="pertenencia" label="pertenencia" :rules="[rules.required]"></v-select>
                </v-col>
                <v-col cols="12" sm="6" md="4" v-if="formUser.rol == 3">
                  <v-select v-model="formUser.pertenencia" :items="selectSitioCoordi" item-text="NAME" item-value="id" name="pertenencia" label="pertenencia" :rules="[rules.required]"></v-select>
                </v-col>

                <v-col cols="12" sm="6" md="4" class="MyPadding" style="padding-top: 4px;" align="center">
                  <div style="font-size: 10px;">
                    Activo
                  </div>
                  <div class="pointman">
                    <i class="bx bxs-user-check" style="font-size: 40px; color: green;" v-if="formUser.activo" @click="formUser.activo = 0"></i>
                    <i class="bx bxs-user-x" style="font-size: 40px; color: orange;" v-else @click="formUser.activo = 1"></i>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>

        <v-card-actions>
          <v-btn dark color="secundary" @click="close">
            CancelAR
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn dark color="success" @click="save">
            GUARDAR
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { setColorHeadFooter } from "@/utils/StyleTools.js";
import VueTable from "../VueTable.vue";

export default {
  components: {
    VueTable,
  },
  data() {
    return {
      dialog: false,
      isEdit: false,
      detUsers: [],
      Sitios: [],
      headers: [
        { text: "Id usuario", value: "id" },
        { text: "Usuario", value: "user" },
        { text: "Contraseña", value: "pass" },
        { text: "Nombre", value: "name" },
        { text: "mail", value: "mail" },
        { text: "cargo", value: "cargo" },
        { text: "rol" },
        { text: "activo", value: "activo" },
        { text: "Super usuario" }
      ],
      rules: {
        required: (value) => !!value || "Required.",
        email: (value) =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value) ||
          "E-mail must be valid",
      },
      formUser: {
        id: 0,
        user: "",
        pass: "",
        name: "",
        name2: "",
        name3: "",
        admin: "",
        rol: "",
        subrol: "",
        pertenencia: "",
        mail: "",
        activo: "",
        cargo: "",
      },
      selectRol: [
        {id: 1, name: "Aafy"},
        {id: 2, name: "Cultur"},
        {id: 3, name: "Parador"}
      ],
      selectSubrolPromtec: [],
      selectSubrolMayo:[],
      selectSubrolAafy:[],
      selectSubrolCultur:[],
      selectSubrolParador:[],
      selectSitioAafy: [],
      selectSitioCultur: [],
      selectSitioCoordi: [],
      hidePassword: true,
    };
  },
  watch: {
    dialog: function () {
      if (!this.dialog) {
        this.formUser = {
          id: 0,
          user: "",
          pass: "",
          name: "",
          name2: "",
          name3: "",
          admin: "",
          rol: "",
          subrol: "",
          pertenencia: "",
          mail: "",
          activo: "",
          cargo: "",
        };
        this.hidePassword = true;
        this.isEdit = false;
        this.$refs.formUser.resetValidation();
      }
    },
  },
  mounted() {
    setColorHeadFooter();
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.$root.post("/CC/ListUsers").then((response) => {
        this.detUsers                   = response.data[0];
        this.selectSitioAafy            = response.data[1];
        this.selectSitioCultur          = response.data[2];
        this.selectSitioCoordi          = response.data[3];
        this.selectSubrolPromtec        = response.data[4];
        this.selectSubrolMayo           = response.data[5];
        this.selectSubrolAafy           = response.data[6];
        this.selectSubrolCultur         = response.data[7];
        this.selectSubrolParador        = response.data[8];
        this.$refs.UsersTable.loadingX  = false;
      });
    },
    OpenUsers(item) {
      this.dialog = true;
      this.isEdit = true;
      this.formUser = Object.assign({}, item);
    },
    save() {
      if (this.$refs.formUser.validate()) {
        this.$root
          .post("/CC/SaveUser", this.formUser)
          .then((response) => {
            if (response.data[0][0].success == "TRUE") {
              this.dialog = false;
              this.$refs.UsersTable.loadingX = true;
              this.initialize();
              this.$root.swalAlert("success", "Usuarios", response.data[0][0].message);
            } else {
              this.$root.swalAlert("error", "Error", response.data[0][0].message);
            }
          });
      }
    },
    close: function() {
      this.dialog = false;
    },
    addUser: function() {
      this.dialog = true;
    },
    rolChanged: function () {
      this.formUser.subrol = "";
      this.formUser.pertenencia = "";
    },
    subrolChanged: function () {
      this.formUser.pertenencia = "";
    }
  },
};
</script>


<style scoped>
.MyWidth {
  width: 100%;
  height: 100vh;
  overflow: auto;
  overflow-x: hidden;
}
.pointman{
  cursor: pointer;
}
</style>