<template>
    <div  align="center" class="MyFull">
       <v-row style="padding:20px;">
           <v-col cols="12" aling="center">
               <div class="profile-content">
                   <div class="avatar">
                       <img :src="User.avatar" alt="profileImg">
                   </div>
                </div>
           </v-col>
           <v-col cols="12" aling="center">
               <div><h2><strong>{{User.name}}</strong></h2></div>
               <div><h6><strong>{{User.cargo}}</strong></h6></div>
           </v-col>

           <v-col  cols="12" aling="center">
               <v-card style="max-width: 1200px !important; padding:30px; zoom: 80%;">
                    <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field append-icon="vpn_key" outlined v-model="password1" label="Nueva contraseña"/>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field append-icon="vpn_key" outlined v-model="password2" label="Repita contraseña"/>
                            </v-col>
                            <v-col cols="12" align="center">
                                    <v-btn :loading="Generete" :disabled="Generete" height="40" color="success" class="ma-2" @click="ChangePass">
                                    Cambiar contraseña
                                </v-btn>
                            </v-col>
                    </v-row>
               </v-card>
           </v-col>
       </v-row>
       
    </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

export default {
    data(){
        return{
            Generete:false,
            User: {},
            hidePassword: true,
            password1:'',
            password2:''
        }
    },
    mounted(){
        this.User       = this.$root.token
    },
    methods: {
        ChangePass(){
            this.Generete = true

            if(this.password1 == "" || this.password2 == ""){
                this.$swal({
                    icon: 'error',
                    title: 'Cambiando contraseña',
                    text: 'No puede estar vacia el campo de contraseña'
                })
            }else{
                if(this.password1 == this.password2){

                    axios
                    .post(this.$root.myserver + this.$root.myport + "/Login/ChangePAss",
                    {
                        pass:   this.password1,
                        token:  this.$root.token.token
                    })
                    .then(response => {
                        let info = response.data

                        if(info.token){
                            this.$root.token.token = info.token
                            this.$parent.SetToken()

                            this.$swal({
                                icon: 'success',
                                title: 'Cambiando contraseña',
                                text: 'Se ha cambiado su contraseña correctamente'
                            })

                        }else{
                            this.$swal({
                                icon: 'error',
                                title: 'Cambiando contraseña',
                                text:   info.message
                            }).then((result) => {
                                 this.$parent.Exit()
                            })
                        }                        

                    })
                    .catch(error => {
                        console.log(error)
                        this.$swal({
                            icon: 'error',
                            title: 'Cambiando contraseña',
                            text: 'Erro al cambiar la contraseña'
                        })
                    })
                    .finally(() => {
                    })
                    
                }else{
                    this.$swal({
                        icon: 'error',
                        title: 'Cambiando contraseña',
                        text: 'Las contraseñas no son iguales'
                    })
                }
            }

            this.Generete = false
        }
    }
}
</script>

<style scoped>
    .MyFull{
        width: 100%;
        height: 100vh;
    }
    .profile-content .avatar,
    .profile-content img{
        height: 250px;
        width: 250px;
        object-fit: cover;
        border-radius: 16px;
        background: #272727;
        transition: all 0.5s ease;
        border-radius: 30px;
    }
</style>