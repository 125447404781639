<template>
  <div>    
    <v-card style="margin: 20px;" :class="noData == null ? 'pb-2 pt-1' : ''">
      <v-card-title v-if="noData == null" style="padding: 5px 25px 5px 26px;">
        <strong>
          <h4>{{CorpY.name}} {{`${searchTitle == '' || radio != 2 ? '' : ' para: ' + searchTitle }`}}</h4>
        </strong>
        <v-spacer></v-spacer>
        <v-btn height="40" color="success" class="ma-2 ml-7" @click="ExportarX">
          Exportar
        </v-btn>
      </v-card-title>
      <v-card-text v-if="noData">
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" >
          {{textNoData}} 
        </v-alert>
      </v-card-text>
      <div v-else>
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          class="m-4 mb-5 mt-2"
          :sort-desc="[true, true]"
          :search="search"
          multi-sort
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(item, index) in items">
                <tr
                  :key="'ReportPayment-' + index"
                  @click="clickrow(item.id)"
                  class="pointman"
                  style="color: black"
                  :style="{ 'background-color': backgrondRowTable(index) }"
                >
                  <td style="padding: 5px; max-width: 14px !important;">
                    <v-icon class="ma-2" v-if="item.id == expanded">mdi-minus</v-icon>
                    <v-icon class="ma-2" v-if="item.id != expanded">mdi-plus</v-icon> 
                  </td>
                  <td>
                    <strong>{{ item.RefOri }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ item.Date }}</strong>
                  </td>                  
                  <td v-if="radio != 1">{{ item.CorpName }}</td>
                  <td align="center">{{ item.fecha }}</td>
                  <td>
                    <strong>${{ formatPrice(item.total) }} MXN</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.cBoletos) }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.iBoletos) }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.dBoletos) }}</strong>
                  </td>
                  <td align="center" v-if="tabx == 1">{{ item.f_autorizacion }}</td>
                  <td align="center" v-if="tabx == 1">{{ item.autorizo }}</td>
                  <td align="center" v-if="tabx == 2">{{ item.f_bloqueo }}</td>
                  <td align="center" v-if="tabx == 2">{{ item.bloqueo }}</td>
                  <td align="center">
                    <v-btn
                      small
                      color="blue lighten-1"
                      class="ma-2"
                      @click="usedHistory(item)"
                      :disabled="item.iBoletos < 1"
                    >
                      <v-icon color="white">mdi-printer-eye</v-icon>
                    </v-btn>
                  </td>
                  <td align="center" v-if="tabx == 1">
                    <v-btn
                      small
                      dark
                      color="orange"
                      class="ma-2"
                      @click="cancelo(item)"
                    >
                      <v-icon>mdi-cancel</v-icon>
                    </v-btn>
                  </td>
                  <td align="center" v-if="tabx == 2">
                    <v-btn
                      small
                      dark
                      color="red"
                      class="ma-2"
                      @click="desbloquear(item)"
                    >
                      <v-icon>mdi-lock-open</v-icon>
                    </v-btn>
                  </td>
                </tr>
                <tr
                  :key="'ReportPayment2-' + index"
                  v-if="item.id == expanded"
                  style="background-color: white !important"
                >
                  <td colspan="13" style="padding-top: 10px !important; ">
                    <v-simple-table
                      style="zoom: 90%"
                      v-for="(item2, index2) in item.sitio"
                      :key="'SubReportPayment2-' + index + '-' + index2"
                    >
                      <thead>
                        <tr>
                          <td
                            style="background: white; color: black; padding: 0 !important; padding-left: 5px !important; height: 40px; font-size:15px;"
                          >
                            <strong>{{ item2.SitioName }}</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        <tr style="background-color: rgba(255, 165, 0, 0.4);">
                          <td>
                            <v-simple-table style="zoom: 90%">
                              <thead>
                                <tr
                                  style="background: rgb(253 142 48 / 22%); color: black"
                                >
                                  <td class="MyTdPadding" align="center" width="10%">
                                    <strong>Tipo de boleto</strong>
                                  </td>
                                  <td class="MyTdPadding" width="25%">
                                    <strong>Nombre del Boleto</strong>
                                  </td>
                                  <td align="center" style="width: 13%">
                                    <strong>Cantidad</strong>
                                  </td>
                                  <td align="center" style="width: 13%">
                                    <strong>Impreso(s)</strong>
                                  </td>
                                  <td align="center" style="width: 13%">
                                    <strong>Disponibles(s)</strong>
                                  </td>
                                  <td align="right" style="width: 13%;">
                                    <strong>Precio Unitario</strong>
                                  </td>
                                  <td align="right" style="width: 13%;">
                                    <strong>Costo</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr
                                  v-for="(item3, index3) in item2.ticket"
                                  :key="
                                    'SubReportPayment2-' +
                                      index +
                                      '-' +
                                      index2 +
                                      '-' +
                                      index3"
                                  >
                                  <td align="center">
                                    {{ item3.id_ticket }}
                                  </td>
                                  <td>
                                    {{ item3.NameTicket }}
                                    <span v-if="item3.alias"> ({{ item3.alias }})</span>
                                  </td>
                                  <td align="center">
                                    {{ formatNum(item3.cantidad) }}
                                  </td>
                                  <td align="center">
                                    {{ formatNum(item3.usado) }}
                                  </td>
                                  <td align="center">
                                    {{ formatNum(item3.n_usado) }}
                                  </td>
                                  <td align="right">
                                    <strong
                                      >${{ formatPrice(item3.costo) }} MXN</strong
                                    >
                                  </td>
                                  <td align="right">
                                    <strong
                                      >${{
                                        formatPrice(item3.subtotal)
                                      }}
                                      MXN</strong
                                    >
                                  </td>
                                </tr>
                              </tbody>
                            </v-simple-table>
                          </td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4" align="right">
                            <strong>
                              ${{ formatPrice(item2.subtotal2) }} MXN
                            </strong>
                          </td>
                        </tr>
                      </tfoot>
                    </v-simple-table>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>


        <div style="width: 100%; overflow:auto"  v-show="false" :id="`${tabx == 1 ? 'Autorizados' : 'Bloqueados'}-${radio}-Tickets-wrap`">
          <table style="width:100%;">
            <tr align="right">
              <td :colspan="radio != 1 ? 10 : 9" style="font-size: 16px;">
                <strong>{{ tabx == 1 ? 'Autorizados' : 'Bloqueados' }} 
                {{radio == 1 ? 'de:' : radio == 2 ?  `Líneas de Referencia ${searchTitle == '' ? '' : 'para: '}`  : '' }} </strong> 
                {{radio == 1 ? CorpY.name :  radio == 2 ? searchTitle == '' ? '' : searchTitle : '' }}
              </td>
            </tr>
            <tr align="right">
              <td :colspan="radio != 1 ? 10 : 9" style="font-size: 16px;">
                <strong>{{radio == 3 ? 'Del' : 'Al'}} día: </strong> {{radio == 3 ? formatFecha(date, '/') : formatFecha(nowDate, '/')}}
              </td>
            </tr>
            <tr></tr>
          </table>
          <div v-for="(item, index2) in items" :key="`sitio-${tabx}-${radio}-${index2}`">
            <table
              border="1"
              bordercolor="#272727"
              class="styled-table"
              style="border-collapse: collapse; width:100%;"
            >
              <thead>
                <tr>
                  <td class="MyTdPadding" align="star"><strong>Línea de Referencia</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Fecha de vencimiento</strong></td>
                  <td class="MyTdPadding" align="center" v-if="radio != 1"><strong>Nombre de la Empresa</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Fecha Orden de Pago</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Costo Total</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Total de Boletos</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Boletos Impreso(s)</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Boletos Disponibles(s)</strong></td>
                  <td class="MyTdPadding" align="center" v-if="tabx == 1"><strong>Fecha de Autorizacion</strong></td>
                  <td class="MyTdPadding" align="center" v-if="tabx == 1"><strong>Autorizó</strong></td>
                  <td class="MyTdPadding" align="center" v-if="tabx == 2"><strong>Fecha de Bloqueo</strong></td>
                  <td class="MyTdPadding" align="center" v-if="tabx == 2"><strong>Bloqueó</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <strong>{{ item.RefOri }}  &nbsp;</strong>
                  </td>
                  <td align="center">
                    <strong>{{ item.Date }}</strong>
                  <td v-if="radio != 1">{{ item.CorpName }}</td>
                  <td align="center">{{ item.fecha }}</td>
                  <td>
                    <strong>${{ formatPrice(item.total) }} MXN</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.cBoletos) }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.iBoletos) }}</strong>
                  </td>
                  <td align="center">
                    <strong>{{ formatNum(item.dBoletos) }}</strong>
                  </td>
                  <td align="center" v-if="tabx == 1">{{ item.f_autorizacion }}</td>
                  <td align="center" v-if="tabx == 1">{{ item.autorizo }}</td>
                  <td align="center" v-if="tabx == 2">{{ item.f_bloqueo }}</td>
                  <td align="center" v-if="tabx == 2">{{ item.bloqueo }}</td>
                </tr>
                <tr>
                  <td :colspan="radio != 1 ? 10 : 9" class="MyTdPadding">
                    <div v-for="(item2, index2) in item.sitio" :key="`ImpreSitio-${item2.id}-${index2}`">
                      <table style="width:100%;">
                        <tr>
                          <td :colspan="radio != 1 ? 10 : 9" style="font-size: 18px;">
                            <strong>{{ item2.SitioName }}</strong>
                          </td>
                        </tr>
                      </table>
                      <table
                        border="1"
                        bordercolor="#272727"
                        class="styled-table"
                        style="border-collapse: collapse; width:100%;"
                      >
                        <thead>
                          <tr>
                            <td class="MyTdPadding" align="center">
                              <strong>Tipo de boleto</strong>
                            </td>
                            <td class="MyTdPadding">
                              <strong>Nombre del Boleto</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Cantidad</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Impreso(s)</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Disponibles(s)</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Precio Unitario</strong>
                            </td>
                            <td class="MyTdPadding" align="center">
                              <strong>Costo</strong>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item3, index3) in item2.ticket" :key="'ImpDetTickets2-' + index3">
                            <td align="center">
                              {{ item3.id_ticket }}
                            </td>
                            <td>
                              {{ item3.NameTicket }}
                              <span v-if="item3.alias"> ({{ item3.alias }})</span>
                            </td>
                            <td align="center">
                              {{ formatNum(item3.cantidad) }}
                            </td>
                            <td align="center">
                              {{ formatNum(item3.usado) }}
                            </td>
                            <td align="center">
                              {{ formatNum(item3.n_usado) }}
                            </td>
                            <td align="right">
                              <strong
                                >${{ formatPrice(item3.costo) }} MXN</strong
                              >
                            </td>
                            <td align="right">
                              <strong
                                >${{
                                  formatPrice(item3.subtotal)
                                }}
                                MXN</strong
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                        
                      <table style="width:100%;">
                        <tr>
                          <td colspan="7" align="right">
                            <strong>
                              ${{ formatPrice(item2.subtotal2) }} MXN
                            </strong>
                          </td>
                        </tr>
                      </table>
                    </div>
                    <br>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <v-progress-linear
        v-if="loading"
        color="orange"
        :height="5"
        indeterminate
      ></v-progress-linear>
    </v-card>
    <ModalTable ref="ModalTableTabalMayoGob2" @closeDialog="closeDialog" v-if="dialog" :type="1" :itemPrint="itemPrint" :dataItemPrint="dataItemPrint" :textNoDataPrint="textNoDataPrint" :noDataPrint="noDataPrint"/>
  </div>
</template>

<script>
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";
import { formatPrice, formatNum } from "@/utils/NumericTools.js";
import { exportExcelJavascript, sumarDias, formatFecha } from "@/utils/ReportsTools.js";
import $ from "jquery";
import ModalTable from './ModalTable.vue'

export default {
  name:'TabalMayoGob2',
  components: {
    ModalTable
  },
  data() {
    return {
      formatPrice, formatNum, backgrondRowTable, formatFecha,
      flag: false,
      dialog: false,
      noDataPrint: null,
      textNoDataPrint: '',
      itemPrint: [],
      dataItemPrint: [],
      selected: [],
      TmpSelected: [],
      flagSelect: false,
      expanded: "",
      nowDate: '',
    };
  },
  props: ["headers", "items", "search", "CorpY", "tabx", "loading" , "noData", "textNoData", "radio", "searchTitle", "date"],
  watch: {
    
  },
  updated(){
    setColorHeadFooter();
  },
  mounted(){
    setColorHeadFooter();
    this.nowDate = sumarDias(new Date(Date.now()), 0)
  },
  methods: {
    clickrow(id) {
      if (this.flag == false) {
        this.expanded = this.expanded == id ? "" : id;
      } else {
        this.flag = false;
      }
    },
    ChangeStatus(item, estatus) {      
      this.$root
        .post("/UpdateTransMayo", {
          idX: item.id_cartrans,
          estatusX: estatus,
        })
        .then((response) => {
          
          if(this.$parent){
            this.$parent.beginc(estatus, 0)
            this.$parent.beginc2(estatus, item.id_mayo)
          }

          if(this.$parent.$parent.$parent.$parent.$parent){
            this.$parent.$parent.$parent.$parent.$parent.begin()
          }
        });
    },
    validar(item) {
      this.flag = true;
      this.MSGBOXFIX(
        "info",
        "¿Desea validar esta Orden de Pago?",
        item.CorpName + ": " + item.RefOri,
        1,
        item
      );
    },
    cancelo(item) {
      this.flag = true;
      this.MSGBOXFIX(
        "warning",
        "¿Desea bloquear esta Orden de Pago?",
        item.CorpName + ": " + item.RefOri,
        2,
        item
      );
    },
    desbloquear(item) {
      this.flag = true;
      this.MSGBOXFIX(
        "warning",
        "¿Desea desbloquear esta Orden de Pago?",
        item.CorpName + ": " + item.RefOri,
        3,
        item
      );
    },
    usedHistory: function (item) {
      this.flag = true;
      this.noDataPrint = null;
      this.itemPrint = [];
      this.dataItemPrint = [];
      this.textNoDataPrint = '';
      this.$root
      .post("/wholesaler/GetHistoryPrintedTransac", {type: 1, id: item.id, corp: item.id_mayo, ticketType: {}})
      .then((response) => {
        var info = response.data[0];
        if (info && info.length > 0) {
          info.map(item =>{
            item.data = JSON.parse(item.data)
          })
          this.itemPrint = item;
          this.dataItemPrint = info;
        } else{
          this.noDataPrint = true;
          this.textNoDataPrint = 'No hay registro de impresión para: '+ item.RefOri
        }
        this.dialog = true;
      });
    },
    closeDialog: function () {
      this.itemPrint = [];
      this.dataItemPrint = []
      this.dialog = false;
    },
    MSGBOXFIX(icon, title, text, opt, item) {
      this.$swal({
        icon: icon,
        title: title,
        text: text,
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "SI",
      }).then((result) => {
        if (result.isConfirmed) {
          switch (opt) {
            //Validar
            case 1:
              this.ChangeStatus(item, 2);
              break;
            //Cancelar
            case 2:
              this.ChangeStatus(item, 3);
              break;
            case 3:
              this.ChangeStatus(item, 4);
              break;
          }
        }
      });
      //Fix bug add padging
      $("body").css({ "padding-right": "0px" });
    },
    ExportarX: function(event){
      if(this.items.length == 0){
        this.$swal({
          icon: 'warning',
          title: 'no hay nada que exportar!',
        })
        return false
      }


      var namePre = this.tabx == 1 ? 'Autorizados' : 'Bloqueados';      
      switch (this.radio) {
        case 1:
          namePre += '-de-' + this.CorpY.name.replaceAll(/\s+/g, "_") + '-a-' + formatFecha(this.nowDate, '-');
          break;
        case 2:
          namePre += `-lineas_referencia${this.searchTitle == '' ? '' : '_para_'+this.searchTitle}` + '-a-' + formatFecha(this.nowDate, '-');
          break;
        case 3:
          namePre += '_del_dia_'+ formatFecha(this.nowDate, '/') + '-a-' + formatFecha(this.nowDate, '-');
          break;
      }
      var table= document.getElementById(`${this.tabx == 1 ? 'Autorizados' : 'Bloqueados'}-${this.radio}-Tickets-wrap`);
      var html = table.outerHTML;
      exportExcelJavascript(html, event, namePre)
    },
  },
};
</script>

<style scoped>
.theme--light.v-data-table .v-data-table-header th.sortable:hover, .theme--light.v-data-table .v-data-table-header th.sortable.active {
  color: white !important;
}
</style>
