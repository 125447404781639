<template>
  <div>
    
    
    <v-card style="margin: 20px;" v-if="noDataCorps">
      <v-card-text>
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" >
          No hay {{status == 1 ? 'Autorizados' : 'Bloqueados'}}! 
        </v-alert>
      </v-card-text>
    </v-card>
    <div v-else>
      <div v-if="ShowBack">
        <div align="center" style="padding:10px;">
          <TabalMayoGob2 ref="TabalMayoGob2" :tabx="tabx" :CorpY="CorpY" :headers="headersX" :items="items" :search="search" :loading="loading" :noData="noData" :textNoData="textNoData" :radio="radio" :searchTitle="searchTitle" :date="date"/>
        </div>
      </div>
      <v-row style="padding: 20px;" v-if="!ShowBack">
        <v-col
          cols="6"
          sm="3"
          md="2"
          lg="2"
          xl="2"
          v-for="(item, index) in corpsX"
          :key="'Corps' + index"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="MyCard"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              @click="openX(item)"
            >
              <div
                align="center"
                class="ImgAvartDiv"
                style="padding: 10px !important;position: relative;
        z-index: 2;"
              >
                <img :src="item.avatar" class="ImgCorp" style="height: 120px;" />
              </div>

              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text MyDecore"
                ></div>
              </v-expand-transition>

              <div align="center" class="Title">
                <strong>{{ item.name }}</strong>
              </div>
            </v-card>
          </v-hover>
        </v-col>
        <v-col
          v-if="Graf2"
          cols="6"
          sm="3"
          md="2"
          lg="2"
          xl="2">
          <v-skeleton-loader
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import TabalMayoGob2 from "./GobMayoristas/TabalMayoGob2.vue";

export default {
  components: {
    TabalMayoGob2,
  },
  data() {
    return {
      typeX:1,
      status:2,
      CorpY:{name:''},
      corpsX: [],
      corpsZ: [],
      headersX:[],
      loading: true,
      headers: [
        { text: '', value: '', sortable: false },
        { text: "Línea de Referencia", value: "RefOri", width: "280px" },
        { text: "Fecha de vencimiento", value: "Date", align: "center", width: "150px", },
        { text: "Nombre de la Empresa", value: "CorpName" },
        { text: "Fecha Orden de Pago", value: "fecha", align: "center", width: "150px", },
        { text: "Costo Total", value: "total" },
        { text: "Total de Boletos", value: "cBoletos" },
        { text: "Boletos Impreso(s)", value: "iBoletos", align: "center" },
        { text: "Boletos Disponibles(s)", value: "dBoletos", align: "center" },
        { text: "Fecha de Autorizacion", value: "f_autorizacion", align:"center", width: "150px" },
        { text: "Autorizó", value: "nameautorizo", align:"center" },
        { text: "Historial de Impresiones", value: "", align: "center", sortable: false },
        { text: "Bloquear", value: "", align: "center", sortable: false },
      ],
      headers2: [
        { text: '', value: '', sortable: false },
        { text: "Línea de Referencia", value: "RefOri", width: "280px" },
        { text: "Fecha de vencimiento", value: "Date", align: "center", width: "150px", },
        { text: "Nombre de la Empresa", value: "CorpName" },
        { text: "Fecha Orden de Pago", value: "fecha", align: "center", width: "150px", },
        { text: "Costo Total", value: "total" },
        { text: "Total de Boletos", value: "cBoletos" },
        { text: "Boletos Impreso(s)", value: "iBoletos", align: "center" },
        { text: "Boletos Disponibles(s)	", value: "dBoletos", align: "center" },
        { text: "Fecha de Bloqueo", value: "f_bloqueo", align:"center", width: "150px", },
        { text: "Bloqueó", value: "namebloqueo", align:"center" },
        { text: "Historial de Impresiones", value: "", align: "center", sortable: false },
        { text: "Desbloquear", value: "", align: "center", sortable: false },
      ],
      headersEmpresa: [
        { text: '', value: '', sortable: false },
        { text: "Línea de Referencia", value: "RefOri", width: "280px" },
        { text: "Fecha de vencimiento", value: "Date", align: "center", width: "150px", },
        { text: "Fecha Orden de Pago", value: "fecha", align: "center", width: "150px", },
        { text: "Costo Total", value: "total" },
        { text: "Total de Boletos", value: "cBoletos" },
        { text: "Boletos Impreso(s)", value: "iBoletos", align: "center" },
        { text: "Boletos Disponibles(s)	", value: "dBoletos", align: "center" },
        { text: "Fecha de Autorizacion", value: "f_autorizacion", align:"center", width: "150px" },
        { text: "Autorizó", value: "nameautorizo", align:"center" },
        { text: "Historial de Impresiones", value: "", align: "center", sortable: false },
        { text: "Bloquear", value: "", align: "center", sortable: false },
      ],
      headersEmpresa2: [
        { text: '', value: '', sortable: false },
        { text: "Línea de Referencia", value: "RefOri", width: "280px" },
        { text: "Fecha de vencimiento", value: "Date", align: "center", width: "150px", },
        { text: "Fecha Orden de Pago", value: "fecha", align: "center", width: "150px", },
        { text: "Costo Total", value: "total" },
        { text: "Total de Boletos", value: "cBoletos" },
        { text: "Boletos Impreso(s)", value: "iBoletos", align: "center" },
        { text: "Boletos Disponibles(s)	", value: "dBoletos", align: "center" },
        { text: "Fecha de Bloqueo", value: "f_bloqueo", align:"center", width: "150px", },
        { text: "Bloqueó", value: "namebloqueo", align:"center" },
        { text: "Historial de Impresiones", value: "", align: "center", sortable: false },
        { text: "Desbloquear", value: "", align: "center", sortable: false },
      ],
      items:[],
      noData: null,
      Graf2: true,
      noDataCorps: null,
      radio: 1,
      textNoData: "",
      searchTitle: ''
    };
  },
  props: ["corps", "search", "search2", "ShowBack", "tabx", "date"],
  watch: {
    search: function(val) {
      switch(this.typeX){
        case 1:
            if(val == "" || val == null){
              this.corpsX = Object.assign({}, this.corpsZ);
            } else {
              var res = this.corpsZ.filter((x) =>
                x.name.toLowerCase().includes(val.toLowerCase())
              );
              this.corpsX = res;
            }
          break
        case 2:
          break
      }
    },
    tabx: function(val) {
      this.status = val + 1
    }
  },
  mounted() {
    this.status = this.tabx + 1
    this.beginc(0, 0)
  },
  methods: {
    beginc(status, opt){
      this.items = []
      this.corpsX = []
      this.typeX = 1
      this.Graf2 = true;
      this.noDataCorps = null;
      this.$root
        .post("/wholesaler/GetTransCarGob", { status: this.status, opt:opt, type: 1 })
        .then((response) => {
          var info = response.data[0]
          if (info.length) {
            this.Graf2 = false;
            this.corpsZ = info
            this.corpsX = Object.assign({}, info)
          } else {
            this.noDataCorps = true;
          }
        })
    },
    beginc2(status, opt){
      this.items = []
      this.typeX = 1
      this.loading = true;
      this.noData = null;
      this.radio = 1
      this.headersX = this.status == 2 ? this.headersEmpresa : this.headersEmpresa2
      this.$root
        .post("/wholesaler/GetTransCarGob", { status: this.status, opt:opt, type: 1 })
        .then((response) => {
          var info = response.data[0]
          this.loading = false;
          for (var i = 0; i < info.length; i++) {
            info[i].sitio = JSON.parse(info[i].sitio)
          }
          this.items = info
          if (!this.items.length) {
            this.noData = true;
            this.textNoData = `No hay ${this.status == 2 ? 'Bloqueado' : 'Autorizados'} en la empresa ` 
          }
          setTimeout(() => {
            this.$refs.TabalMayoGob2.expanded = ""
          }, 10);
        })
    },
    onFecha(fecha){
      this.loading = true;
      this.noData = null;
      this.noDataCorps = null;
      this.CorpY.name = `${this.status == 2 ? 'Autorizados': 'Bloqueados'} en ` + fecha
      this.headersX = this.status == 2 ? this.headers : this.headers2
      this.radio = 3
      this.items = []
      this.$root
        .post("/wholesaler/GetTransCarGob", { status: this.status, fecha: fecha, type: 3 })
        .then((response) => {
          var info = response.data[0]
          this.loading = false;
          for (var i = 0; i < info.length; i++) {
            info[i].sitio = JSON.parse(info[i].sitio)
          }
          this.items = info
          if (!this.items.length) {
            this.noData = true;
            this.textNoData = `No hay ${this.status == 2 ? 'autorizados': 'bloqueados'} en ${fecha}` 
          }
          setTimeout(() => {
            this.$refs.TabalMayoGob2.expanded = ""
          }, 10);
        })
    },
    onEnter(){
      this.loading = true;
      this.noData = null;
      this.noDataCorps = null;
      this.CorpY.name = '*Limite de busqueda 20 resultados'
      this.headersX = this.status == 2 ? this.headers : this.headers2
      this.radio = 2
      this.items = []
      this.$root
        .post("/wholesaler/GetTransCarGob", { status: this.status, linea: this.search2, type: 2 })
        .then((response) => {
          var info = response.data[0]
          this.loading = false;
          for (var i = 0; i < info.length; i++) {
            info[i].sitio = JSON.parse(info[i].sitio)
          }
          this.items = info
          if (!this.items.length) {
            this.noData = true;
            this.textNoData = this.search2 == '' ? `No hay ${this.status == 2 ? 'autorizados': 'bloqueados'}` : `No hay resultados en ${this.status == 2 ? 'autorizados': 'bloqueados'} para '${this.search2}'`
          }
          setTimeout(() => {
            this.$refs.TabalMayoGob2.expanded = ""
            this.searchTitle = this.search2
          }, 10);
        })
    },
    openX(item){
      this.$parent.$parent.$parent.$parent.OpenX = item
      this.CorpY            = item
      this.$parent.ShowBack = true
      this.$parent.ShowOpt = false
      this.$parent.TextSearch = 'Escribe lo que quieras buscar'
      this.$parent.search = ''
      this.$parent.search2 = ''
      this.beginc2(this.status, item.idcorp)
    },
    changeMont(data){
      this.typeX = 2
      this.corpsX = data
    }
  }
};
</script>

<style scoped>
.MyDecore {
  width: 100%;
  height: 65%;
  position: absolute;
  top: 0px;
  z-index: 1;
  border-radius: 0px 0px 200% 0%;
  background: rgba(245, 124, 0, 0.3);
}
.MyCard {
  height: 100% !important;
}
.MyCard:hover {
  cursor: pointer;
  zoom: 105%;
  background: #ffdb9a;
  position: absolute;
  width: 100%;
  margin-left: -14px;
  margin-top: -10px;
  height: fit-content !important;
}
.MyCard:hover .Title {
  background: #ffdb9a !important;
  color: rgb(53, 53, 53);
}

.MyCard.ImgAvartDiv {
  width: 100%;
  height: 121px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.MyCard.ImgAvartDiv.ImgCorp {
  max-height: 121px !important;
}
.MyCard .Title {
  padding: 10px;
  margin-bottom: 20px;
  background: orange;
  color: white;
  border-radius: 0 !important;
}
</style>
