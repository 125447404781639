<template>
    <div class="MyWidth">
        <v-toolbar dark>
          <v-toolbar-title>
              Configuración de Cajas
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn dark class="ma-2" :loading="loading2" :disabled="loading2" color="green" @click="DownloadCasiers()">
                Actualizar lista
              </v-btn>
        </v-toolbar>

        <v-row class="MyPadding2" style="padding-top: 20px;">
            <v-col cols="12" class="MyPadding3" style="padding: 0px 40px 0px 40px;">
            <VueTable ref="CajasTable" :ButonWith="'width:80px'" :BtnDelete="null" :headers="headers" :desserts="desserts" :titulo="''" :father="'Cajas'"/>
          </v-col>
        </v-row>

        <v-dialog v-model="dialog" max-width="900px">
            <v-card style="zoom: 90%; opacity:1;">
                <v-card-title style="background: #272727; color: white;">
                    <span class="text-h5">Configuracion de Caja</span>
                </v-card-title>

                <v-card-text>
                    <v-container>
                        <v-row style="margin-top: 5px;">
                            <v-col cols="12" sm="6" md="2" class="MyPadding">
                                <v-text-field readonly v-model="editedItem.IdCajero" disabled label="Id Boleto Axess"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="MyPadding">
                                <v-text-field readonly v-model="editedItem.name" disabled label="Nombre Largo"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="3" class="MyPadding">
                                <v-text-field readonly v-model="editedItem.name2" disabled label="Nombre Corto"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" class="MyPadding">
                                <v-select :items="Entidad" v-model="editedItem.entidad" name="id" item-text="name" label="Entidad" @change="EntiChange()"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="5" class="MyPadding">
                                <v-select :items="Sitios2" :disabled="disableSitio" v-model="editedItem.sitio" name="id" item-text="NAME" label="Sitio"></v-select>
                            </v-col>
                            <v-col cols="12" sm="6" md="4" class="MyPadding">
                                <v-select :items="Cbancarias" v-model="editedItem.Cuenta" name="id" item-text="Cuenta" label="Cuenta Bancarias"></v-select>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>

                <v-card-actions>
                    <v-btn dark color="secundary" @click="close" :loading="loading3">
                        CancelAR
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="success" @click="save" :loading="loading3">
                        GUARDAR
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { setColorHeadFooter } from "@/utils/StyleTools.js";
import VueTable from '../VueTable.vue'
import axios from 'axios'

export default {
    components: {
        VueTable
    },
    data(){
        return{
            loading2:       false,
            disableSitio:   false,
            loading3:       false,
            dialog:         false,
            desserts:       [],
            editedItem:     {},
            Entidad:        [],
            Sitios2:        [],
            Cbancarias:     [],
            Sitios:         [
                                {name:'CHICHEN ITZÁ', id:1},
                            ],
            headers:        [
                                { text: 'Id Cajero',          value: 'IdCajero' },
                                { text: 'Nombre Largo',       value: 'name' },
                                { text: 'Nombre Corto',       value: 'name2' },                    
                                { text: 'Entidad',            value: 'entidad'},
                                { text: 'Sitio',              value: 'sitio' },
                                { text: 'Cuota Promtec',      value: 'Cuenta'},
                            ],
        }
    },
    mounted(){
        setColorHeadFooter();
        this.Entidad = this.$root.entidad
    },
    created () {
      this.initialize()
    },
    methods: {
        initialize() {
            axios
            .post(this.$root.myserver + this.$root.myport + "/CC/ListaCajas",
            {
                token: ''
            })
            .then(response => {
                this.$refs.CajasTable.loadingX = false

                this.desserts   = response.data[0]
                this.Sitios     = response.data[1]
                this.Cbancarias = response.data[2]

                this.Cbancarias.push({id:0,Cuenta:''})
            })
            .catch(error => {
                console.log(error)
            })
        },
        OpenCajaX(item){
            this.dialog     = true
            this.editedItem = Object.assign({}, item)
            this.Sitios2    = this.Sitios.filter( itemx => itemx.entidad === item.IdEntidad )
        },
        DownloadCasiers(){
            this.loading2  = true
            this.desserts  =   []
            axios
            .post(this.$root.myserver + this.$root.myport + "/api2/getCatalogues/UpdateCataloge",
            {
                token: '',
                i_szTableName:'tabkassakonf'
            })
            .then(response => {
                this.loading2 = false
                this.initialize()
            }).catch(error => {
                this.loading2 = false
            })
        },
        save(){
            let res1    = this.Sitios.find( itemx => itemx.NAME === this.editedItem.sitio )
            if(res1){
                this.editedItem.IdSitio = res1.id
            }

            let res2    = this.Entidad.find( itemx => itemx.name === this.editedItem.entidad )
            if(res2){
                this.editedItem.IdEntidad = res2.id
            }
            
            let res3    = this.Cbancarias.find( itemx => itemx.Cuenta === this.editedItem.Cuenta )
            if(res3){
                this.editedItem.IdCuenta  = res3.id
            }

            axios
            .post(this.$root.myserver + this.$root.myport + "/CC/SaveCajas",
            {
                token: '',
                datax:this.editedItem
            })
            .then(response => {
                this.MSGBOX('success', 'Configuracion de Cajas', 'Se ha actualizado la caja satisfactoriamente')
                this.initialize()
                this.dialog = false
            })
            .catch(error => {
                this.MSGBOX('error', 'Configuracion de Cajas', 'Ha ocurrido un error')
                this.dialog = false
                console.log(error)
            })

            //this.dialog     = false
        },
        MSGBOX(icon, title, text){
            this.$swal({
                icon: icon,
                title: title,
                text: text
            })
        },
        close(){
            this.dialog     = false
        },
        EntiChange(){
            let res       = this.Entidad.find( itemx => itemx.name === this.editedItem.entidad )

            if(res.id){
                this.disableSitio = false
            }else{
                this.disableSitio = true
            }

            this.Sitios2    = this.Sitios.filter( itemx => itemx.entidad === res.id )
      },
    }
}
</script>


<style scoped>
    .MyWidth{
        width: 100%;
        height: 100vh;
        overflow: auto;
        overflow-x: hidden;
    }
</style>