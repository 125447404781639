<template>
  <div class="Mybackground">
    <v-toolbar dark>
      <v-toolbar-title>
      <div style="padding-top:0px;" v-if="ShowBack && row=='radio1'">
          <v-btn dark color="orange" @click="ShowBackX()">
            <i class="bx bx-arrow-back" style="font-size: 20px;"></i>
            Regresar
          </v-btn>
        </div>

        <v-radio-group
          v-model="row"
          row
          style="padding-top:16px;"
          v-if="ShowOpt"
        >
          <v-radio value="radio1" @click="TypeSearch()">
            <template v-slot:label>
              <div style="padding-top: 10px;">
                Buscar por <strong class="warning--text">Empresa</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="radio2" @click="TypeSearch()">
            <template v-slot:label>
              <div style="padding-top: 10px;">
                Buscar por
                <strong class="warning--text">Línea de Referencia</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="radio3" @click="TypeSearch()">
            <template v-slot:label>
              <div style="padding-top: 10px;" class="mr-3">
                Buscar por
                <strong class="warning--text">Fecha</strong>
              </div>
              <v-menu v-if="row == 'radio3'" v-model="menu" absolute :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field                   
                      v-model="date"
                      label=""
                      outlined
                      dark
                      color="orange"
                      placeholder="Seleccionar fecha"
                      prepend-icon="mdi-calendar"
                      class="my-text-style"
                      style="margin-top: 45px; zoom: 80%;"
                      v-bind="attrs"
                      v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="esp"
                  v-model="date"
                  :max="nowDate"
                  @input="loadFecha"
                ></v-date-picker>
              </v-menu>
            </template>
          </v-radio>
        </v-radio-group>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-text-field
        v-if="row == 'radio1'"
        v-model="search"
        label=""
        outlined
        dark
        color="orange"
        :placeholder="TextSearch"
        clearable
        prepend-icon="mdi-account-search"
        class="my-text-style"
        style="margin-top: 30px; zoom: 80%;"
      ></v-text-field>
      <v-text-field
        v-if="row == 'radio2'"
        v-model="search2"
        label=""
        outlined
        dark
        color="orange"
        placeholder="Escribe la línea de referencia y presione enter"
        v-on:keyup.enter="onEnter"
        clearable
        @click:clear="onClear"
        prepend-icon="mdi-account-search"
        class="my-text-style"
        style="margin-top: 30px; zoom: 80%;"
      ></v-text-field>
      
    </v-toolbar>
    <div
      style="margin-top: 0px; padding-top: 1px; height: calc(100vh - 178px); overflow: auto;"
    >
      <CardCorp ref="CardCorp" :tabx="tabx" :corps="corpsX" :search="search" :search2="search2" :ShowBack="ShowBack" :date="date"/>
    </div>
  </div>
</template>

<script>
import { sumarDias } from "@/utils/ReportsTools.js";
import CardCorp from "../CorpCard.vue";

export default {
  components: {
    CardCorp,
  },
  data() {
    return {
      ShowBack: false,
      ShowOpt: true,
      TextSearch:'Escribe un nombre de compañia',
      search: "",
      search2: "",
      row: "radio1",
      corpsX: [],
      LRef: [],
      corps: [],
      menu: false,
      date: '',
      nowDate: '',
    };
  },
  props: ["tabx"],
  watch: {
    search2: function (val) {
      if (val == '' && this.$refs.CardCorp.searchTitle != '') {
        setTimeout(() => {
          this.$refs.CardCorp.searchTitle = ''
          this.onEnter()
        }, 10);
      }
    }
  },
  mounted() {
    this.date = sumarDias(new Date(Date.now()), 0)
    this.nowDate = sumarDias(new Date(Date.now()), 0)
  },
  methods: {
    ShowBackX(){
      this.ShowOpt  = true
      this.ShowBack = false
      this.search   = ''
    },
    onEnter(){
      if(this.row == "radio2"){
        this.ShowBack = true
        this.$refs.CardCorp.onEnter()
      }
    },
    onClear(){
      setTimeout(() => {
        this.search2 = '';
        this.onEnter();
      }, 10);
    },
    TypeSearch() {
      this.ShowBack = false
      switch (this.row) {
        case "radio1":
          this.TextSearch = 'Escribe un nombre de compañia'
          this.corpsX = this.corps;
          this.$refs.CardCorp.beginc(null,0);
          break;
        case "radio2":
          this.corpsX = this.LRef;
          this.$refs.CardCorp.changeMont(this.LRef);
          this.ShowBack = true
          setTimeout(() => {
            this.$refs.CardCorp.onEnter()
          }, 10);
          break;        
        case "radio3":
          this.corpsX = this.LRef;
          this.$refs.CardCorp.changeMont(this.LRef);
          this.ShowBack = true
          setTimeout(() => {
            this.$refs.CardCorp.onFecha(this.date)
          }, 10);
          break;
      }
      this.search = ''
    },
    loadFecha(){
      this.menu = false
      if(this.row == "radio3"){
        this.ShowBack = true
        this.$refs.CardCorp.onFecha(this.date)
      }
    }
  },
};
</script>

<style scoped>
.Mybackground {
  width: 100%;
  background: #e4e9f7;
}
.my-text-style >>> .v-text-field__slot input {
  text-transform: uppercase;
}
</style>
