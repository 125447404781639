<template>
  <div style="width:100%;">
    <v-toolbar dark>
      <v-toolbar-title>
        Modulo de Cancelacion Manual
      </v-toolbar-title>
      <v-spacer></v-spacer>
    </v-toolbar>
    <div style="padding:20px;">
      <v-card style="padding:20px;">
        <v-card-title>
          <v-btn @click="add()" depressed color="primary">
            <i class="bx bxs-edit-alt IconY"></i>
            Agregar
          </v-btn>

          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="items"
          :single-select="false"
          item-key="id"
          class="styled-table m-4 mt-2"
          :sort-desc="[true, true]"
          :search="search"
          multi-sort
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(item, index) in items">
                <tr :key="'cancel-' + index">
                  <td align="center">{{ item.id }}</td>
                  <td align="center">{{ item.transI }}</td>
                  <td align="center">{{ item.caja }}</td>
                  <td align="center">{{ item.fecha }}</td>
                  <td align="center">{{ item.transC }}</td>
                  <td align="center">
                    <i
                      class="bx bxs-timer IconX"
                      style="color:#5d99c6;"
                      v-if="item.activo"
                    ></i>
                    <i
                      class="bx bx-check IconX"
                      style="color:#26a69a; font-weight: bold;"
                      v-if="!item.activo"
                    ></i>
                  </td>
                  <td>
                    <v-btn
                      @click="editX(item)"
                      :disabled="!item.activo"
                      small
                      depressed
                      color="primary"
                    >
                      <i class="bx bxs-edit-alt IconY"></i>
                    </v-btn>

                    <v-btn
                      @click="deleteX(item)"
                      :disabled="!item.activo"
                      small
                      depressed
                      color="error"
                      style="margin-left:10px"
                    >
                      <i class="bx bxs-trash IconY"></i>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </div>

    <v-dialog
      v-model="dialog"
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-toolbar color="primary" dark>Agregar nueva cancelacion</v-toolbar>
        <v-card-text>
          <v-row style="padding-top:20px;">
            <v-col cols="12" md="6">
              <v-text-field
                name="Referencia"
                :label="'Transaccion Original'"
                type="text"
                outlined
                v-model="itemx.transI"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                name="Referencia"
                :label="'No. Caja'"
                type="text"
                outlined
                v-model="itemx.caja"
              />
            </v-col>
            <v-col cols="12" md="6">
              <v-menu
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="itemx.fecha"
                    style="height: 10px; !important"
                    label="Fecha del evento"
                    outlined
                    v-bind="attrs"
                    class="Mytext"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="esp"
                  v-model="itemx.fecha"
                  @input="menu3 = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                name="Referencia"
                :label="'Transaccion de Cancelacion'"
                type="text"
                outlined
                v-model="itemx.transC"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-end">
          <v-btn depressed color="error" @click="dialog = false">
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed color="primary" @click="addX()">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { setColorHeadFooter } from "@/utils/StyleTools.js";

export default {
  name: "CancelManu",
  data() {
    return {
      search: "",
      menu3: false,
      selected: [],
      headers: [
        {
          text: "ID",
          align: "start",
          value: "id",
          align: "center",
        },
        { text: "Transaccion Original", value: "transI", align: "center" },
        { text: "No. Caja", value: "caja", align: "center" },
        { text: "Fecha del evento", value: "fecha", align: "center" },
        {
          text: "Transaccion de Cancelacion",
          value: "transC",
          align: "center",
        },
        { text: "Estatus", value: "activo", align: "center" },
        { text: "Actions", value: "actions", sortable: false, align: "center" },
      ],
      items: [],
      itemx: {
        transI: null,
        caja: null,
        fecha:  new Date().toISOString().slice(0, 10),
        transC: null,
      },
      dialog: false,
    };
  },
  mounted() {
    setColorHeadFooter();
    this.incio();
  },
  methods: {
    incio() {
      this.$root.post("/CC/ListcCancelation", {}).then((response) => {
        var info = response.data;
        this.items = info;
      });
    },
    deleteX(item) {
      this.$swal({
        icon: "warning",
        title: "Desea realmente eliminar el registro",
        text: null,
        showDenyButton: true,
        showCancelButton: false,
        reverseButtons: true,
        confirmButtonColor: "primary",
        confirmButtonText: "SI",
        denyButtonColor: "#dd8159",
      }).then((result) => {
        if (result.isConfirmed) {

          this.$root.post("/CC/DelCancelation", { item }).then((response) => {
            this.incio();
          });
        }
      });
    },
    add() {
      this.dialog = true;
      this.itemx= {
        transI: null,
        caja: null,
        fecha:  new Date().toISOString().slice(0, 10),
        transC: null,
      }
    },
    addX() {
      if(!this.itemx.transI){
        this.$root.swalAlert('error','','No capturo la transaccion original')
        return false
      }

      if(!this.itemx.caja){
        this.$root.swalAlert('error','','No capturo la caja')
        return false
      }

      if(!this.itemx.fecha){
        this.$root.swalAlert('error','','No capturo la Fecha del evento')
        return false
      }

      if(!this.itemx.transC){
        this.$root.swalAlert('error','','No capturo la Transaccion de Cancelacion')
        return false
      }

      let item = this.itemx

      this.$root.post("/CC/AddCancelation", { item }).then((response) => {
        this.incio();
        this.dialog = false
      });
    },
    editX(item){
      this.itemx = Object.assign({}, item);
      this.dialog = true
    }
  },
};
</script>

<style scoped>
.IconX {
  font-size: 35px;
}
.IconY {
  font-size: 18px;
}
</style>
