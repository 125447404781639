<template>
  <div>
    <v-card style="margin: 20px;" v-if="noDataCorps">
      <v-card-text>
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" >
          No hay corporaciones!
        </v-alert>
      </v-card-text>
    </v-card>
    <div v-else>
      <div v-if="ShowBack">
        <v-tabs v-model="tab" dark>
          <v-tabs-slider></v-tabs-slider>

          <v-tab id="tab1" key="tab-1">
            <v-icon class="TabIconSpace ma-2" left> mdi-ticket-confirmation-outline </v-icon>
            Boletos Disponibles
          </v-tab>

          <v-tab id="tab2" key="tab-2">
            <v-icon class="TabIconSpace ma-2" left> mdi-cloud-print-outline </v-icon>
            boletos generados
          </v-tab>

          <v-tab id="tab3" key="tab-3">
          <v-icon class="ma-2">mdi-clipboard-check-multiple-outline</v-icon>
            Transacciones
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-model="tab"
          class="MyFullDiv"
          style="background-color: transparent !important;"
        >
          <v-tab-item key="tab-1">
            <TablaTicketsAll :items="items" :noDataItem="noDataItem" :loading="loading" :nameCorp="nameCorp" :nowDate="nowDate"/>
          </v-tab-item>
          <v-tab-item key="tab-2">
            <TablaTicketsGenerated ref="TablaTicketsGenerated" @print-history="printHistory" :items="items2" :noDataItem="noDataItem2" :loading="loading" :nameCorp="nameCorp" :corpX="corpX" :nowDate="nowDate"/>
          </v-tab-item>
          <v-tab-item key="tab-3">
            <TablaTicketsTransac ref="TablaTicketsTransac" :itemsAll="items3" :noDataItem="noDataItem3" :loading="loading" :nameCorp="nameCorp" :corpX="corpX" :nowDate="nowDate"/>
          </v-tab-item>
        </v-tabs-items>
      </div>
      <v-row style="padding: 20px;" v-else>
        <v-col
          cols="6"
          sm="3"
          md="2"
          lg="2"
          xl="2"
          v-for="(item, index) in corpsX"
          :key="'Corps' + index"
        >
          <v-hover v-slot="{ hover }">
            <v-card
              class="MyCard"
              :elevation="hover ? 12 : 2"
              :class="{ 'on-hover': hover }"
              @click="openX(item)"
            >
              <div
                align="center"
                class="ImgAvartDiv"
                style="padding: 10px !important;position: relative;
        z-index: 2;"
              >
                <img :src="item.avatar" class="ImgCorp" style="height: 120px;" />
              </div>

              <v-expand-transition>
                <div
                  v-if="hover"
                  class="d-flex transition-fast-in-fast-out darken-2 v-card--reveal text-h2 white--text MyDecore"
                ></div>
              </v-expand-transition>

              <div align="center" class="Title">
                <strong>{{ item.name }}</strong>
              </div>
            </v-card>
          </v-hover>
        </v-col>
        <v-col
          v-if="loading"
          cols="6"
          sm="3"
          md="2"
          lg="2"
          xl="2">
          <v-skeleton-loader
            type="image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <ModalTable ref="ModalTableTablaTicketsGenerated" @closeDialog="closeDialog" v-if="dialog" :type="2" :itemPrint="itemPrint" :dataItemPrint="dataItemPrint" :textNoDataPrint="textNoDataPrint" :noDataPrint="noDataPrint"/>
  </div>
</template>

<script>
import { setColorHeadFooter } from "@/utils/StyleTools.js";
import { sumarDias } from "@/utils/ReportsTools.js";
import TablaTicketsAll from "./TablaTicketsAll.vue";
import TablaTicketsGenerated from "./TablaTicketsGenerated.vue";
import TablaTicketsTransac from "./TablaTicketsTransac.vue";
import ModalTable from './ModalTable.vue';

export default {
  components: {
    TablaTicketsAll,
    TablaTicketsGenerated,
    ModalTable,
    TablaTicketsTransac
  },
  data() {
    return {
      corpX: null,
      corpsX: [],
      corpsY: [],
      items: [],
      items2: [],
      items3: [],
      loading: true,
      noDataCorps: false,
      noDataItem: false,
      noDataItem1: false,
      noDataItem2: false,
      tab: 0,
      nowDate: '',
      dialog: false,
      noDataPrint: null,
      textNoDataPrint: '',
      itemPrint: [],
      dataItemPrint: [],
      flag: false,
    };
  },
  props: ["ShowBack", "search", "nameCorp"],
  watch: {
    search: function (val) {
      if(val == "" || val == null){
        this.corpsX = Object.assign({}, this.corpsY)
      } else { 
        var res = this.corpsY.filter(x => x.name.toLowerCase().includes(val.toLowerCase()))
        this.corpsX = res
      }
    },
    tab: function (val) {
      this.chargeData();
    }
  },
  mounted() {
    this.nowDate = sumarDias(new Date(Date.now()), 0)
  },
  methods: {
    chargeData: function () {
      this.loading = true
      if (!this.corpX) {
        this.corpsY = []
        this.noDataCorps = false
        this.$root.post('/wholesaler/GetCorpCarGob', {
          type: 1,
          corp: 0
        })
        .then( response => {
          this.loading  = false
          if (response.data) {
            this.$parent.ShowBack = false
            this.corpsY = response.data[0]
            this.$parent.search = ''
            this.noDataCorps = this.corpsY.length ? false : true
            this.corpsX = Object.assign({}, this.corpsY)
            setColorHeadFooter();
          }
        })
      } else {
        this.items = []
        this.items2 = []
        this.items3 = []
        this.noDataItem = false
        this.noDataItem2 = false
        this.noDataItem3 = false
        this.$root.post('/wholesaler/GetCorpCarGob', {
          type: 2 + this.tab,
          corp: this.corpX
        })
        .then( response => {
          this.loading  = false
          if (response.data) {
            var itemsTemp = response.data[0];
            if (this.tab == 2){
              this.items3 = itemsTemp;
              this.noDataItem3 = itemsTemp.length ? false : true;
              setTimeout(() => {
                setColorHeadFooter();
                this.$refs.TablaTicketsTransac.parseTransac()
              }, 100);
              return false;
            }
            itemsTemp.map(item => {
              item.data = JSON.parse(item.data);
            })
            if (this.tab) {
              this.items2 = itemsTemp;
              this.noDataItem2 = itemsTemp.length ? false : true;
            } else {
              this.items = itemsTemp;
              this.noDataItem = itemsTemp.length ? false : true;
            }
            setColorHeadFooter();
          }
        })
      }
      
    },
    openX: function (item) {
      this.$parent.ShowBack = true
      this.corpX = item.idcorp
      this.$parent.nameCorp = item.name
      this.tab = 0
      this.chargeData();
    },
    closeDialog: function () {
      this.itemPrint = [];
      this.dataItemPrint = []
      this.dialog = false;
    },
    printHistory: function (item, ticketType) {
      this.noDataPrint = null;
      this.itemPrint = [];
      this.dataItemPrint = [];
      this.textNoDataPrint = '';
      this.$root
      .post("/wholesaler/GetHistoryPrintedTransac", {type: 2, id: item.id, corp: this.corpX, ticketType: ticketType})
      .then((response) => {
        var info = response.data[0];
        if (info && info.length > 0) {
          this.itemPrint = ticketType;
          this.itemPrint.RefOri = item.clave;
          this.itemPrint.CorpName = response.data[1][0].name;
          this.dataItemPrint = info;
        } else{
          this.noDataPrint = true;
          this.textNoDataPrint = 'No hay registro de impresión para: ' + item.clave + ' Boleto: '+ ticketType.descripcion + (ticketType.alias ? `(${ticketType.alias})` : '')
        }
        this.dialog = true;
      });
    },
  }
};
</script>

<style scoped>
.MyDecore {
  width: 100%;
  height: 65%;
  position: absolute;
  top: 0px;
  z-index: 1;
  border-radius: 0px 0px 200% 0%;
  background: rgba(245, 124, 0, 0.3);
}
.MyCard {
  height: 100% !important;
}
.MyCard:hover {
  cursor: pointer;
  zoom: 105%;
  background: #ffdb9a;
  position: absolute;
  width: 100%;
  margin-left: -14px;
  margin-top: -10px;
  height: fit-content !important;
}
.MyCard:hover .Title {
  background: #ffdb9a !important;
  color: rgb(53, 53, 53);
}

.MyCard.ImgAvartDiv {
  width: 100%;
  height: 121px;
  display: flex !important;
  align-items: center !important;
  justify-content: space-around !important;
}
.MyCard.ImgAvartDiv.ImgCorp {
  max-height: 121px !important;
}
.MyCard .Title {
  padding: 10px;
  margin-bottom: 20px;
  background: orange;
  color: white;
  border-radius: 0 !important;
}
</style>
