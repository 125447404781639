<template>
  <div class="TicketsTransac">
    <v-card style="width:100vw; overflow:auto; height: calc(100vh - 228px);">
      <v-card-text v-if="noDataItem">
        <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" >
          No hay transacciones!
        </v-alert>
      </v-card-text>
      <div v-else>
        <v-card-title style="padding: 5px 25px 5px 26px;">
          <v-switch
            v-model="typeTransac"
            inset
            label="solo referencias Autorizadas"
          ></v-switch>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            label=""
            color="orange"
            placeholder="Buscar"
            prepend-icon="mdi-magnify"
            clearable
            single-line
            hide-details
            style="max-width: 400px"
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          :single-select="false"
          item-key="clave"
          class="m-4 mt-2"
          :sort-desc="[true, true]"
          :search="search"
          multi-sort
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(transac, index) in items">
                <tr
                  :key="'ReportPayment-' + index"
                  class="pointman"
                  style="color: black"
                  :style="{ 'background-color': backgrondRowTable(index), 'border-top':'2px solid #75695f', 'color': transac.f_autorizacion ? '' : 'red' }"
                >
                  <td class="MyTdPadding" align="center" width="20%;">
                    <strong>{{ transac.linearef }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="11%;">
                    <strong>{{ formatFecha(transac.f_creacion, '/') }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="16%;">
                    <strong>{{ transac.NAME }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="11%;">
                    <strong>{{ transac.f_autorizacion ? formatFecha(transac.f_autorizacion, '/') : '' }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="11%;">
                    <strong>{{ transac.total }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="11%;">
                    <strong>{{ transac.usados }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="11%;">
                    <strong>{{ transac.disponibles }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <v-btn
                      small
                      color="blue lighten-1"
                      class="ma-2"
                      @click="detailOfTransac(transac)"
                    >
                      <v-icon color="white">mdi-format-list-checkbox</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
        <v-row v-if="!typeTransac" class="mx-0" style="padding: 0px 25px 0px 26px;">
          <v-spacer></v-spacer>          
          <strong style="color: red">Los registros en color rojo son Líneas de Referencia no autorizadas</strong>
        </v-row>
      </div>
    </v-card>
    <v-progress-linear
      v-if="loading"
      color="orange"
      :height="5"
      indeterminate
    ></v-progress-linear>
    <div v-if="dialog" class="modal-x">
      <v-card outlined>
        <v-toolbar dark>
          <v-toolbar-title>{{item.linearef}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="card-modal-x">
          <v-card-title style="padding: 5px 25px 5px 26px;">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search2"
              label=""
              color="orange"
              placeholder="Buscar"
              prepend-icon="mdi-magnify"
              clearable
              single-line
              hide-details
              style="max-width: 400px"
            ></v-text-field>
            <v-btn height="40" color="success" class="ma-2 ml-7" @click="ExportarX">
              Exportar
            </v-btn>
          </v-card-title>
          <v-data-table
            :headers="headers1"
            :items="tickets"
            item-key="brcode"
            class=" m-4 mt-2"
            :sort-desc="[true, true]"
            :search="search2"
            multi-sort
          >
            <template v-slot:body="{ items }">
              <tbody>
                <template v-for="(ticketTransac, index2) in items">
                  <tr
                    :key="'ReportTicketsTransac-' + index2"
                    class="pointman"
                    style="color: black"
                    :style="{ 'background-color': backgrondRowTable(index2), 'border-top':'2px solid #75695f' }"
                  >
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.folioimp ? ticketTransac.folio : '' }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.NJOURNALNO }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.brcode }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.folioimp }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.fecha_creacion ? formatFecha(ticketTransac.fecha_creacion, '/') : '' }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.descripcion }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.alias }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.shortname }}</strong>
                    </td>
                  </tr>
                </template>
              </tbody>
            </template>
          </v-data-table>
          <div style="width:100%; overflow:auto" v-show="false" id="pageTG-wraperTicketsTransac">
            <table style="width:100%;">
              <tr align="right">
                <td colspan="9" style="font-size: 16px;">
                  <strong>Boletos de: </strong> {{nameCorp}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="9" style="font-size: 16px;">
                  <strong>Línea de Referencia: </strong> {{item.linearef}}
                </td>
              </tr>
              <tr align="right">
                <td colspan="9" style="font-size: 16px;">
                  <strong>Al día: </strong> {{formatFecha(nowDate, '/')}}
                </td>
              </tr>
              <tr></tr>
            </table>

            <div>
              <table
                border="1"
                bordercolor="#272727"
                class="styled-table"
                style="border-collapse: collapse; width:100%;"
              >
                <thead>
                  <tr>
                    <td class="MyTdPadding" align="star"><strong></strong></td>
                    <td class="MyTdPadding" align="center"><strong>Folio</strong></td>
                    <td class="MyTdPadding" align="center"><strong>Journal</strong></td>
                    <td class="MyTdPadding" align="center"><strong>Barcode</strong></td>
                    <td class="MyTdPadding" align="center"><strong>Folio de Impresion</strong></td>
                    <td class="MyTdPadding" align="center"><strong>Fecha de Impresión</strong></td>
                    <td class="MyTdPadding" align="center"><strong>Nombre del Boleto</strong></td>
                    <td class="MyTdPadding" align="center"><strong>Alias del Boleto</strong></td>
                    <td class="MyTdPadding" align="center"><strong>Parador Turistico</strong></td>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(ticketTransac, index2) in tickets" :key="`sitioReportTicketsTransac-${ticketTransac.id}-${index2}`">
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ index2 + 1  }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.folioimp ? ticketTransac.folio : '' }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.NJOURNALNO }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.brcode }}&nbsp;</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.folioimp }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.fecha_creacion ? formatFecha(ticketTransac.fecha_creacion, '/') : '' }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.descripcion }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.alias }}</strong>
                    </td>
                    <td class="MyTdPadding" align="center" width="10%;">
                      <strong>{{ ticketTransac.shortname }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";
import { formatNum } from "@/utils/NumericTools.js";
import { exportExcelJavascript, formatFecha } from "@/utils/ReportsTools.js";
import $ from "jquery";

export default {
  name: 'TablaTicketsGenerated',
  props: ["itemsAll", "noDataItem", "loading", "nameCorp", "corpX", "nowDate"],
  data(){
    return{
      formatNum, backgrondRowTable, formatFecha,
      flag: false,
      search: '',
      expanded: '',
      headers: [
        { text: "Línea de Referencia", value: "linearef", align: "center" },
        { text: "Fecha Orden de Pago", value: "f_creacion", align: "center" },
        { text: "Autorizó", value: "NAME", align: "center" },
        { text: "Fecha de Autorización", value: "f_autorizacion", align: "center" },
        { text: "Comprado(s)", value: "", align:"center" },
        { text: "Impreso(s)", value: "", align:"center" },
        { text: "Disponible(s)", value: "", align:"center" },
        { text: "Detalles", value: "", align:"center" },
      ],
      dialog: false,
      search2: '',
      items: [],
      item: {},
      tickets: [],
      headers1: [
        { text: "Folio", value: "folio", align: "center" },
        { text: "Journal", value: "NJOURNALNO", align: "center" },
        { text: "Barcode", value: "brcode", align: "center" },
        { text: "Folio de Impresión", value: "folioimp", align: "center" },
        { text: "Fecha de Impresión", value: "fecha_creacion", align: "center" },
        { text: "Nombre del Boleto", value: "descripcion", align: "center" },
        { text: "Alias del Boleto", value: "alias", align: "center" },
        { text: "Parador Turistico", value: "shortname", align: "center" },
      ],
      typeTransac: false
    }
  },
  watch: {
    typeTransac: function () {
      this.parseTransac();
    }
  },
  mounted(){
    setColorHeadFooter();
  },
  methods: {
    detailOfTransac: function (item) {
      if (item.f_autorizacion == "") {
        this.$swal({
          icon: 'warning',
          title: 'La Línea de Referencia no ha sido autorizada!',
        });
        return false
      }
      this.item = [];
      this.tickets = [];
      this.textNoDataPrint = '';
      this.search2 = '';
      this.$root
      .post("/wholesaler/GetHistoryPrintedTransac", {type: 3, id: item.id, corp: 0, ticketType: []})
      .then((response) => {
        var info = response.data[0];
        if (info && info.length > 0) {
          this.item = item;
          this.tickets = info;
          this.dialog = true;
          setTimeout(() => {
            setColorHeadFooter();
          }, 100);
        } else this.$swal({icon: 'warning', title: 'Algo salio mal!'})
      });
    },
    ExportarX: function(event){
      if(this.items.length == 0){
        this.$swal({
          icon: 'warning',
          title: 'no hay nada que exportar!',
        })
        return false
      }
      var table= document.getElementById('pageTG-wraperTicketsTransac');
      var html = table.outerHTML;
      exportExcelJavascript(html, event, `${this.nameCorp}-${this.item.linearef}-${formatFecha(this.nowDate, '-')}`)
    },
    parseTransac: function () {
      if (this.typeTransac) {
        var tempArray = this.itemsAll.filter(transac => transac.f_autorizacion != "")
        this.items = Object.assign([], tempArray);
      } else this.items = Object.assign([], this.itemsAll);
    }
  }
}
</script>

<style scoped>
.TicketsTransac {
  height: calc(100vh - 226px);
}
.modal-x {
  min-width: 100%;
  min-height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.card-modal-x {
  min-width: 100%;
  min-height: 100%;
  overflow: auto;
  height: calc(100vh - 290px);
}
.MyTdPadding {
  padding: 5px;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}
</style>