<template>
  <div id="api" style="width:100%;">
    <v-toolbar dark>
      <v-toolbar-title>
        Bitacoras de envio
      </v-toolbar-title>
    </v-toolbar>
    <v-tabs v-model="tab" dark>
        <v-tabs-slider color="orange"></v-tabs-slider>

        <v-tab id="tab1" key="tab-1">
          <v-icon class="ma-2">mdi-clipboard-text-outline</v-icon>
          Enviados
        </v-tab>
        <v-tab id="tab2" key="tab-2">
          <v-icon class="ma-2">mdi-clipboard-text-clock-outline</v-icon>
          Fuera del registro
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="MyFullDiv"
        style="background-color: transparent !important;"
      >
        <v-tab-item key="tab-1">
          <v-toolbar dark style="background-color: #1e1e1e;">
            <v-spacer></v-spacer>
            <v-menu v-model="menu1" absolute :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field                   
                    v-model="date1"
                    label="Seleccionar fecha"
                    outlined
                    dark
                    color="orange"
                    placeholder="Seleccionar fecha"
                    prepend-icon="mdi-calendar"
                    class="my-text-style mr-5"
                    style="margin-top: 30px; zoom: 80%; max-width: 300px"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="esp"
                v-model="date1"
                :max="nowDate"
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="search1"
              label=""
              outlined
              dark
              clearable
              color="orange"
              placeholder="Escribe lo que desea buscar"
              prepend-icon="mdi-magnify"
              class="my-text-style"
              style="margin-top: 30px; zoom: 80%;"
            ></v-text-field>
          </v-toolbar>
          <div style="margin-top: 0px; padding-top: 1px; height: calc(100vh - 178px); overflow: auto;">
            <v-card style="margin: 20px; zoom:90%;">
              <v-card-text v-if="noData1">
                <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" > 
                  No hay bitacoras para el día {{date1}}
                </v-alert>
              </v-card-text>
              <v-data-table
                v-else
                :headers="headers1"
                :items="items1"
                item-key="id"
                class="styled-table m-4 mt-2"
                :sort-desc="[true, true]"
                :search="search1"
                multi-sort
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <template v-for="(item, index) in items">
                      <tr :key="'ReportPayment-' + index">
                        <td align="center"><strong>{{item.iFolioBitacora}}</strong></td>
                        <td align="center">{{item.sCodigoRecepcion}}</td>
                        <td align="center">{{item.ConsecutivoEnvio}}</td>
                        <td align="center">{{item.idSucursal}}</td>
                        <td align="center">{{item.idEquipo}}</td>
                        <td align="center">{{item.idCajero}}</td>
                        <td align="center">{{item.cError}}</td>
                        <td align="center">{{item.fechaEnvio}}</td>
                        <!-- <td align="center">
                          <v-btn
                            small
                            dark
                            color="orange"
                            class="ma-2"
                            @click="sentBitaAafy(item, item.ConsecutivoEnvio)"
                          >
                            <v-icon>mdi-cloud-sync</v-icon>
                          </v-btn>
                        </td> -->
                        <td align="center" v-if="$root.token.subrol != 4">
                          <v-btn
                            small
                            dark
                            color="red"
                            class="ma-2"
                            @click="sentBitaAafy(item, 0)"
                          >
                            <v-icon>mdi-cloud-upload</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-data-table>
              <v-progress-linear
                v-if="loadingTable1"
                color="orange"
                :height="5"
                indeterminate
              ></v-progress-linear>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item key="tab-2">
          <v-toolbar dark style="background-color: #1e1e1e;">
            <v-spacer></v-spacer>
            <v-menu v-model="menu2" absolute :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field                   
                    v-model="date2"
                    label="Seleccionar fecha"
                    outlined
                    dark
                    color="orange"
                    placeholder="Seleccionar fecha"
                    prepend-icon="mdi-calendar"
                    class="my-text-style mr-5"
                    style="margin-top: 30px; zoom: 80%; max-width: 300px"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="esp"
                v-model="date2"
                :max="nowDate"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
            <v-text-field
              v-model="search2"
              label=""
              outlined
              dark
              clearable
              color="orange"
              placeholder="Escribe lo que desea buscar"
              prepend-icon="mdi-magnify"
              class="my-text-style"
              style="margin-top: 30px; zoom: 80%;"
            ></v-text-field>
          </v-toolbar>
          <div style="margin-top: 0px; padding-top: 1px; height: calc(100vh - 178px); overflow: auto;">
            <v-card style="margin: 20px; zoom:90%;">
              <v-card-text v-if="noData2">
                <v-alert class="m-1" icon="bx bxs-traffic-barrier" prominent text type="warning" align="left" > 
                  Se han enviado las del día {{date2}}
                </v-alert>
              </v-card-text>
              <v-data-table
                v-else
                :headers="headers2"
                :items="items2"
                item-key="id"
                class="styled-table m-4 mt-2"
                :sort-desc="[true, true]"
                :search="search2"
                multi-sort
              >
                <template v-slot:body="{ items }">
                  <tbody>
                    <template v-for="(item, index) in items">
                      <tr :key="'ReportPayment-' + index">
                        <td align="center">{{item.sucursal}}</td>
                        <td align="center">{{item.name}}</td>
                        <td align="center">{{item.caja}}</td>
                        <td align="center">{{item.IDEquipo}}</td>
                        <td align="center">{{item.IDCajero}}</td>
                        <td align="center">{{item.NombreCajero}}</td>
                        <td align="center" v-if="$root.token.subrol != 4">
                          <v-btn
                            small
                            dark
                            color="red"
                            class="ma-2"
                            @click="sentNoRegistBitaAafy(item)"
                          >
                            <v-icon>mdi-cloud-upload</v-icon>
                          </v-btn>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </template>
              </v-data-table>
              <v-progress-linear
                v-if="loadingTable2"
                color="orange"
                :height="5"
                indeterminate
              ></v-progress-linear>
            </v-card>
          </div>
        </v-tab-item>
      </v-tabs-items>
  </div>
</template>

<script>
import { sumarDias } from "@/utils/ReportsTools.js";

  export default {
    data() {
      return{
        tab: 0,
        nowDate: '',
        menu1: false,
        date1: '',
        search1: '',
        loadingTable1: false,
        noData1: false,
        headers1: [
          { text: "Folio en Bitacora", value: "iFolioBitacora", width: "", filterable: true },
          { text: "Código de Recepción", value: "sCodigoRecepcion", width: "", filterable: true },
          { text: "Consecutivo de Envio", value: "ConsecutivoEnvio", width: "", filterable: true },
          // { text: "Sucursal", value: "nameSucursal", width: "", filterable: true },
          { text: "Sucursal", value: "idSucursal", width: "", filterable: true },
          // { text: "#Caja", value: "idEquipo", width: "", filterable: true },
          { text: "Caja", value: "idEquipo", width: "", filterable: true },
          // { text: "#cajero", value: "idCajero", width: "", filterable: true },
          { text: "Cajero", value: "idCajero", width: "", filterable: true },
          { text: "Descripción de Error", value: "cError", width: "", filterable: true },
          { text: "Fecha Envio", value: "fechaEnvio", width: "", filterable: true },
          //{ text: "Reenviar", value: "", width: "", filterable: false },
          { text: "Nuevo envio", value: "", width: "", filterable: false },
        ],
        items1: [],
        menu2: false,
        date2: '',
        search2: '',
        loadingTable2: false,
        noData2: false,
        headers2: [
          { text: "Sucursal", value: "sucursal", width: "", filterable: true },
          { text: "Nombre Sucursal", value: "name", width: "", filterable: true },
          { text: "Caja", value: "caja", width: "", filterable: true },
          { text: "Nombre Caja", value: "IDEquipo", width: "", filterable: true },
          { text: "Cajero", value: "IDCajero", width: "", filterable: true },
          { text: "Nombre Cajero", value: "NombreCajero", width: "", filterable: true },
          { text: "Enviar", value: "", width: "", filterable: false }
        ],
        items2: [],
      }
    },
    watch:{
      date1: function () {
        this.loadDataBit();
      },
      date2: function () {
        this.loadDataNoRegister();
      },
    },
    mounted(){
      this.date1 = sumarDias(new Date(Date.now()), -1)
      this.date2 = sumarDias(new Date(Date.now()), -1)
      this.nowDate = sumarDias(new Date(Date.now()), -1)

      if (this.$root.token.rol === 1 && this.$root.token.subrol === 4) {
        this.headers1.pop()
        this.headers2.pop()
      }
    },
    methods:{
      loadDataBit: function(){
        this.loadingTable1 = true;
        this.noData1 = false;
        this.$root.post("/api2/getCatalogues/GetBitaAafy", {
          dateX: this.date1,
        }).then((response) => {
          if (response.data) {
            this.loadingTable1 = false;
            this.items1 = response.data;
            if (!this.items1.length) {
              this.noData1 = true
            }
          }
        });
      },
      sentBitaAafy: function (item, consecutivo) {
        var bit = {
          fecha:        this.date1,
          suc:          item.idSucursal,
          caja:         item.idEquipo,
          cajero:       item.idCajero,
          consecutivo:  consecutivo
        }
        this.$swal({
          title: "Esta seguro?",
          text: `Realmente desea ${consecutivo == 0 ? 'realizar un nuevo envio' : 'reenviar la informacion' }!`,
          icon: "warning",
          showDenyButton: true,
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "primary",
          confirmButtonText: "SI",
          denyButtonColor: "#dd8159",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SendDataAafy(bit,1)
          }
        });
      },
      loadDataNoRegister: function(){
        this.loadingTable2 = true;
        this.noData2 = false;
        this.$root.post("/api2/getCatalogues/GetCajaFaltAafy", {
          dateX: this.date2,
        }).then((response) => {
          if (response.data) {
            this.loadingTable2 = false;
            this.items2 = response.data[0];
            if (!this.items2.length) {
              this.noData2 = true
            }
          }
        });
      },
      sentNoRegistBitaAafy: function (item) {
        var bit = {
          fecha:        this.date2,
          suc:          item.sucursal,
          caja:         item.caja,
          cajero:       item.IDCajero
        }
        this.$swal({
          title: "Esta seguro?",
          text: `Realmente desea enviar la informacion!`,
          icon: "warning",
          showDenyButton: true,
          showCancelButton: false,
          reverseButtons: true,
          confirmButtonColor: "primary",
          confirmButtonText: "SI",
          denyButtonColor: "#dd8159",
        }).then((result) => {
          if (result.isConfirmed) {
            this.SendDataAafy(bit,2)
          }
        });
      },
      SendDataAafy: function(item, opt){
        console.log(item);
        this.$root.post("/AFFY/SendCajaAFFY", {
          dateX: item,
        }).then((response) => {
          console.log(response.data);
          var info = response.data
          switch(opt){
            case 1:
              this.loadDataBit();
              break

            case 2:
              this.loadDataNoRegister();
              break
          }
          if(info.success == true){
            this.$root.swalAlert('success','Agregado correctamente',null)
          }else{
            this.$root.swalAlert('error',info.msg, null)
          }

        });
      }
    }
  }
</script>

<style scoped>
.my-text-style >>> .v-text-field__slot input {
  text-transform: uppercase;
}
</style>