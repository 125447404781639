<template>
  <div>
    <div class="MyPaddingV">
      <v-card class="MyTdPadding" style="padding: 20px;">
        <v-row>
          <v-col cols="12">
            <h3 class="text-center"><strong>Reporte de cajero</strong></h3>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="MyForm">
            <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field                   
                    v-model="date"
                    style="height: 10px; !important"
                    label="Seleccione una fecha de inicio"
                    prepend-icon="mdi-calendar"
                    outlined
                    readonly
                    v-bind="attrs"
                    class="Mytext"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="esp"
                v-model="date"
                :max="nowDate"
                @input="ChangeDate(1)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="MyForm">
            <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field                   
                  v-model="date2"
                  style="height: 10px; !important"
                  label="Seleccione una fecha de fin"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  class="Mytext"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="esp"
                v-model="date2"
                :max="nowDate"
                @input="ChangeDate(2)"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="MyForm" v-if="rol == 0">
            <v-select prepend-icon="mdi-bank" dense outlined :items="cajas" v-model="cajaX" name="id" item-text="name" label="Seleccione un cajero" background-color='white' item-value="id"></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="3" class="Mypadding2 MyForm" align="center">
            <v-btn :loading="Generete" :disabled="Generete" height="40" color="primary" class="ma-2" @click="GenereteX">
              GENERAR
            </v-btn>
            <v-btn :loading="Generete" :disabled="Generete" height="40" color="success" class="ma-2" @click="ExportarX">
              Exportar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div style="width: 100%; height: calc(100vh - 294px); ">
      <div v-if="DataEmpty" style="padding: 10px 20px">
        <h5>NO HAY VENTAS DE {{date}} - {{date2}}</h5>
      </div>

      <div id="page-wrap" v-if="DetTicket.length > 0" align="center">
        <TablaReportMayo :subtitleX="subtitleX" :DetUser="DetUser" :DetTicket="DetTicket" :totalX="totalX" :transacX="transacX"/>
      </div>
    </div>
  </div>
</template>

<script>
import { exportExcelJavascript, sumarDias } from "@/utils/ReportsTools.js";
import TablaReportMayo from './TablaReportMayo.vue'
export default {
  components: {
    TablaReportMayo
  },
  data(){
    return{      
      menu2: false,
      menu3: false,
      date: '',
      date2: '',
      nowDate: '',
      Generete: false,
      titulox: '',
      subtitleX: '',
      DetUser: [],
      DetTicket: [],
      totalX: 0,
      DataEmpty: false,
      rol: null,
      cajas: [],
      cajaX: null
    }
  },
  mounted(){
    this.date = sumarDias(new Date(Date.now()), 0)
    this.date2 = sumarDias(new Date(Date.now()), 0)
    this.nowDate = sumarDias(new Date(Date.now()), 0)
    if (this.$root.token) {
      this.rol = this.$root.token.rol
      this.loadCajas();
    }
  },
  methods:{
    loadCajas: function () {
      if (this.rol == 0) {
        this.cajaX= null;
        this.$root.post("/Report/POSReportDayU", {
          dateX: this.date,
          dateY: this.date2
        }).then((response) => {  
          if (response.data) {
            this.cajas = response.data;
          }
        });
      }
    },
    ExportarX: function(event){
      if(this.DetTicket.length == 0){
        this.$swal({
          icon: 'warning',
          title: 'Genera el reporte!',
        })
        return false
      }
      var table= document.getElementById('page-wrap');
      var html = table.outerHTML;
      exportExcelJavascript(html, event, this.titulox)
    },
    GenereteX: function (event) {
      this.DetUser = []
      this.DetTicket = []
      this.transacX = []
      this.Generete = true
      this.DataEmpty = false

      if (this.rol == 0) {
        if(!this.cajaX){
          this.$swal({
            icon: 'warning',
            title: 'Seleccione un Cajero',
          })
          this.Generete = false
          return false
        }
      }
      

      this.$root.post("/Report/ReportDayU", {
        dateX: this.date,
        dateY: this.date2,
        id: this.cajaX
      }).then((response) => {  
        if (response.data[0].length > 0 && response.data[1].length > 0) {
          this.DetTicket = response.data[0];
          this.DetTicket.map(item => {
            item.data = JSON.parse(item.data)
          })
          this.DetUser = response.data[1][0];
          this.transacX = response.data[2];
          this.transacX.map(item => {
            item.sitio = JSON.parse(item.sitio)
          })
          this.titulox = `REPORTE_CAJERO-${this.DetUser.name}_${this.DetUser.name2}_${this.DetUser.name3}-${this.DetUser.userId}-${this.date}_00:00-${this.date2}_23:59`
          this.subtitleX = `Del: ${this.date} 00:00, Hasta: ${this.date2} 23:59`
        } else{
          this.DataEmpty = true
        }
        this.Generete = false
      });        
    },
    ChangeDate(menu){
      this.DetUser = []
      this.DetTicket = []
      this.transacX = []
      this.DataEmpty = false
      if(menu == 1){
        this.menu2 = false
      }else{
        this.menu3 = false
      }
      this.loadCajas();
    },
  }
};
</script>

<style scoped>
  .MyForm{
    height: 75px;
  }
  .MyTdPadding{
    padding: 5px;
  }
  .Mypadding2{
    padding: 4px !important;
  }
  .MyPaddingV{
    margin: 25px 20px 25px 20px;
    zoom: 90%;
  }
  .Mytext .v-input__control div fieldset{
    max-height: 45px;
  }
  .Mytext .v-input__control div input{
    margin-top: -8px;
  }
  .Mytext .v-input__prepend-outer{
    margin: 9px !important;
  }
  #page-wrap{
    margin-top:20px;
    height: calc(100vh - 168px);
    overflow: auto; 
    padding: 7px 10px 26px 24px;
    zoom: 80%;
    margin-right: 10px;
  }
</style>