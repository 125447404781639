<template>
  <div style="width:100%;">
    <v-toolbar dark>
      <v-toolbar-title>
        Modulo de Mayoristas
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn dark color="orange"  @click="OpenView()">
        <i class="bx bx-calculator" style="font-size: 20px;"></i>
        Verificador
      </v-btn>
    </v-toolbar>
    <div>
      <v-tabs v-model="tab" class="masterTabs" dark next-icon="mdi-arrow-right-bold-box-outline"
      prev-icon="mdi-arrow-left-bold-box-outline"
      show-arrows>
        <v-tabs-slider color="orange"></v-tabs-slider>

        <v-tab id="tab1" key="tab-1">
          <v-icon class="ma-2">mdi-clipboard-text-clock-outline</v-icon>
          Por validar
        </v-tab>
        <v-tab id="tab2" key="tab-2">
          <v-icon class="ma-2">mdi-clipboard-check-multiple-outline</v-icon>
          Autorizados
        </v-tab>
        <v-tab id="tab3" key="tab-3">
          <v-icon class="ma-2">mdi-cancel</v-icon>
          Bloqueados
        </v-tab>
        <v-tab id="tab4" key="tab-4">
          <v-icon class=" ma-2">mdi-calendar-clock</v-icon>
          Vencidos
        </v-tab>
        <v-tab id="tab6" key="tab-5">
          <v-icon class="ma-2">mdi-ticket-confirmation-outline</v-icon>
          Boletos
        </v-tab>
        <v-tab id="tab5" key="tab-6">
          <v-icon class="ma-2">mdi-file-document-edit-outline</v-icon>
          Reporte
        </v-tab>
        <v-tab id="tab7" key="tab-7">
          <v-icon class="ma-2">mdi-clipboard-text-search-outline</v-icon>
          Buscador
        </v-tab>
      </v-tabs>

      <v-tabs-items
        v-model="tab"
        class="MyFullDiv"
        style="background-color: transparent !important;"
      >
        <v-tab-item key="tab-1">
          <TablaMayo ref="TablaMayo1" :TLoading="TLoading" :headers="headers" :items="items" :noData="tNoDat" :vencidos="vencidos"/>
        </v-tab-item>
        <v-tab-item key="tab-2">
          <MayoList ref="MayoList1" :tabx="tab" />
        </v-tab-item>
        <v-tab-item key="tab-3">
          <MayoList ref="MayoList2" :tabx="tab" />
        </v-tab-item>
        <v-tab-item key="tab-4">
          <TablaMayo ref="TablaMayo2" :TLoading="TLoading" :headers="headers" :items="items" :noData="tNoDat" :vencidos="vencidos"/>
        </v-tab-item>
        <!-- <v-tab-item key="tab-6" v-if="rol"> -->
        <v-tab-item key="tab-5">
          <CardCorpBoletos ref="CorpBoletos" />
        </v-tab-item>
        <v-tab-item key="tab-6">
          <Report ref="Report" />
        </v-tab-item>
        <v-tab-item key="tab-7">
          <SearchEngine ref="SearchEngine" />
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { sumarDias } from "@/utils/ReportsTools.js";
import TablaMayo from "./TabalMayoGob.vue";
import MayoList from "./Mayorista.vue";
import Report from "./Reporte.vue"
import CardCorpBoletos from "./CardCorpBoletos.vue";
import SearchEngine from './SearchEngine.vue'

export default {
  components: {
    TablaMayo,
    MayoList,
    Report,
    CardCorpBoletos,
    SearchEngine
  },
  data() {
    return {
      TLoading: true,
      tNoDat: null,
      OpenX:null,
      tab: 0,
      headers: [
        { text: '', value: ''},
        { text: "Línea de Referencia", value: "RefOri", width: "280px" },
        { text: "Fecha de vencimiento", value: "Date", align: "center", width: "189px", },
        { text: "Valido", value: "valido" },
        { text: "Nombre de la Empresa", value: "CorpName" },
        { text: "Fecha Orden de Pago", value: "fecha", align: "center", width: "189px", },
        { text: "Costo Total", value: "total" },
        { text: "Total de Boletos", value: "cBoletos", align: "center" },
        { text: "Validar", value: "", align: "center" },
        { text: "Bloquear", value: "", align: "center" },
      ],
      items: [],
      nowDate: '',
      vencidos: 1,
      rol: 0
    };
  },
  mounted() {
    this.begin();
    this.nowDate = sumarDias(new Date(Date.now()), 0)
    var rol = this.$root.token.rol
    var subrol = this.$root.token.subrol
    if (rol == 0) {
      this.rol = 1
    } else if(rol == 1 && subrol == 0){
      this.rol = 1
    }
  },
  watch: {
    tab: function (val) {
      switch(val){
        case 0:
          this.begin()
          break
        case 1:
          setTimeout(() => {
            this.$refs.MayoList1.$refs.CardCorp.status = 2
            switch(this.$refs.MayoList1.row){
              case "radio1":
                if(this.$refs.MayoList1.$refs.CardCorp.$refs.TabalMayoGob2){
                  this.$refs.MayoList1.$refs.CardCorp.beginc2(2, this.OpenX.idcorp)
                }else{
                  this.$refs.MayoList1.$refs.CardCorp.beginc(2,0)
                }
                break
              case "radio2":
                this.$refs.MayoList1.$refs.CardCorp.onEnter()
                break
              case "radio3":
                this.$refs.MayoList1.$refs.CardCorp.onFecha(this.$refs.MayoList1.date)
                break
            }
            
          }, 10);
          break
        case 2:
          setTimeout(() => {
            this.$refs.MayoList2.$refs.CardCorp.status = 3
            switch(this.$refs.MayoList2.row){
              case "radio1":
                if(this.$refs.MayoList2.$refs.CardCorp.$refs.TabalMayoGob2){
                  this.$refs.MayoList2.$refs.CardCorp.beginc2(2, this.OpenX.idcorp)
                }else{
                  this.$refs.MayoList2.$refs.CardCorp.beginc(3,0)
                }
                break
              case "radio2":
                this.$refs.MayoList2.$refs.CardCorp.onEnter()
                break
              case "radio3":
                this.$refs.MayoList2.$refs.CardCorp.onFecha(this.$refs.MayoList2.date)
                break
            }
          }, 10);
          break
        case 3:
          this.beginVencidos()
          break
        case 4:
          setTimeout(() => {
            this.$refs.CorpBoletos.$refs.CardCorp.chargeData()
          }, 10);
      }
    }
  },
  methods: {
    async begin() {
      this.items = []
      this.TLoading = true
      this.tNoDat = null
      this.vencidos = 1;
      this.$root
        .post("/wholesaler/GetTransCarGob", { status: 1, opt:0, type: 1 })
        .then((response) => {
          var info = response.data[0];
          this.TLoading = false
          
          if (info.length) {
            for (var i = 0; i < info.length; i++) {
              info[i].sitio = JSON.parse(info[i].sitio);
              info[i].valido = "Verificando..";
              info[i].total   = info[i].total.toFixed(2)

              // Aqui esta la parte de la validacion de la referencia --------------
              this.GeneratorLineRefX(info, i, info.length - 1);
            }
          } else {
            this.tNoDat = true
          }      
        });
    },
    async beginVencidos() {
      this.items = []
      this.TLoading = true
      this.tNoDat = null
      this.vencidos = 2;
      var lSearch = ''
      setTimeout(() => {
        lSearch = this.$refs.TablaMayo2.search2
        this.$refs.TablaMayo2.noDataText = lSearch == '' ? '' : `No hay vencidos sin validar para '${lSearch}'`
        this.$root
        .post("/wholesaler/GetTransCarGob", { status: 1, opt:0, type: 4, linea: lSearch })
        .then((response) => {
          var info = response.data[0];
          this.TLoading = false
          
          if (info.length) {
            for (var i = 0; i < info.length; i++) {
              info[i].sitio = JSON.parse(info[i].sitio);
              info[i].valido = "Verificando..";
              info[i].total   = info[i].total.toFixed(2)

              // Aqui esta la parte de la validacion de la referencia --------------
              this.GeneratorLineRefX(info, i, info.length - 1);
            }
          } else {
            this.tNoDat = true
          }
        });
      }, 1);
    },
    async GeneratorLineRefX(info, i, leg) {
      info[i].valido = await this.GeneratorLineRef(info[i]);

      if (i >= leg) {
        this.items = info;
      }
    },
    async GeneratorLineRef(Info) {
      var constante = 2;
      var residuo = 97;
      var prefijo = 201;
      var CodeF;
      var CodeI;
      var CodeR;

      var valX = 0;

      if (Info) {
        //Fecha -------------------------------------------------
        if (!Info.Date) {
          valX = 1;
        } else {
          CodeF = await this.getCodeDate(Info.Date);
        }

        if (!Info.total) {
          valX = 1;
        } else {
          CodeI = await this.getCodeImport("" + Info.total);
        }

        if (!Info.RefOri) {
          valX = 1;
        } else {
          CodeR = await this.getCodeRef(Info.RefOri, constante, residuo);
        }

        return new Promise(function(resolve, reject) {
          if (valX == 1) {
            resolve("NO");
          } else {
            var Valido;
            var LineRef =
              CodeR["folioRef"] + CodeF + CodeI + constante + CodeR["Refclave"];

            if (LineRef == Info.RefOri) {
              Valido = "SI";
              //this.mycolor = "green"
            } else {
              Valido = "NO";
              //this.mycolor = "red"
            }
            resolve(Valido);
          }
        });
      } else {
        return new Promise(function(resolve, reject) {
          resolve("NO");
        });
      }
    },
    async getCodeDate(FechaX) {
      var vx = this.$root;
      return new Promise(function(resolve, reject) {
        var CodeY, CodeM, CodeD;
        var CodeF = "";
        if (!FechaX) {
          resolve("");
          return false;
        }
        var date = FechaX.split("-");

        if (date.length == 1) {
          date = FechaX.split("/");
        }

        //if (!date[2]) {
        //  vx.swalAlert("warning", "Formato fecha incorrecto", null);
        //  return false;
        //}

        if (date[2].length == 4) {
          var year = date[2];
          date[2] = date[0];
          date[0] = year;
        }

        for (var x = 0; x < date.length; x++) {
          date[x] = parseInt(date[x]);
        }

        CodeY = (date[0] - 2013) * 372;
        CodeM = (date[1] - 1) * 31;
        CodeD = date[2] - 1;

        // solo enteros
        var CodeF2 = "" + parseInt(CodeY + CodeM + CodeD);

        for (var x = 0; x < 4; x++) {
          if (CodeF2[x]) {
            CodeF = CodeF + CodeF2[x];
          } else {
            CodeF = "0" + CodeF;
          }
        }

        resolve(CodeF);
      });
    },
    async getCodeImport(importX) {
      var vx = this.$root;
      return new Promise(function(resolve, reject) {
        var CodeF2, CodeF, CodeI, Ponde;
        var CodeI = (Ponde = CodeP = "");
        var impot = importX;
        var Zuma = 0;
        var con = 0;

        if (!impot) {
          resolve("");
          return false;
        }

        impot = impot.replaceAll(",", "");

        var verifi = impot.split(".");

        if (verifi.length == 1) {
          impot = impot + ".00";
        } else {
          impot = verifi[0] + "." + verifi[1];
        }

        if (impot.length < 12) {
          var vlx = 0;
          Ponde = "71371371.37";
          for (var x = 0; x < 11; x++) {
            if (impot[x]) {
              CodeI = CodeI + impot[x];
            } else {
              CodeI = "0" + CodeI;
            }
          }
        } else {
          CodeI = impot;
          for (var x = 0; x < impot.length; x++) {
            let val = impot[x];

            if (val == ".") {
              Ponde = Ponde + ".";
            } else {
              switch (con) {
                case 0:
                  Ponde = Ponde + "7";
                  //Zuma = Zuma + (parseInt(val) * 7)
                  con = 1;
                  break;
                case 1:
                  Ponde = Ponde + "1";
                  //Zuma = Zuma + (parseInt(val) * 1)
                  con = 2;
                  break;
                case 2:
                  Ponde = Ponde + "3";
                  //Zuma = Zuma + (parseInt(val) * 3)
                  con = 0;
                  break;
              }
            }
          }
        }
        
        for (var x = 0; x < Ponde.length; x++) {
          if (CodeI[x] != ".") {
            Zuma = Zuma + parseInt(CodeI[x]) * parseInt(Ponde[x]);
          }
        }

        var i = 0;
        var ii = false;

        do {
          let y = Zuma % 10;
          if (y == i) {
            ii = true;
          } else {
            i = y;
          }
        } while (ii == false);
        var CodeP = "" + i;
        resolve(CodeP);
      });
    },
    async getCodeRef(RefOriX, constante, residuo) {
      var vx = this.$root;
      return new Promise(function(resolve, reject) {
        var constant = 2;
        //limite 24 caracteres + valor constante 2

        if (!RefOriX) {
          resolve("");
          return false;
        }

        if (RefOriX.length < 24) {
          resolve("");
          return false;
        }

        var RefOri = RefOriX.substring(0, 24) + constant;
        var ref = [];
        var ponde = [];
        var con = 11;
        var Zuma = 0;

        for (var x = 0; x < RefOri.length; x++) {
          ponde.push(con);
          con = con + constant;
          constant = constant + constante;

          if (con >= 25) {
            con = 11;
            constant = constante;
          }
          if (constant == 6) {
            constant = constante;
          }
        }
        var ponde = ponde.reverse();

        for (var x = 0; x < ponde.length; x++) {
          Zuma = Zuma + parseInt(RefOri[x]) * parseInt(ponde[x]);
        }

        let y = (Zuma % residuo) + 1;

        if (y < 10) {
          y = "0" + parseInt(y);
        } else {
          y = "" + parseInt(y);
        }
        
        var data = { Refclave: y, folioRef: RefOriX.substring(0, 19) };
        resolve(data);
      });
    },
    OpenView(){
      let routeData = this.$router.resolve({name: 'LineaRef', query: {data: "someData"}});
      window.open(routeData.href,'popUpWindow','height=500,width=500,left=100,top=100,resizable=no,scrollbars=yes,toolbar=no,menubar=no,location=no,directories=no, status=no');
    }
  },
};
</script>

<style scoped>
.MyFullDiv {
  height: calc(100vh - 105px);
  overflow: auto;
}
/* width */
::-webkit-scrollbar {
  width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fa9f31;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #505050;
}

/* @media (max-width: 400px) { */
@media (max-width: 830px){
  .masterTabs {
    width: calc(100% - 27px) !important;
  }
}
@media (max-width: 700px) and (orientation: landscape),
  (max-width: 530px) and (orientation: portrait) {
  ::-webkit-scrollbar {
    width: 4px;
  }
}
</style>
