<template>
    <div class="DivFull">
        <v-toolbar dark>
            <v-toolbar-title>
                Reportes
            </v-toolbar-title>
            <v-spacer></v-spacer>
            
            <v-tooltip bottom v-if="ShowPrinter">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn fab dark small color="green" v-bind="attrs" v-on="on" @click="Imprimir()" class="ReportPrint">
                        <span class="material-icons">
                            print
                        </span>
                    </v-btn>
                </template>
                <span>Imprimir Reporte</span>
            </v-tooltip>            

            <v-btn class="ma-2" color="orange darken-2" dark v-if="!ShowReports" @click="BackReports">
                <v-icon dark left>
                    mdi-arrow-left
                </v-icon>
                Back
            </v-btn>            

        </v-toolbar>

        <div style="width: 100%; height: calc(100vh - 66px); overflow: auto; overflow-x: hidden;">
            
            <v-row v-if="ShowReports && (entidi == 0 || entidi == 7)" style="padding:20px;">
                <v-col  cols="12">
                    <div class="Paralelogramo" style="margin:20px;">
                        <div class="TextPAragon">
                            <strong>PROMTEC</strong>
                        </div>
                    </div>
                </v-col>
                <template v-for="(item, i) in items0">
                    <v-col  cols="12" sm="12" md="6" lg="4" xl="3" :key="'ReportInd-'+i" @click="OpenReport(item)" class="MyCardLX">
                        <v-hover v-slot="{ hover }">
                            <v-card class="CardFormat" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                <v-card-title>
                                    <div class="icon">
                                        <img :src="item.icon">
                                    </div>
                                    <span class="TitleText2">{{item.title}}<br>{{item.title2}}</span>
                                </v-card-title>

                                <v-card-subtitle style="margin-top: 0px;">
                                    <strong>
                                    {{item.subtext}}
                                    </strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-hover>
                    </v-col>
                </template>
            </v-row>

            <v-row v-if="ShowReports" style="padding:20px;">
                <v-col  cols="12">
                    <div class="Paralelogramo" style="margin:20px;">
                        <div class="TextPAragon">
                            <strong>RECAUDACIÓN POR</strong>
                        </div>
                    </div>
                </v-col>
                <template v-for="(item, i) in items">
                    <v-col  cols="12" sm="12" md="6" lg="4" xl="3" :key="'ReportInd-'+i" @click="OpenReport(item)" class="MyCardLX">
                        <v-hover v-slot="{ hover }">
                            <v-card class="CardFormat" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                <v-card-title>
                                    <div class="icon">
                                        <img :src="item.icon">
                                    </div>
                                    <span class="TitleText">{{item.title}}</span>
                                </v-card-title>

                                <v-card-subtitle style="margin-top: 0px;">
                                    <strong>
                                    {{item.subtext}}
                                    </strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-hover>
                    </v-col>
                </template>
            </v-row>    

            <v-row v-if="ShowReports" style="padding:20px;">
                <v-col  cols="12">
                    <div class="Paralelogramo" style="margin:20px;">
                        <div class="TextPAragon">
                            <strong>BOLETOS</strong>
                        </div>
                    </div>
                </v-col>
                <template v-for="(item, i) in items2">
                    <v-col  cols="12" sm="12" md="6" lg="4" xl="3" :key="'ReportInd-'+i" @click="OpenReport(item)" class="MyCardLX">
                        <v-hover v-slot="{ hover }">
                            <v-card class="CardFormat" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                <v-card-title>
                                    <div class="icon">
                                        <img :src="item.icon">
                                    </div>
                                    <span class="TitleText2">{{item.title}}<br>{{item.title2}}</span>
                                </v-card-title>

                                <v-card-subtitle style="margin-top: 0px;">
                                    <strong>
                                    {{item.subtext}}
                                    </strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-hover>
                    </v-col>
                </template>
            </v-row>

            <v-row v-if="ShowReports" style="padding:20px;">
                <v-col  cols="12">
                    <div class="Paralelogramo" style="margin:20px;">
                        <div class="TextPAragon">
                            <strong>TRANSACCIONES</strong>
                        </div>
                    </div>
                </v-col>
                <template v-for="(item, i) in items3">
                    <v-col  cols="12" sm="12" md="6" lg="4" xl="3" :key="'ReportInd-'+i" @click="OpenReport(item)" class="MyCardLX">
                        <v-hover v-slot="{ hover }">
                            <v-card class="CardFormat" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                <v-card-title>
                                    <div class="icon">
                                        <img :src="item.icon">
                                    </div>
                                    <span class="TitleText2">{{item.title}}<br>{{item.title2}}</span>
                                </v-card-title>

                                <v-card-subtitle style="margin-top: 0px;">
                                    <strong>
                                    {{item.subtext}}
                                    </strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-hover>
                    </v-col>
                </template>
            </v-row>

            <v-row v-if="ShowReports" style="padding:20px;">
                <v-col  cols="12">
                    <div class="Paralelogramo" style="margin:20px;">
                        <div class="TextPAragon">
                            <strong>MAYORISTAS</strong>
                        </div>
                    </div>
                </v-col>
                <template v-for="(item, i) in items4">
                    <v-col  cols="12" sm="12" md="6" lg="4" xl="3" :key="'ReportInd-'+i" @click="OpenReport(item)" class="MyCardLX">
                        <v-hover v-slot="{ hover }">
                            <v-card class="CardFormat" :elevation="hover ? 12 : 2" :class="{ 'on-hover': hover }">
                                <v-card-title>
                                    <div class="icon">
                                        <img :src="item.icon">
                                    </div>
                                    <span class="TitleText2">{{item.title}}<br>{{item.title2}}</span>
                                </v-card-title>

                                <v-card-subtitle style="margin-top: 0px;">
                                    <strong>
                                    {{item.subtext}}
                                    </strong>
                                </v-card-subtitle>
                            </v-card>
                        </v-hover>
                    </v-col>
                </template>
            </v-row>

            <ReportePromtec v-if="ShowReportePromtec"/>
            <ReportePromtecFinal v-if="ShowReportePromtecFinal"/>
            <ReportePromtecFinalInterno v-if="ShowReportePromtecFinalInterno"/>            
            <Report1 v-if="ShowReports1"/>
            <Report2 v-if="ShowReports2"/>
            <Report3 v-if="ShowReports3"/>
            <Report4 v-if="ShowReports4"/>
            <Report5 v-if="ShowReports5"/>
            <Report6 v-if="ShowReports6"/>
            <Report7 v-if="ShowReports7"/>

            <Report10 v-if="ShowReports10"/>
            <Report11 v-if="ShowReports11"/>
            <Report14 v-if="ShowReports14"/>


        </div>

    </div>
    
</template>


<script>
import VanillaTilt from 'vanilla-tilt'

import Report1          from '../reports/report1.vue'
import Report2          from '../reports/report2.vue'
import Report3          from '../reports/report3.vue'
import Report4          from '../reports/report4.vue'
import Report5          from '../reports/report5.vue'
import Report6          from '../reports/report6.vue'
import Report7          from '../reports/report7.vue'

import Report10         from '../reports/report10.vue'
import Report11         from '../reports/report11.vue'
import Report14         from '../reports/report14.vue'

import ReportePromtec   from '../reports/reportPromtec.vue'

import ReportePromtecFinal   from '../reports/ReportePromtecFinal.vue'
import ReportePromtecFinalInterno   from '../reports/ReportePromtecFinalInterno.vue'

export default {
    components: {
        Report1,
        Report2,
        Report3,
        Report4,
        Report5,
        Report6,
        Report7,
        Report10,
        Report11,
        Report14,
        ReportePromtec,
        ReportePromtecFinal,
        ReportePromtecFinalInterno
    },
    data(){
        return{
            entidi:             null,
            ShowPrinter:        false,
            TxtSinDatos:        'Sin datos almacenados',
            ShowReports:        true,
            ShowReportePromtec: false,
            ShowReports1:       false,
            ShowReports2:       false,
            ShowReports3:       false,
            ShowReports4:       false,
            ShowReports5:       false,
            ShowReports6:       false,
            ShowReports7:       false,
            ShowReports9:       false,
            ShowReports10:      false,
            ShowReports11:      false,
            ShowReports14:      false,
            ShowReportePromtecFinal:      false,
            ShowReportePromtecFinalInterno: false,
            

            transparent: 'rgba(255, 255, 255, 0)',
            items0: [
                /* {
                    title: 'Boletos vendidos',
                    title2:'con detalle',
                    subtext: 'Cantidad total de boletos vendidos',
                    icon:"img/ticket2.png",
                    val:8
                }, */
                {
                    title: 'Reporte comisiones',
                    title2:'con detalle',
                    subtext: 'Informe general de comisiones promtec',
                    icon:"img/ticket2.png",
                    val: 12
                },
                {
                    title: 'Reporte comisiones',
                    title2:'Interno',
                    subtext: 'Informe general de comisiones promtec',
                    icon:"img/ticket2.png",
                    val: 13
                },
            ],
            items: [
                {
                    title: 'Parador',
                    subtext: 'Relacion de fichas de depositos de la unidad recaudadora.',
                    icon:"img/Site.png",
                    val:1
                },
                {
                    title: 'Caja',
                    subtext: 'Informe de recaudacion',
                    icon:"img/POS.png",
                    val:2
                },
                {
                    title: 'Cajero',
                    subtext: 'Informe de recaudacion',
                    icon:"img/Cashier.png",
                    val:3
                },
            ],
            items2: [
                {
                    title: 'Boletos vendidos',
                    title2:'sin detalle',
                    subtext: 'Cantidad total de boletos vendidos',
                    icon:"img/ticket1.png",
                    val:4
                },
                {
                    title: 'Boletos vendidos',
                    title2:'con detalle',
                    subtext: 'Cantidad total de boletos vendidos con detalle',
                    icon:"img/ticket2.png",
                    val:5
                },
                {
                    title: 'Boletos vendidos',
                    title2:'Paradores',
                    subtext: 'Cantidad total de boletos vendidos de todos los Paradores',
                    icon:"img/ticket3.png",
                    val:6
                }
            ],
            items3: [
               {
                    title: 'Transacciones canceladas',
                    title2:'',
                    subtext: 'Detalle de los boletos cancelados',
                    icon:"img/ticket4.png",
                    val:7
                },
                /*
                {
                    title: 'Transacciones por Caja',
                    title2:'',
                    subtext: 'Detalle de transacciones por caja',
                    icon:"img/trans1.png",
                    val:9
                },
                {
                    title: 'Transacciones por Cajero',
                    title2:'',
                    subtext: 'Detalle de transacciones por cajero',
                    icon:"img/trans2.png",
                    val:10
                }
                */
            ],
            items4: [
                {
                    title: 'Reporte de ventas',
                    title2:'',
                    subtext: 'Informe de venta sin detalles',
                    icon:"img/trans1.png",
                    val:14
                },
                {
                    title: 'Cajero',
                    title2:'',
                    subtext: 'Informe de transacciones autorizadas',
                    icon:"img/Cashier.png",
                    val:11
                },
            ]
        }
    },
    mounted(){
        this.vailla()
        this.entidi          = this.$root.token.rol
        this.ShowPrinter = false
    },
    updated(){
        this.vailla()
    },
    methods: {
        vailla(){
            VanillaTilt.init(document.querySelector(".MyCardLX"), {
                max:         25,
                speed:       400,
                glare:       false,
                "max-glare": 1,
            })
            VanillaTilt.init(document.querySelectorAll(".MyCardLX"))
        },
        BackReports(){
            this.HideReports()
            this.ShowReports    = true
            this.ShowPrinter = false
        },
        HideReports(){
            this.ShowReportePromtec = false
            this.ShowReports1 = false
            this.ShowReports2 = false
            this.ShowReports3 = false
            this.ShowReports4 = false
            this.ShowReports5 = false
            this.ShowReports6 = false
            this.ShowReports7 = false
            this.ShowReports9 = false
            this.ShowReports10 = false
            this.ShowReports11 = false
            this.ShowReports14 = false
            this.ShowReportePromtecFinal = false
            this.ShowReportePromtecFinalInterno = false
        },
        OpenReport(item){
            if(item.val){
                
                this.ShowReports = false

                switch(item.val){
                    case 1:
                        this.ShowReports1 = true
                        break
                    case 2:
                        this.ShowReports2 = true
                        break
                    case 3:
                        this.ShowReports3 = true
                        break
                    case 4:
                        this.ShowReports4 = true
                        break
                    case 5:
                        this.ShowReports5 = true
                        break
                    case 6:
                        this.ShowReports6 = true
                        break
                    case 7:
                        this.ShowReports7 = true
                        break
                    case 8: 
                        this.ShowReportePromtec = true
                        break
                    case 9: 
                        this.ShowReports9 = true
                        break
                    case 10: 
                        this.ShowReports10 = true
                        break
                    case 11: 
                        this.ShowReports11 = true
                        break
                    case 14: 
                        this.ShowReports14 = true
                        break
                    case 12: 
                        this.ShowReportePromtecFinal = true
                        break
                    case 13: 
                        this.ShowReportePromtecFinalInterno = true
                        break
                }
            }
        },
        Imprimir(){
            this.PrintElem('page-wrap')
        },
        PrintElem(elem){
            const prtHtml = document.getElementById(elem).innerHTML;
            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }
            this.shoX_ = false
            var  today = new Date();
            var m = today.getMonth() + 1;
            var mes = (m < 10) ? '0' + m : m;
            var fecha = today.getDate()+'/' +mes+'/'+today.getYear()
            var mywindow = window.open('', 'PRINT', 'left=0,top=0,width=750,height=700,toolbar=0,scrollbars=0,status=0')
            mywindow.document.write('<html><head><title>Reporte de Efectivo</title>');
            mywindow.document.write('</head><body style="padding:20px;">');
            mywindow.document.write(document.getElementById(elem).innerHTML);
            mywindow.document.write('</body></html>');
            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/
            mywindow.print();
            mywindow.close();
            return true;
        }
    }
}
</script>

<style scoped>
    .ReportPrint{
        margin-right: 5px;
    }
    .TitleText{
        font-size: 25px;
    }
    .Paralelogramo .TextPAragon{
        position: absolute;
        top: 0;
        left: 49px;
        -webkit-transform: skew(47deg);
        -moz-transform: skew(47deg);
        -ms-transform: skew(47deg);
        -o-transform: skew(47deg);
        transform: skew(47deg);
        font-size: 20px;
        color: white;
    }
    .Paralelogramo {
        width: calc(90% - 45px);
        height: 34px;
        border: 1px solid #555;
        background: orange;
        border: 0;
        -webkit-transform: skew(311deg);
        -moz-transform: skew(311deg);
        -ms-transform: skew(311deg);
        -o-transform: skew(311deg);
        transform: skew(311deg);
        margin: 17px 0px 0px -45px !important;
    }
    .DivFull{
        width: 100%;
    }
    .v-card {
        transition: opacity .4s ease-in-out;
    }

    .v-card:not(.on-hover) {
        opacity: 0.8;
    }

    .show-btns {
    color: rgba(255, 255, 255, 1) !important;
    }

    .CardFormat{
        min-height: 140px;
        background: url('../assets/backgrondReport2.png');
        background-size: 100%;
        height: 100%;
    }
    .CardFormat:hover{
        cursor: pointer;
    }
    .icon{
        width: 80px;
        position: absolute;
        left: calc(100% - 85px);
        top: 2px;
        opacity: 0.7;
    }
</style>