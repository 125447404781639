<template>
  <v-card class="VcarRowT2">
    <table style="width:100%;">
      <tr align="center">
        <td colspan="6" style="font-weight: bold; font-size: 20px;">
          <strong>{{ titulox }}</strong>
        </td>
      </tr>
    </table>

    <br />
    <div style="width:100%; overflow:auto">
      <table
        border="1"
        bordercolor="#272727"
        style="border-collapse: collapse;font-size: 0.7em;"
        class="styled-table"
      >
        <thead>
          <tr>
            <td align="center"><strong></strong></td>
            <td align="center"><strong>TIPO BOLETO</strong></td>
            <td align="center"><strong>VENTA REAL</strong></td>
            <td align="center"><strong>VENTA TOTAL</strong></td>
            <td align="center"><strong>CANJE</strong></td>
            <td align="center"><strong>PRECIO REAL</strong></td>
            <td align="center"><strong>INGRESO REAL</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index1) in DetUser" :key="`DetUser-${index1}`">
            <td align="left">
              <strong>{{ item.shortname }}</strong>
            </td>
            <td align="left">
              <strong>{{ item.Descripcion }}</strong>
            </td>
            <td align="center">
              <strong>{{ formatNum(item.Boleto) }}</strong>
            </td>
            <td align="center">
              <strong>{{ formatNum(item.Cantidad) }}</strong>
            </td>
            <td align="center">
              <strong>{{ formatNum(item.Canje) }}</strong>
            </td>
            <td align="right">
              <strong>${{ formatPrice(item.unitario) }}</strong>
            </td>
            <td align="right">
              <strong>${{ formatPrice(item.total) }}</strong>
            </td>
          </tr>
          <tr class="TotalX">
            <td align="right" colspan="2"><strong>Total general</strong></td>
            <td align="center">
              <strong>{{ formatNum(totalX) }}</strong>
            </td>
            <td align="center">
              <strong>{{ formatNum(totalXX) }}</strong>
            </td>
            <td align="center">
              <strong>{{ formatNum(totalXXX) }}</strong>
            </td>
            <td align="center"><strong></strong></td>
            <td align="right">
              <strong>${{ formatPrice(totalY) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
import { formatPrice, formatNum } from "@/utils/NumericTools.js";

export default {
  components: {},
  data() {
    return {
      formatPrice, formatNum
    };
  },
  props: ["titulox", "DetUser", "totalX", "totalXX", "totalXXX", "totalY"],
  methods: {
    updateGraphic(data1, data2) {
      this.testData.labels = data1;
      this.testData.datasets[0].data = data2;
    },
  },
};
</script>

<style scoped>
.VcarRowT2 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.PieX {
  height: 100% !important;
}
.PieX div {
  height: 100% !important;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em !important;
  font-family: sans-serif !important;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.TotalX {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
  font-size: 18px !important;
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}
@media (max-width: 400px) {
  .VcarRowT2 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>
