<template>
  <v-card class="VcarRowT1">
    <table style="width:100%;">
      <tr align="center">
        <td colspan="9" style="font-weight: bold; font-size: 20px;">
          <strong>Concentrado Paradores <br> Comisión Boletaje</strong>
        </td>
      </tr>
      <br>
      <tr align="center">
        <td colspan="9" style="font-size: 20px;">
          <strong>Fecha: </strong> {{parseDate(date)}} al {{parseDate(date2)}}
        </td>
      </tr>
      <tr></tr>
    </table>
    <table
      border="1"
      bordercolor="#272727"
      style="border-collapse: collapse;"
      class="styled-table"
      >
      <thead>
        <tr align="center">
          <td style="" class="MyTdPadding" align="center"><strong>Parador</strong></td>
          <td style="width: 20%" class="MyTdPadding" align="center"><strong>N. Boletos</strong></td>
          <td style="width: 20%" class="MyTdPadding" align="center"><strong>Recaudación</strong></td>
          <td style="width: 20%" class="MyTdPadding" align="center"><strong>Comisión</strong></td>
          <td style="width: 20%" class="MyTdPadding" align="center"><strong>% del total de ingresos</strong></td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index1) in DetTotales" :key="`DetTicket-${index1}`">
          <td class="MyTdPadding">{{ item.sitio }}</td>
          <td class="MyTdPadding" align="right">
            {{ formatNum(item.tickets) }}
          </td>
          <td class="MyTdPadding" align="right" style="">
            ${{ formatPrice(item.totalF) }}
          </td>
          <td class="MyTdPadding" align="right" style="">
            ${{ formatPrice(item.cuotaF) }}
          </td>
          <td class="MyTdPadding" align="right" style="">
            {{ formatPrice(item.cuotaF * 100 / TotalCuota) }} %
          </td>
        </tr>
        <tr>
          <td colspan="3" class="MyTdPadding" align="center" style="border-top: 1px solid black;">
            <strong>Subtotal</strong>
          </td>
          <td
            align="right"
            class="MyTdPadding"
            style="border-top: 1px solid black;"
          >
            <strong>${{ formatPrice(obtenerSubtotal(TotalCuota)) }}</strong>
          </td>
          <td style="border-right: 1px solid white; border-bottom: 1px solid white; background-color: white !important"></td>
        </tr>
        <tr>
          <td colspan="3" class="MyTdPadding" align="center">
            <strong>IVA</strong>
          </td>
          <td
            align="right"
            class="MyTdPadding"
          >
            <strong>${{ formatPrice(obtenerIVA(TotalCuota)) }}</strong>
          </td>
          <td style="border-right: 1px solid white; border-bottom: 1px solid white; background-color: white !important"></td>
        </tr>
        <tr>
          <td colspan="3" class="MyTdPadding" align="center">
            <strong>Total</strong>
          </td>
          <td
            align="right"
            class="MyTdPadding"
          >
            <strong>${{ formatPrice(obtenerSubtotal(TotalCuota) + obtenerIVA(TotalCuota)) }}</strong>
          </td>
          <td style="border-right: 1px solid white; border-bottom: 1px solid white; background-color: white !important"></td>
        </tr>
      </tbody>
    </table>

    <div style="width:100%;" v-for="(sitios, index2) in DetSitios" :key="`DetTicket-${index2}`">
      <table><tr></tr><tr></tr></table>
      <table style="width:100%;">
        <tr align="left">
          <td colspan="5" style="font-size: 20px;">
            <strong>Parador: </strong> {{sitios.sitio}}
          </td>
        </tr>
        <tr align="left">
          <td colspan="5" style="font-size: 20px;">
            <strong>Fecha: </strong> {{parseDate(date)}} al {{parseDate(date2)}}
          </td>
        </tr>
      </table>
      <table
        border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse; width: 100%"
      >
        <thead>
          <tr>
            <td :colspan="statistics ? 9 : 8" class="MyTdPadding" align="center">
              <strong>Informe de Ventas</strong>
            </td>
          </tr>
          <tr>
            <td style="width: 10%" class="MyTdPadding" align="center"><strong>Cuenta</strong></td>
            <td colspan="2" class="MyTdPadding"><strong>Concepto</strong></td>
            <td style="width: 8%" class="MyTdPadding" align="center"><strong>Total</strong></td>
            <td style="width: 10%" class="MyTdPadding" align="center"><strong>Precio Individual</strong></td>
            <td style="width: 10%" class="MyTdPadding" align="center"><strong>Importe</strong></td>
            <td style="width: 10%" class="MyTdPadding" align="center"><strong>Comisión por boleto</strong></td>
            <td style="width: 10%" class="MyTdPadding" align="center"><strong>Comisión total</strong></td>
            <td style="width: 10%" class="MyTdPadding" align="center" v-if="statistics"><strong>Comisión Media Últimos 6 Meses</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index1) in sitios.data" :key="`DetTicket-${index1}`">
            <td class="MyTdPadding">{{ item.Cuenta }}</td>
            <td colspan="2" class="MyTdPadding">{{ item.Concepto || item.IDNodoCuentaContable }}</td>
            <td class="MyTdPadding" align="center">{{ formatNum(item.total) }}</td>
            <td class="MyTdPadding" align="right">
              ${{ formatPrice(item.PrecioIndividual) }}
            </td>
            <td class="MyTdPadding" align="right" style="">
              ${{ formatPrice(item.Importe) }}
            </td>
            <td class="MyTdPadding" align="right" style="">
              ${{ formatPrice(item.promtec) }}
            </td>
            <td class="MyTdPadding" align="right" style="">
              ${{ formatPrice(item.cuotaX) }}
            </td>
            <td class="MyTdPadding" align="right" style=""  v-if="statistics">
              ${{
                formatPrice(getValue(sitios, item))
              }}
            </td>
          </tr>
          <tr>
            <td colspan="3" class="MyTdPadding" align="right" style="border-top: 1px solid black;">
              <strong>Totales:</strong>
            </td>
            <td class="MyTdPadding" align="center" style="border-top: 1px solid black;">
              <strong>{{formatNum(sitios.Total)}}</strong>
            </td>
            <td class="MyTdPadding" align="right" style="border-top: 1px solid black;">
              <strong>Total</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
              style="border-top: 1px solid black;"
            >
              <strong>${{ formatPrice(sitios.totalF) }}</strong>
            </td>
            <td class="MyTdPadding" align="right" style="border-top: 1px solid black;">
              <strong>Total</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
              style="border-top: 1px solid black;"
            >
              <strong>${{ formatPrice(sitios.cuotaF) }}</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
              style="border-top: 1px solid black;"
              v-if="statistics"
            >
              <strong>${{ formatPrice(averages.sitiosAverage[sitios.sucursal]['averageMensual']) }}</strong>
            </td>
          </tr>
          <tr>
            <td colspan="6" rowspan="3" class="MyTdPadding" align="center" >
              <strong>SON: {{ $root.numeroALetras( sitios.cuotaF, {
                  plural: 'PESOS',
                  singular: 'PESO',
                  centPlural: 'CENTAVOS',
                  centSingular: 'CENTAVO'
                })}} 00/100 MXN</strong> <br>
            </td>
            <td class="MyTdPadding" align="left">
              <strong>Subtotal</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
            >
              <strong>${{ formatPrice(obtenerSubtotal(sitios.cuotaF)) }}</strong>
            </td>
          </tr>
          <tr>
            <td class="MyTdPadding" align="left">
              <strong>IVA</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
            >
              <strong>${{ formatPrice(obtenerIVA(sitios.cuotaF)) }}</strong>
            </td>
          </tr>
          <tr>
            <td class="MyTdPadding" align="left">
              <strong>Total</strong>
            </td>
            <td
              align="right"
              class="MyTdPadding"
            >
              <strong>${{ formatPrice(obtenerSubtotal(sitios.cuotaF) + obtenerIVA(sitios.cuotaF)) }}</strong>
            </td>
          </tr>
        </tbody>
      </table>
      <br />
    </div>
  </v-card>
</template>

<script>
import { formatPrice, formatNum } from "@/utils/NumericTools.js";

export default {
  data() {
    return {
      formatPrice, formatNum
    };
  },
  props: [
    "date",
    "date2",
    "DetTotales",
    "TotalCuota",
    "DetSitios",
    "averages",
    "statistics",
  ],
  mounted(){
  },
  methods: {
    obtenerSubtotal(total){
      return total / 1.16
    },
    obtenerIVA: function (total) {
      return total / 1.16 * 0.16
    },
    FixCanje(item) {
      let ite = 0;
      if (item) {
        ite = item;
      }
      return ite;
    },
    parseDate: function (fecha) {
      try {
        var tempFecha = fecha.split('-')
        return tempFecha[2] + '/' + tempFecha[1] + '/' +tempFecha[0]
      } catch (error) {
        console.log(error);
      }
    },
    round(value, precision) {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    },
    getValue(sitios, item) {
      try {
        return item.Cuenta !== '' ? this.averages.sitiosAverage[sitios.sucursal][item.IDNodoCuentaContable] : this.averages.mayoAverage[sitios.sucursal][item.IDNodoCuentaContable]  
      } catch (error) {
        console.log(error);
        console.log(item);
        console.log(this.averages.mayoAverage[sitios.sucursal]);
      }
    }
  },
};
</script>

<style scoped>
.VcarRowT1 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 7px 10px 26px 24px;
  zoom: 80%;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

.table-total{
  border: 1px solid #272727;
  width: 14%;
}

@media (max-width: 400px) {
  .VcarRowT1 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>
