<template>
  <div style="width:100%">
    <v-toolbar dark>
      <v-toolbar-title>
        Inicio
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <span class="SwithText" v-if="switch1">
        {{ SwithText }}
      </span>
      <v-switch
        v-if="switch1"
        v-model="switch1"
        inset
        color="orange"
        style="padding-top: 21px; zoom: 120%;"
      ></v-switch>
    </v-toolbar>

    <div class="FullDivHome">
      <v-row>
        <v-col
          cols="12"
          sm="12"
          md="6"
          lg="6"
          xl="3"
          v-for="(item, index1) in infoX"
          :key="`infoX-${index1}`"
          class="MySpDiv"
        >
          <v-card class="MyHoverX">
            <v-row class="MyRowCardX">
              <v-col cols="8" style="padding: 20px 10px 20px 26px;">
                <div class="IconVal">{{ formatX(item) }}</div>
                <div class="IconTitle">{{ item.Title }}</div>
              </v-col>
              <v-col cols="4" align="center">
                <span class="material-icons IconX">
                  {{ item.icon }}
                </span>
              </v-col>
            </v-row>
          </v-card>
        </v-col>

        <v-col cols="12" sm="12" md="12" lg="6">
          <v-card style="padding: 20px; height: 80px;">
            <v-select
              prepend-icon="mdi-bank"
              dense
              outlined
              :items="Sitios"
              v-model="SitiosX"
              name="id"
              item-text="name"
              label="Seleccione un parador"
              background-color="white"
              v-on:change="SitiosXChange"
            ></v-select>
          </v-card>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-card class="PieXX">
                <v-skeleton-loader
                  v-if="Graf1"
                  v-bind="attrs"
                  type="image, list-item-two-line"
                  style="padding:20px;"
                ></v-skeleton-loader>
                <PieChart
                  ref="PieChartRef"
                  :chartData="testData"
                  :options="options"
                  v-if="!Graf1"
                />
              </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="12" lg="6">
              <v-card class="PieXX">
                <v-skeleton-loader
                  v-if="Graf2"
                  v-bind="attrs"
                  type="image, list-item-two-line"
                  style="padding:20px;"
                ></v-skeleton-loader>
                <DoughnutChart
                  ref="doughnutRef"
                  :chartData="testData2"
                  :options="options2"
                  v-if="!Graf2"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-card class="PieXX LePadding">
                <v-skeleton-loader
                  v-if="Graf3"
                  v-bind="attrs"
                  type="image, list-item-two-line"
                  style="padding:20px;"
                ></v-skeleton-loader>
                <BarChart
                  ref="BarChartRef"
                  :chartData="testData3"
                  :options="options3"
                  v-if="!Graf3"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12">
          <v-row>
            <v-col cols="12">
              <v-card class="PieXX LePadding">
                <v-skeleton-loader
                  v-if="Graf2"
                  v-bind="attrs"
                  type="image, list-item-two-line"
                  style="padding:20px;"
                ></v-skeleton-loader>
                <LineChart
                  ref="LineChartRef"
                  :chartData="testData2"
                  :options="options2"
                  v-if="!Graf2"
                />
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { shuffle } from "lodash";
import { DoughnutChart, PieChart, BarChart, LineChart } from "vue-chart-3";

export default {
  components: {
    DoughnutChart,
    PieChart,
    BarChart,
    LineChart,
  },
  data() {
    return {
      attrs: {
        class: 'mb-6',
        boilerplate: true,
        elevation: 2,
      },
      switch1: false,
      SwithText: "MODO PRUEBAS",
      Graf1: false,
      Graf2: false,
      Graf3: false,
      sucuy: 0,
      SitiosX: null,
      Sitios: [],
      infoX: [
        {
          icon: "confirmation_number",
          Title: "Boletos mensual",
          val: 0,
          type: "n",
        },
        { icon: "payments", Title: "Recaudado mensual", val: 0, type: "m" },
        {
          icon: "confirmation_number",
          Title: "Boletos anual",
          val: 0,
          type: "n",
        },
        { icon: "payments", Title: "Recaudado anual", val: 0, type: "m" },
      ],
      testData: {
        toggleLegend: true,
        labels: [""],
        datasets: [
          {
            data: [0],
            //backgroundColor: ['#77CEFF', '#0079AF', '#123E6B', '#97B0C4', '#A5C8ED'],
            backgroundColor: [
              "#FFD750",
              "#FFD700",
              "#FFA500",
              "#FF8C00",
              "#FF4500",
              "#FF4570",
            ],
          },
        ],
      },
      options: {
        responsive: true,
        plugins: {
          legend: {
            position: this.toggleLegend ? "top" : "bottom",
            //display:false
          },
          title: {
            display: true,
            text: "Ventas X Tipo de Boleto - Anual",
            font: {
              size: 20,
            },
            //color:'#FFA500'
          },
        },
      },
      testData2: {
        toggleLegend: true,
        labels: [""],
        datasets: [
          {
            label: "Parador",
            data: [0],
            backgroundColor: [
              "#FFD750",
              "#FFA500",
              "#FF8C00",
              "#FF4500",
              "#FF4570",
              "#FFD700",
            ],
          },
        ],
      },
      options2: {
        responsive: true,
        plugins: {
          legend: {
            position: this.toggleLegend ? "top" : "bottom",
          },
          title: {
            display: true,
            text: "Ventas X Parador - Anual",
            font: {
              size: 20,
            },
          },
        },
      },
      testData3: {
        toggleLegend: true,
        labels: ["Día"],
        datasets: [
          {
            label: "Lunes",
            data: [0],
            backgroundColor: "#FFD750",
          },
          {
            label: "Martes",
            data: [0],
            backgroundColor: "#FFD700",
          },
          {
            label: "Miercoles",
            data: [0],
            backgroundColor: "#FFA500",
          },
          {
            label: "Jueves",
            data: [0],
            backgroundColor: "#FF8C00",
          },
          {
            label: "Viernes",
            data: [0],
            backgroundColor: "#FF8C70",
          },
          {
            label: "Sabado",
            data: [0],
            backgroundColor: "#FF4500",
          },
          {
            label: "Domingo",
            data: [0],
            backgroundColor: "#FF4550",
          },
        ],
      },
      options3: {
        responsive: true,
        plugins: {
          legend: {
            position: this.toggleLegend ? "top" : "bottom",
          },
          title: {
            display: true,
            text: "Ventas X Semana",
            font: {
              size: 20,
            },
          },
        },
      },
    };
  },
  watch: {
    switch1: function(val) {
      if (val) {
        this.SwithText = "MODO PRODUCCION";
      } else {
        this.SwithText = "MODO PRUEBAS";
      }

      this.$parent.switch1 = val;
    },
  },
  mounted() {
    this.switch1 = this.$parent.switch1;
    this.IniHome();
  },
  methods: {
    IniHome() {
      this.Sitios = this.$root.siteX;

      var sucu = 39;

      if (this.$root.token) {
        if (this.$root.token.rol == 2) {
          sucu = 12;
        } else {
          if (this.$root.token.rol == 3) {
            sucu = this.$root.token.sitio;
          }
        }

        /* this.DashIni(sucu, this.$root.token.rol); */

        this.DashIni1(sucu);
        this.DashIni2(sucu);
        this.DashIni3(sucu);
        this.DashIni4(this.$root.token.rol, this.$root.token.sitio);
        this.DashIni5(sucu);
      }
    },
    DashIni(sucu, rol) {
      var SitiosX = this.Sitios.find((itemx) => itemx.id === sucu);
      if (SitiosX) {
        this.SitiosX = SitiosX.name;

        if (this.$root.dasini.length > 0) {
          this.PutDataInside(this.$root.dasini);
        } else {
          this.$parent.ShowLoading = true;

          axios
            .post(this.$root.myserver + this.$root.myport + "/Report/DashIni", {
              sucu: sucu,
              rol: rol,
            })
            .then((response) => {
              var info = response.data;
              this.$root.dasini = info;
              this.PutDataInside(info);

              this.$parent.ShowLoading = false;
            })
            .catch((error) => {
              this.$parent.ShowLoading = false;
              console.log(error);
            });
        }
      }
    },
    DashIni1(sucu) {
      var SitiosX = this.Sitios.find((itemx) => itemx.id === sucu);

      if (SitiosX) {
        this.SitiosX = SitiosX.name;

        if (this.$root.dasini.length > 0) {
          this.PutDataInside(this.$root.dasini);
        } else {
          //this.$parent.ShowLoading = true
          axios
            .post(
              this.$root.myserver + this.$root.myport + "/Report/DashIni1",
              {
                sucu: sucu,
              }
            )
            .then((response) => {
              var info = response.data;
              this.infoX[0].val = info[0][0].boletos;
              this.infoX[1].val = info[0][0].total;

              //this.PutDataInside(info)
            })
            .catch((error) => {
              this.$parent.ShowLoading = false;
              console.log(error);
            })
            .finally(() => {});
        }
      }
    },
    DashIni2(sucu) {
      var SitiosX = this.Sitios.find((itemx) => itemx.id === sucu);

      if (SitiosX) {
        this.SitiosX = SitiosX.name;

        if (this.$root.dasini.length > 0) {
          this.PutDataInside(this.$root.dasini);
        } else {
          //this.$parent.ShowLoading = true
          axios
            .post(
              this.$root.myserver + this.$root.myport + "/Report/DashIni2",
              {
                sucu: sucu,
              }
            )
            .then((response) => {
              this.$parent.ShowLoading = false;
              var info = response.data;
              //this.$root.dasini   = info;
              this.infoX[2].val = info[0][0].boletos;
              this.infoX[3].val = info[0][0].total;
            })
            .catch((error) => {
              this.$parent.ShowLoading = false;
              console.log(error);
            })
            .finally(() => {});
        }
      }
    },
    DashIni3(sucu) {
      this.Graf1 = true;
      var SitiosX = this.Sitios.find((itemx) => itemx.id === sucu);

      if (SitiosX) {
        this.SitiosX = SitiosX.name;

        if (this.$root.dasini.length > 0) {
          this.PutDataInside(this.$root.dasini);
        } else {
          //this.$parent.ShowLoading = true
          axios
            .post(
              this.$root.myserver + this.$root.myport + "/Report/DashIni3",
              {
                sucu: sucu,
              }
            )
            .then((response) => {
              this.$parent.ShowLoading = false;
              var info = response.data;
              //this.$root.dasini   = info;
              var temp1 = info[0];
              //var temp1 = info;
              var temp1L = [];
              var temp1D = [];

              for (var x = 0; x < temp1.length; x++) {
                temp1L.push(temp1[x].Descripcion);
                temp1D.push(temp1[x].total);
              }

              this.testData.labels = temp1L;
              this.testData.datasets[0].data = temp1D;

              this.Graf1 = false;
            })
            .catch((error) => {
              this.$parent.ShowLoading = false;
              console.log(error);
            })
            .finally(() => {});
        }
      }
    },
    DashIni4(enti, sitio) {
      if (enti >= 0) {
        this.Graf2 = true;
        axios
          .post(this.$root.myserver + this.$root.myport + "/Report/DashIni4", {
            enti: enti,
            sitio: sitio,
          })
          .then((response) => {
            this.Graf2 = false;
            this.$parent.ShowLoading = false;
            var temp2 = response.data[0];
            var temp2L = [];
            var temp2D = [];

            if (temp2) {
              for (var y = 0; y < temp2.length; y++) {
                temp2L.push(temp2[y].name);
                temp2D.push(temp2[y].total);
              }
            }

            this.testData2.labels = temp2L;
            this.testData2.datasets[0].data = temp2D;
          })
          .catch((error) => {
            this.$parent.ShowLoading = false;
            this.Graf2 = false;
            console.log(error);
          })
          .finally(() => {});
      }
    },
    DashIni5(sucu) {
      var SitiosX = this.Sitios.find((itemx) => itemx.id === sucu);

      if (SitiosX) {
        this.Graf3 = true;
        this.SitiosX = SitiosX.name;
        axios
          .post(this.$root.myserver + this.$root.myport + "/Report/DashIni5", {
            sucu: sucu,
          })
          .then((response) => {
            this.Graf3 = false;
            this.$parent.ShowLoading = false;
            var temp3 = response.data[0];

            var JSontem = JSON.parse(JSON.stringify(this.testData3.datasets));

            if (temp3) {
              for (var z = 0; z < temp3.length; z++) {
                JSontem[z].data[0] = temp3[z].total;
              }
            } else {
              for (var z = 0; z < JSontem.length; z++) {
                JSontem[z].data[0] = 0;
              }
            }

            this.testData3.datasets = JSON.parse(JSON.stringify(JSontem));
          })
          .catch((error) => {
            this.$parent.ShowLoading = false;
            this.Graf3 = false;
            console.log(error);
          })
          .finally(() => {});
      }
    },

    PutDataInside(info) {
      this.infoX[0].val = info[0][0].boletos;
      this.infoX[1].val = info[0][0].total;
      this.infoX[2].val = info[1][0].boletos;
      this.infoX[3].val = info[1][0].total;
      var temp1 = info[2];
      var temp1L = [];
      var temp1D = [];
      var temp2 = info[3];
      var temp2L = [];
      var temp2D = [];
      var temp3 = info[4];
      var JSontem = JSON.parse(JSON.stringify(this.testData3.datasets));

      for (var x = 0; x < temp1.length; x++) {
        temp1L.push(temp1[x].Descripcion);
        temp1D.push(temp1[x].total);
      }

      for (var y = 0; y < temp2.length; y++) {
        temp2L.push(temp2[y].name);
        temp2D.push(temp2[y].total);
      }

      for (var z = 0; z < temp3.length; z++) {
        JSontem[z].data[0] = temp3[z].total;
      }

      this.testData3.datasets = JSON.parse(JSON.stringify(JSontem));

      this.testData.labels = temp1L;
      this.testData.datasets[0].data = temp1D;

      this.testData2.labels = temp2L;
      this.testData2.datasets[0].data = temp2D;
    },
    shuffleData() {
      //let datx = this.testData.datasets[0].data
      //this.testData.datasets[0].data = shuffle(datx)
      //let datx2 = this.testData2.datasets[0].data
      //this.testData2.datasets[0].data = shuffle(datx2)
      //let datx3 = this.testData3.datasets
      //this.testData3.datasets = shuffle(datx3)
      //this.SitiosXChange()
    },
    SitiosXChange() {
      var SitiosX = this.Sitios.find((itemx) => itemx.name === this.SitiosX);

      this.$root.dasini = [];
      /* this.DashIni(SitiosX.id, this.$root.token.rol); */

      this.DashIni1(SitiosX.id);
      this.DashIni2(SitiosX.id);
      this.DashIni3(SitiosX.id);
      this.DashIni4(this.$root.token.rol, this.$root.token.sitio);
      this.DashIni5(SitiosX.id);
    },
    shuffleDataLegend() {
      if (this.toggleLegend) {
        this.toggleLegend = false;
      } else {
        this.toggleLegend = true;
      }
    },
    formatX(value) {
      var val = value.val;
      if (!val) {
        val = 0;
      }else{
        if (value.type == "n") {
          val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
          val = val.toFixed(2);
          val = "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
      
      return val;
    },
  },
};
</script>

<style scoped>
.SwithText {
  margin-right: 10px;
  font-weight: bold;
}
.LePadding {
  padding: 15px !important;
}
.MyRowCardX {
  margin-bottom: 0px !important;
}
.MyHoverX:hover {
  background: rgb(255, 175, 26) !important;
  cursor: pointer;
}
.MyHoverX:hover .IconVal,
.MyHoverX:hover .IconTitle,
.MyHoverX:hover .IconX {
  color: white;
}

.MyHoverX:hover .IconX {
  animation-duration: 2s;
  animation-name: slidein;
}
@keyframes slidein {
  0% {
    transform: scale(1, 1);
  }
  50% {
    transform: scale(2, 2);
  }
  100% {
    transform: scale(1, 1);
    opacity: 1;
  }
}

.IconVal {
  font-size: 24px;
  color: orange;
}
.IconTitle {
  color: #9e9e9e;
}
.IconX {
  font-size: 50px !important;
  padding: 10px;
  color: orange;
  opacity: 0.6;
}
.MySpDiv {
  padding: 10px !important;
}
.MyFull {
  height: 100% !important;
  overflow: auto !important;
  padding: 30px !important;
}
.FullDivHome {
  height: calc(100vh - 65px) !important;
  width: 100%;
  padding: 30px !important;
  overflow: auto;
}
.PieXX {
  min-height: 450px !important;
}
.PieXX div {
  height: 100% !important;
}

/* width */
::-webkit-scrollbar {
  width: 13px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #fa9f31;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #505050;
}

.sk-folding-cube {
  margin: 20px auto;
  width: 40px;
  height: 40px;
  position: relative;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
}

.sk-folding-cube .sk-cube {
  float: left;
  width: 50%;
  height: 50%;
  position: relative;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
}
.sk-folding-cube .sk-cube:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 5px 0px 0px 0px;
  background-color: #b10202;
  -webkit-animation: sk-foldCubeAngle 2.4s infinite linear both;
  animation: sk-foldCubeAngle 2.4s infinite linear both;
  -webkit-transform-origin: 100% 100%;
  -ms-transform-origin: 100% 100%;
  transform-origin: 100% 100%;
}

.sk-folding-cube .sk-cube2 {
  -webkit-transform: scale(1.1) rotateZ(90deg);
  transform: scale(1.1) rotateZ(90deg);
}
.sk-folding-cube .sk-cube3 {
  -webkit-transform: scale(1.1) rotateZ(180deg);
  transform: scale(1.1) rotateZ(180deg);
}
.sk-folding-cube .sk-cube4 {
  -webkit-transform: scale(1.1) rotateZ(270deg);
  transform: scale(1.1) rotateZ(270deg);
}
.sk-folding-cube .sk-cube2:before {
  border-radius: 5px 0px 0px 0px;
  background: #ff0500;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.sk-folding-cube .sk-cube3:before {
  border-radius: 5px 0px 0px 0px;
  background: #fe4d00;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}
.sk-folding-cube .sk-cube4:before {
  border-radius: 5px 0px 0px 0px;
  background: #ff9c00;
  -webkit-animation-delay: 0.9s;
  animation-delay: 0.9s;
}
@-webkit-keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

@keyframes sk-foldCubeAngle {
  0%,
  10% {
    -webkit-transform: perspective(140px) rotateX(-180deg);
    transform: perspective(140px) rotateX(-180deg);
    opacity: 0;
  }
  25%,
  75% {
    -webkit-transform: perspective(140px) rotateX(0deg);
    transform: perspective(140px) rotateX(0deg);
    opacity: 1;
  }
  90%,
  100% {
    -webkit-transform: perspective(140px) rotateY(180deg);
    transform: perspective(140px) rotateY(180deg);
    opacity: 0;
  }
}

/* @media (max-width: 400px) { */
@media (max-width: 700px) and (orientation: landscape),
  (max-width: 530px) and (orientation: portrait) {
  .DivTickets::-webkit-scrollbar {
    width: 4px;
  }
  .PieXX {
    min-height: fit-content !important;
    padding: 5px;
  }
  .FullDivHome {
    padding: 20px !important;
  }
  .MyRowCardX {
    zoom: 80%;
  }
  .MyFull {
    padding: 20px !important;
  }
  .PieX {
    min-height: 300px !important;
  }
}
</style>
