<template>
  <div class="Mybackground">
    <v-toolbar dark>
      <v-toolbar-title v-if="ShowBack">
        <div style="padding-top:0px;">
          <v-btn dark color="orange" @click="ShowBackX()">
            <i class="bx bx-arrow-back" style="font-size: 20px;"></i>
            Regresar
          </v-btn>
        </div>
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-text-field
        v-if="!ShowBack"
        v-model="search"
        label=""
        outlined
        dark
        color="orange"
        :placeholder="TextSearch"
        clearable
        @click:clear="search = ''"
        prepend-icon="mdi-account-search"
        class="my-text-style"
        style="margin-top: 30px; zoom: 80%;"
      ></v-text-field>  
      <h5 v-else>{{nameCorp}}</h5>
    </v-toolbar>
    <div style="margin-top: 0px; padding-top: 0px; height: calc(100vh - 178px); overflow: auto; overflow-x: hidden">
      <CardCorp ref="CardCorp" :ShowBack="ShowBack" :search="search" :nameCorp="nameCorp"/>
    </div>
  </div>
</template>

<script>
import CardCorp from "./CorpCard.vue";

export default {
  components: {
    CardCorp,
  },
  data() {
    return {
      ShowBack: false,
      TextSearch:'Escribe un nombre de compañia',
      search: "",
      nameCorp: ""
    };
  },
  props: ["tabx"],
  watch: {},
  mounted() {},
  methods: {
    ShowBackX(){
      this.ShowBack = false
      this.search   = ''
      this.corpX    = null
    },
  },
};
</script>

<style scoped>
  .Mybackground {
    width: 100%;
    background: #e4e9f7;
  }
  .my-text-style >>> .v-text-field__slot input {
    text-transform: uppercase;
  }
</style>