var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"DivFull"},[_c('v-toolbar',{attrs:{"dark":""}},[_c('v-toolbar-title',[_vm._v(" Reportes ")]),_c('v-spacer'),(_vm.ShowPrinter)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ReportPrint",attrs:{"fab":"","dark":"","small":"","color":"green"},on:{"click":function($event){return _vm.Imprimir()}}},'v-btn',attrs,false),on),[_c('span',{staticClass:"material-icons"},[_vm._v(" print ")])])]}}],null,false,669156056)},[_c('span',[_vm._v("Imprimir Reporte")])]):_vm._e(),(!_vm.ShowReports)?_c('v-btn',{staticClass:"ma-2",attrs:{"color":"orange darken-2","dark":""},on:{"click":_vm.BackReports}},[_c('v-icon',{attrs:{"dark":"","left":""}},[_vm._v(" mdi-arrow-left ")]),_vm._v(" Back ")],1):_vm._e()],1),_c('div',{staticStyle:{"width":"100%","height":"calc(100vh - 66px)","overflow":"auto","overflow-x":"hidden"}},[(_vm.ShowReports && (_vm.entidi == 0 || _vm.entidi == 7))?_c('v-row',{staticStyle:{"padding":"20px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"Paralelogramo",staticStyle:{"margin":"20px"}},[_c('div',{staticClass:"TextPAragon"},[_c('strong',[_vm._v("PROMTEC")])])])]),_vm._l((_vm.items0),function(item,i){return [_c('v-col',{key:'ReportInd-'+i,staticClass:"MyCardLX",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"3"},on:{"click":function($event){return _vm.OpenReport(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"CardFormat",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-card-title',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.icon}})]),_c('span',{staticClass:"TitleText2"},[_vm._v(_vm._s(item.title)),_c('br'),_vm._v(_vm._s(item.title2))])]),_c('v-card-subtitle',{staticStyle:{"margin-top":"0px"}},[_c('strong',[_vm._v(" "+_vm._s(item.subtext)+" ")])])],1)]}}],null,true)})],1)]})],2):_vm._e(),(_vm.ShowReports)?_c('v-row',{staticStyle:{"padding":"20px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"Paralelogramo",staticStyle:{"margin":"20px"}},[_c('div',{staticClass:"TextPAragon"},[_c('strong',[_vm._v("RECAUDACIÓN POR")])])])]),_vm._l((_vm.items),function(item,i){return [_c('v-col',{key:'ReportInd-'+i,staticClass:"MyCardLX",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"3"},on:{"click":function($event){return _vm.OpenReport(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"CardFormat",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-card-title',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.icon}})]),_c('span',{staticClass:"TitleText"},[_vm._v(_vm._s(item.title))])]),_c('v-card-subtitle',{staticStyle:{"margin-top":"0px"}},[_c('strong',[_vm._v(" "+_vm._s(item.subtext)+" ")])])],1)]}}],null,true)})],1)]})],2):_vm._e(),(_vm.ShowReports)?_c('v-row',{staticStyle:{"padding":"20px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"Paralelogramo",staticStyle:{"margin":"20px"}},[_c('div',{staticClass:"TextPAragon"},[_c('strong',[_vm._v("BOLETOS")])])])]),_vm._l((_vm.items2),function(item,i){return [_c('v-col',{key:'ReportInd-'+i,staticClass:"MyCardLX",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"3"},on:{"click":function($event){return _vm.OpenReport(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"CardFormat",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-card-title',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.icon}})]),_c('span',{staticClass:"TitleText2"},[_vm._v(_vm._s(item.title)),_c('br'),_vm._v(_vm._s(item.title2))])]),_c('v-card-subtitle',{staticStyle:{"margin-top":"0px"}},[_c('strong',[_vm._v(" "+_vm._s(item.subtext)+" ")])])],1)]}}],null,true)})],1)]})],2):_vm._e(),(_vm.ShowReports)?_c('v-row',{staticStyle:{"padding":"20px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"Paralelogramo",staticStyle:{"margin":"20px"}},[_c('div',{staticClass:"TextPAragon"},[_c('strong',[_vm._v("TRANSACCIONES")])])])]),_vm._l((_vm.items3),function(item,i){return [_c('v-col',{key:'ReportInd-'+i,staticClass:"MyCardLX",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"3"},on:{"click":function($event){return _vm.OpenReport(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"CardFormat",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-card-title',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.icon}})]),_c('span',{staticClass:"TitleText2"},[_vm._v(_vm._s(item.title)),_c('br'),_vm._v(_vm._s(item.title2))])]),_c('v-card-subtitle',{staticStyle:{"margin-top":"0px"}},[_c('strong',[_vm._v(" "+_vm._s(item.subtext)+" ")])])],1)]}}],null,true)})],1)]})],2):_vm._e(),(_vm.ShowReports)?_c('v-row',{staticStyle:{"padding":"20px"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"Paralelogramo",staticStyle:{"margin":"20px"}},[_c('div',{staticClass:"TextPAragon"},[_c('strong',[_vm._v("MAYORISTAS")])])])]),_vm._l((_vm.items4),function(item,i){return [_c('v-col',{key:'ReportInd-'+i,staticClass:"MyCardLX",attrs:{"cols":"12","sm":"12","md":"6","lg":"4","xl":"3"},on:{"click":function($event){return _vm.OpenReport(item)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"CardFormat",class:{ 'on-hover': hover },attrs:{"elevation":hover ? 12 : 2}},[_c('v-card-title',[_c('div',{staticClass:"icon"},[_c('img',{attrs:{"src":item.icon}})]),_c('span',{staticClass:"TitleText2"},[_vm._v(_vm._s(item.title)),_c('br'),_vm._v(_vm._s(item.title2))])]),_c('v-card-subtitle',{staticStyle:{"margin-top":"0px"}},[_c('strong',[_vm._v(" "+_vm._s(item.subtext)+" ")])])],1)]}}],null,true)})],1)]})],2):_vm._e(),(_vm.ShowReportePromtec)?_c('ReportePromtec'):_vm._e(),(_vm.ShowReportePromtecFinal)?_c('ReportePromtecFinal'):_vm._e(),(_vm.ShowReportePromtecFinalInterno)?_c('ReportePromtecFinalInterno'):_vm._e(),(_vm.ShowReports1)?_c('Report1'):_vm._e(),(_vm.ShowReports2)?_c('Report2'):_vm._e(),(_vm.ShowReports3)?_c('Report3'):_vm._e(),(_vm.ShowReports4)?_c('Report4'):_vm._e(),(_vm.ShowReports5)?_c('Report5'):_vm._e(),(_vm.ShowReports6)?_c('Report6'):_vm._e(),(_vm.ShowReports7)?_c('Report7'):_vm._e(),(_vm.ShowReports10)?_c('Report10'):_vm._e(),(_vm.ShowReports11)?_c('Report11'):_vm._e(),(_vm.ShowReports14)?_c('Report14'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }