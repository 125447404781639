<template>
  <v-card class="VcarRowT1">
    <table style="width:100%;">
      <tr align="center">
        <td colspan="4" style="font-weight: bold; font-size: 20px;">
          <strong>{{ `REPORTE DE CAJERO, ${DetUser.name} ${DetUser.name2}  ${DetUser.name3} - ${DetUser.userId},` }}</strong> <br> Liquidacion
        </td>
      </tr>
      <tr align="center">
        <td colspan="4" style="font-size: 18px;">
          {{ subtitleX }}
        </td>
      </tr>
      <tr></tr>
      <tr align="right">
        <td colspan="4" style="font-size: 17px;">
          <strong><span class="mr-6">Cajero:</span> {{ DetUser.name }} {{ DetUser.name2 }} {{ DetUser.name3 }}</strong>
        </td>
      </tr>
      <tr align="right">
        <td colspan="4" style="font-size: 17px;">
          <strong><span class="mr-6">Total de Transacciones:</span> {{ formatNum(DetUser.operaciones) }}</strong>
        </td>
      </tr>
      <tr align="right">
        <td colspan="4" style="font-size: 17px;">
          <strong><span class="mr-6">Total:</span> ${{ formatPrice(DetUser.total) }}</strong>
        </td>
      </tr>
    </table>

    
    <div style="width:100%; overflow:auto">
      <div v-for="(item1, index1) in DetTicket" :key="`sitio-${item1.id}-${index1}`">
        <table style="width:100%;">
          <tr></tr>
          <tr align="center">
            <td colspan="4" style="font-size: 18px;">
              <strong>{{ item1.sitio }}</strong>
            </td>
          </tr>
        </table>
        <table
          border="1"
          bordercolor="#272727"
          class="styled-table"
          style="border-collapse: collapse; width:100%;"
        >
          <thead>
            <tr>
              <td class="MyTdPadding" align="center"><strong>Nombre del Boleto</strong></td>
              <td class="MyTdPadding" align="center">
                <strong>Cantidad</strong>
              </td>
              <td class="MyTdPadding" align="center">
                <strong>Precio Unitario</strong>
              </td>
              <td class="MyTdPadding" align="center"><strong>Costo</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item2, index2) in item1.data" :key="`DatosSitio-${index2}`">
              <td class="MyTdPadding">{{ item2.nombre }}</td>
              <td class="MyTdPadding" align="center" width="18%">{{ formatNum(item2.cantidad) }}</td>
              <td class="MyTdPadding" align="right" width="18%">
                ${{ formatPrice(item2.costo) }}
              </td>
              <td class="MyTdPadding" align="right" width="18%">
                ${{ formatPrice(item2.total) }}
              </td>
            </tr>
            <tr>
              <td
                class="MyTdPadding"
                align="right"
                style="border-top: 2px double black"
              >
                <strong>Total</strong>
              </td>
              <td
                class="MyTdPadding"
                align="center"
                style="border-top: 2px double black"
              >
                <strong>{{ formatNum(item1.cantidadSitio) }}</strong>
              </td>
              <td 
                align="right"
                class="MyTdPadding"
                style="border-top: 2px double black"
              >
                <strong>TOTAL</strong>
              </td>
              <td
                align="right"
                class="MyTdPadding"
                style="border-top: 2px double black"
              >
                <strong>${{ formatPrice(item1.total) }}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <br />
    </div>



    <table style="width:100%;">
      <tr></tr>
      <tr align="center">
        <td colspan="6" style="font-size: 18px;">
          <strong>TRANSACCIONES</strong>
        </td>
      </tr>
    </table>
    <div v-for="(item2, index2) in transacX" :key="`sitio-${item2.id}-${index2}`">
      <table
        border="1"
        bordercolor="#272727"
        class="styled-table"
        style="border-collapse: collapse; width:100%;"
      >
        <thead>
          <tr>
            <td class="MyTdPadding" align="center"><strong>Linea de Referencia</strong></td>
            <td class="MyTdPadding" align="center"><strong>Nombre de la Empresa</strong></td>
            <td class="MyTdPadding" align="center"><strong>Solicitó</strong></td>
            <td class="MyTdPadding" align="center"><strong>Fecha Orden de Pago</strong></td>
            <td class="MyTdPadding" align="center"><strong>Fecha de Autorización</strong></td>
            <td class="MyTdPadding" align="center"><strong>Costo Total</strong></td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="MyTdPadding" width="25%">{{  `${item2.RefOri}&nbsp;` }}</td>
            <td class="MyTdPadding" align="center" width="15%">{{ item2.CorpName }}</td>
            <td class="MyTdPadding" align="center" width="15%">{{ item2.solicito }}</td>
            <td class="MyTdPadding" align="center" width="15%">{{ item2.f_solicitud }}</td>
            <td class="MyTdPadding" align="center" width="15%">{{ item2.f_autorizacion }}</td>
            <td class="MyTdPadding" align="right"  width="15%">
              ${{ formatPrice(item2.total) }}
            </td>
          </tr>
          <tr>
            <td colspan="6" class="MyTdPadding">
              <div v-for="(item3, index3) in item2.sitio" :key="`sitio-${item3.id}-${index3}`">
                <!-- <table style="width:100%;">
                  <tr>
                    <td colspan="4" style="font-size: 18px;">
                      <strong>{{ item3.SitioName }}</strong>
                    </td>
                  </tr>
                </table> -->
                <table
                  border="1"
                  bordercolor="#272727"
                  class="styled-table"
                  style="border-collapse: collapse; width:100%;"
                >
                  <thead>
                    <tr>
                      <td colspan="4" align="center">
                        <strong>{{ item3.SitioName }}</strong>
                      </td>
                    </tr>
                    <tr>
                      <td class="MyTdPadding" align="center"><strong>Nombre del Boleto</strong></td>
                      <td class="MyTdPadding" align="center">
                        <strong>Cantidad</strong>
                      </td>
                      <td class="MyTdPadding" align="center">
                        <strong>Precio Unitario</strong>
                      </td>
                      <td class="MyTdPadding" align="center"><strong>Costo</strong></td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item4, index4) in item3.ticket" :key="`DatosSitio-${index4}`">
                      <td class="MyTdPadding">{{ item4.NameTicket }}</td>
                      <td class="MyTdPadding" align="center" width="18%">{{ formatNum(item4.cantidad) }}</td>
                      <td class="MyTdPadding" align="right" width="18%">
                        ${{ formatPrice(item4.costo) }}
                      </td>
                      <td class="MyTdPadding" align="right" width="18%">
                        ${{ formatPrice(item4.subtotal) }}
                      </td>
                    </tr>
                    <tr>
                      <td
                        class="MyTdPadding"
                        align="right"
                        style="border-top: 2px double black"
                      >
                        <strong>Total</strong>
                      </td>
                      <td
                        class="MyTdPadding"
                        align="center"
                        style="border-top: 2px double black"
                      >
                        <strong>{{ formatNum(item3.cantidadSitio) }}</strong>
                      </td>
                      <td 
                        align="right"
                        class="MyTdPadding"
                        style="border-top: 2px double black"
                      >
                        <strong>TOTAL</strong>
                      </td>
                      <td
                        align="right"
                        class="MyTdPadding"
                        style="border-top: 2px double black"
                      >
                        <strong>${{ formatPrice(item3.subtotal2) }}</strong>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <br>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </v-card>
</template>

<script>
import { formatPrice, formatNum } from "@/utils/NumericTools.js";

export default {
  name: 'TablaReportMayo',
  data() {
    return {
      formatPrice, formatNum
    };
  },
  props: [
    "subtitleX",
    "DetUser",
    "DetTicket",
    "totalX",
    "transacX"
  ],
  methods: {},
};
</script>

<style scoped>
.VcarRowT1 {
  width: fit-content;
  padding: 30px 50px 30px 50px;
}
.TituloX {
  font-weight: bold;
  font-size: 25px;
}
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  height: calc(100vh - 90px);
  overflow: auto;
  padding: 7px 10px 26px 24px;
  zoom: 80%;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
.styled-table {
  border-collapse: collapse;
  margin: 25px 0;
  font-size: 0.9em;
  font-family: sans-serif;
  min-width: 400px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.styled-table thead tr {
  background-color: #009879 !important;
  color: #ffffff !important;
  text-align: left !important;
}

.styled-table th,
.styled-table td {
  padding: 6px 8px !important;
}

.styled-table tr:nth-child(2n + 0) {
  background: rgba(160, 255, 211, 0.24);
  height: 30px;
}

.styled-table tr:nth-child(2n + 1) {
  background: white;
  height: 30px;
}

.styled-table tbody tr.active-row {
  font-weight: bold !important;
  color: #009879 !important;
}
.styled-table tbody tr:hover {
  cursor: pointer;
  background: rgba(160, 255, 211, 0.6);
}

@media (max-width: 400px) {
  .VcarRowT1 {
    width: fit-content;
    padding: 10px 20px 10px 20px;
  }
}
</style>
