<template>
  <div>
    <div class="MyPaddingV">
      <v-card class="MyTdPadding" style="padding: 20px">
        <v-row>
          <v-col cols="12" align="center">
            <h3><strong>Transacciones por Cajero</strong></h3>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="MyForm">
            <v-menu
              v-model="menu"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  style="height: 10px; !important"
                  label="Seleccione una fecha de inicio"
                  prepend-icon="mdi-calendar"
                  outlined
                  readonly
                  v-bind="attrs"
                  class="Mytext"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="esp"
                v-model="date"
                @input="ChangeDate"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="MyForm">
            <v-select
              prepend-icon="mdi-bank"
              dense
              outlined
              :items="Sitios"
              v-model="SitiosX"
              name="id"
              item-text="name"
              label="Seleccione un parador"
              background-color="white"
              v-on:change="loadPOS"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="4" class="MyForm">
            <v-select
              prepend-icon="mdi-account"
              dense
              outlined
              :items="Cajas"
              v-model="CajasX"
              name="id"
              item-text="name"
              label="Seleccione un cajero"
              background-color="white"
              v-on:change="POSXChange"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            offset-md="6"
            md="6"
            class="Mypadding2 MyForm"
            align="center"
          >
            <v-btn
              :loading="Generete"
              :disabled="Generete"
              height="40"
              color="primary"
              class="ma-2"
              @click="GenereteX"
            >
              GENERAR
            </v-btn>

            <v-btn
              :loading="Generete"
              :disabled="Generete"
              height="40"
              color="success"
              class="ma-2"
              @click="ExportarX"
            >
              Exportar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>

    <div id="page-wrap" align="center" v-if="DetTrans.length > 0">
      <TableReport10 :titulox="titulox" :DetTrans="DetTrans" :totalX="totalX"/>
    </div>
    <div style="padding:20px;" v-if="readyDet">
        <h5>Sin datos almacenados</h5>
    </div>
  </div>
</template>

<script>
import { exportExcelJavascript, sumarDias } from "@/utils/ReportsTools.js";
import $ from "jquery";
import axios from "axios";
import TableReport10 from "./TablaReport10.vue";

export default {
  components: {
    TableReport10,
  },
  data() {
    return {
      titulox: "",
      DataEmpty: true,
      TxtEmpty: "",
      DetTrans: [],
      Cajas: [],
      CajasX: null,
      CajasY: null,
      Generete: false,
      menu: false,
      date: "",
      SitiosX: null,
      Sitios: [
        { name: "CHICHEN ITZÁ", id: 1 },
        { name: "El Corchito", id: 17 },
      ],
      readyDet: false,
      totalX: []
    };
  },
  mounted() {
    this.Sitios = this.$root.siteX;
    this.date = sumarDias(new Date(Date.now()), -1);
  },
  methods: {
    ExportarX: function (event) {
      if (this.DetTrans.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Genera el reporte!",
        });
        return false;
      }
      var table = document.getElementById("page-wrap");
      var html = table.outerHTML;
      exportExcelJavascript(html, event, this.titulox);
    },
    GenereteX: function (event) {
      this.DetTrans = [];
      this.Generete = true;
      if (!this.SitiosX) {
        this.$swal({
          icon: "warning",
          title: "Seleccione un Parador",
        });
        this.Generete = false;
        return false;
      }

      if (!this.CajasX) {
        this.$swal({
          icon: "warning",
          title: "Seleccione un Cajero",
        });
        this.Generete = false;
        return false;
      }

      var SitiosX = this.Sitios.find((itemx) => itemx.name === this.SitiosX);

      var CajasY = this.Cajas.find((itemx) => itemx.name === this.CajasX);

      var name =
        "Transacciones - " +
        this.SitiosX +
        " - " +
        this.CajasX +
        " -  " +
        this.date;
      this.titulox = name;

      this.$root
        .post("/Report/ReportTransacDayU", {
          SitiosX: SitiosX.id,
          dateX: this.date,
          CajasY: CajasY.id,
        })
        .then((response) => {
            if (response.data[0][0].success == "TRUE") {
                this.Generete = false;
                this.$root.token.token = response.data[0][0].token;
                this.$root.SetToken();

                var transac = [];
                var detTransac = [];
                var datos = response.data[1];
                datos.forEach(item => {
                    if(!transac.includes(item.NTRANSNR)){
                        transac.push(item.NTRANSNR);
                        var subTotalTransac = 0;
                        var totalTransac = 0;
                        var data = [];
                        datos.forEach(element => {
                            if (item.NTRANSNR == element.NTRANSNR) {
                                subTotalTransac += (element.subtotal * element.CountTickets);
                                totalTransac += (element.total * element.CountTickets);
                                data.push(element);
                            }
                        });
                        detTransac.push({
                            transac: item.NTRANSNR,
                            fecha: item.FechaOperacion,
                            hora: item.HoraOperacion,
                            idcaja: item.IDEquipo,
                            caja: item.Caja,
                            sub: subTotalTransac, 
                            cancel: item.cancelacion ? item.cancelacion: '',
                            total: totalTransac,
                            elements: data
                        });
                    }
                });

                this.totalX = response.data[2][0];


                this.DetTrans = detTransac;
                
                if (this.DetTrans.length > 0) {
                    this.$parent.ShowPrinter = true;
                    this.readyDet = false;
                } else {
                    this.readyDet = true;
                }

            } else {
                this.$root.swalAlert("error", "Error", response.data[0][0].message);
            }
        });
    },
    ChangeDate(menu) {
      this.menu = false;

      this.loadPOS();
    },
    POSXChange() {
      this.DetTrans = [];
    },
    loadPOS() {
      if (this.SitiosX) {
        this.Cajas = [];
        this.CajasX = null;
        this.CajasY = null;

        var SitiosX = this.Sitios.find((itemx) => itemx.name === this.SitiosX);

        axios
          .post(
            this.$root.myserver +
              this.$root.myport +
              "/Report/GetRecaudacionCajeros",
            {
              SitiosX: SitiosX.id,
              dateX: this.date,
              dateY: this.date,
            }
          )
          .then((response) => {
            this.Cajas = response.data;
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {});
      }
    },
  },
};
</script>

<style scoped>
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  height: calc(100vh - 168px);
  overflow: auto;
  padding: 7px 10px 26px 24px;
  zoom: 80%;
  margin-right: 10px;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
</style>