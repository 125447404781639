<template>
  <div class="Mybackground">
    <v-toolbar dark>
      <v-toolbar-title>
        <v-radio-group v-model="row" row style="padding-top: 16px">
          <v-radio :value="0">
            <template v-slot:label>
              <div style="padding-top: 10px">
                Buscar por <strong class="warning--text">Folio</strong>
              </div>
            </template>
          </v-radio>
          <v-radio :value="1">
            <template v-slot:label>
              <div style="padding-top: 10px">
                Buscar por <strong class="warning--text">Journal</strong>
              </div>
            </template>
          </v-radio>
          <v-radio :value="2">
            <template v-slot:label>
              <div style="padding-top: 10px">
                Buscar por <strong class="warning--text">Barcode</strong>
              </div>
            </template>
          </v-radio>
          <v-radio :value="3">
            <template v-slot:label>
              <div style="padding-top: 10px" class="mr-3">
                Buscar por
                <strong class="warning--text">Fecha Impresion</strong>
              </div>
              <v-menu
                v-if="row == 3"
                v-model="menu"
                absolute
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label=""
                    outlined
                    dark
                    color="orange"
                    placeholder="Seleccionar fecha"
                    prepend-icon="mdi-calendar"
                    class="my-text-style"
                    style="margin-top: 45px; zoom: 80%"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  locale="esp"
                  v-model="date"
                  :max="nowDate"
                  @input="loadFecha"
                ></v-date-picker>
              </v-menu>
            </template>
          </v-radio>
        </v-radio-group>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
        ref="textFieldSearch"
        v-if="row == 0 || row == 1 || row == 2"
        v-model="search"
        label=""
        outlined
        dark
        color="orange"
        :placeholder="TextSearch[row]"
        :rules="row == 0 ? [rules.required, rules.isFolio] : [rules.required]"
        clearable
        v-on:keyup.enter="searchTicket"
        prepend-icon="mdi-account-search"
        class="my-text-style"
        style="margin-top: 30px; zoom: 80%"
      ></v-text-field>
      <v-btn
        v-if="row == 0 || row == 1 || row == 2"
        class="ml-4 mr-2"
        depressed
        color="primary"
        @click="searchTicket"
      >
        Buscar
      </v-btn>
    </v-toolbar>
    <v-card style="margin: 20px" :class="[noData == null ? 'pb-2 pt-1' : '']">
      <v-card-text v-if="!searched || noData">
        <v-alert
          class="m-1"
          icon="bx bxs-traffic-barrier"
          prominent
          text
          type="warning"
          align="left"
        >
          {{ noDataText }}
        </v-alert>
      </v-card-text>
      <v-card-title v-else style="padding: 5px 25px 5px 26px">
        <v-spacer></v-spacer>
        <v-btn height="40" color="success" class="ma-2 ml-7" @click="ExportarX">
          Exportar
        </v-btn>
      </v-card-title>
      <div v-if="searched && !noData">
        <v-data-table
          :headers="headers"
          :items="items"
          item-key="id"
          class="styled-table m-4 mt-2"
          :sort-desc="[true, true]"
          multi-sort
        >
          <template v-slot:body="{ items }">
            <tbody>
              <template v-for="(ticketTransac, index2) in items">
                <tr
                  :key="'ReportTicketsTransac-' + index2"
                  class="pointman"
                  style="color: black"
                  :style="{
                    'background-color': backgrondRowTable(index2),
                    'border-top': '2px solid #75695f',
                  }"
                >
                  <td class="MyTdPadding" align="center">
                    <strong>{{
                      ticketTransac.folioimp ? ticketTransac.folio : ""
                    }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.NJOURNALNO }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.brcode }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.corp }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.l_reference }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{
                      ticketTransac.fecha_autorizacion
                        ? formatFecha(ticketTransac.fecha_autorizacion, "/")
                        : ""
                    }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.folioimp }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{
                      ticketTransac.fecha_impresion
                        ? formatFecha(ticketTransac.fecha_impresion, "/")
                        : ""
                    }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.descripcion }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.alias }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center">
                    <strong>{{ ticketTransac.shortname }}</strong>
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-data-table>
        <div style="width:100%; overflow:auto" v-show="false" id="pageTG-wraperSearchTickets">
          <table style="width:100%;">
            <tr align="right">
              <td colspan="9" style="font-size: 16px;">
                <strong>Busqueda por {{valores[row]}}: </strong> {{row == 3 ? formatFecha(this.date, '/') : this.search}}
              </td>
            </tr>
            <tr align="right">
              <td colspan="9" style="font-size: 16px;">
                <strong>Al día: </strong> {{formatFecha(nowDate, '/')}}
              </td>
            </tr>
            <tr></tr>
          </table>

          <div>
            <table
              border="1"
              bordercolor="#272727"
              class="styled-table"
              style="border-collapse: collapse; width:100%;"
            >
              <thead>
                <tr>
                  <td class="MyTdPadding" align="center"><strong>Folio</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Journal</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Barcode</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Empresa</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Línea de Referencia</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Folio de Impresion</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Fecha de Autorización</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Fecha de Impresión</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Nombre del Boleto</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Alias del Boleto</strong></td>
                  <td class="MyTdPadding" align="center"><strong>Parador Turistico</strong></td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ticketTransac, index2) in items" :key="`sitioReportTicketsTransac-${ticketTransac.id}-${index2}`">
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.folioimp ? ticketTransac.folio : '' }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.NJOURNALNO }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.brcode }}&nbsp;</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.corp }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.l_reference }}  &nbsp;</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.fecha_autorizacion ? formatFecha(ticketTransac.fecha_autorizacion, '/') : '' }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.folioimp }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.fecha_impresion ? formatFecha(ticketTransac.fecha_impresion, '/') : '' }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.descripcion }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.alias }}</strong>
                  </td>
                  <td class="MyTdPadding" align="center" width="10%;">
                    <strong>{{ ticketTransac.shortname }}</strong>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <v-progress-linear
        v-if="TLoading"
        color="orange"
        :height="5"
        indeterminate
      ></v-progress-linear>
    </v-card>
  </div>
</template>

<script>
import { setColorHeadFooter, backgrondRowTable } from "@/utils/StyleTools.js";
import { exportExcelJavascript, formatFecha, sumarDias } from "@/utils/ReportsTools.js";

export default {
  name: 'SearchEngine',
  data() {
    return {
      backgrondRowTable, formatFecha,
      row: 0,
      menu: false,
      date: "",
      nowDate: "",
      TextSearch: [
        "00-00-00000000",
        "Escribe un numero de Journal",
        "Escribe un numero de Barcode",
      ],
      search: "",
      TLoading: false,
      noData: null,
      noDataText: "Primero realize su busqueda!",
      items: [],
      headers: [
        { text: "Folio", value: "folio", align: "center" },
        { text: "Journal", value: "NJOURNALNO", align: "center" },
        { text: "Barcode", value: "brcode", align: "center" },
        { text: "Empresa", value: "corp", align: "center" },
        { text: "Línea de Referencia", value: "l_reference", align: "center" },
        { text: "Fecha de Autorización", value: "fecha_autorizacion", align: "center" },
        { text: "Folio de Impresión", value: "folioimp", align: "center" },
        {
          text: "Fecha de Impresión",
          value: "fecha_creacion",
          align: "center",
        },
        { text: "Nombre del Boleto", value: "descripcion", align: "center" },
        { text: "Alias del Boleto", value: "alias", align: "center" },
        { text: "Parador Turistico", value: "shortname", align: "center" },
      ],
      valores: ['Folio', 'Journal', 'Barcode', 'Fecha'],
      rules: {
        isFolio: (value) => {
          const pattern = /[0-9]+-[0-9]+-[0-9]{8}/;
          return pattern.test(value) || "Folio no valido.";
        },
        required: (v) => !!v || "Campo Requerido",
      },
      searched: false,
    };
  },
  watch: {
    row: function (val) {
      this.searched = false;
      this.search = '';
      this.noDataText = "Primero realize su busqueda!";
      setTimeout(() => {
        if (val == 3) {
          this.searchTicket();
        } else {
          this.$refs.textFieldSearch.reset();
        }
      }, 10);
    },
  },
  mounted() {
    this.date = sumarDias(new Date(Date.now()), 0);
    this.nowDate = sumarDias(new Date(Date.now()), 0);
    setColorHeadFooter();
  },
  methods: {
    searchTicket() {
      this.items = []
      this.TLoading = true;
      this.noData = null;
      let value = "";
      if (this.row == 3) value = this.date;
      else value = this.search;

      this.$root
        .post("/wholesaler/GetSearchTicketMayo", {
          type: this.row,
          value: value,
        })
        .then((response) => {
          var data = response.data;
          if (response.data) {
            data = data[0];
            if (data.length) {
              this.items = data;
              setTimeout(() => {
                setColorHeadFooter();
              }, 10);
            } else {
              this.noDataText = "No hay datos para su busqueda!";
              this.noData = true;
            }
          }
          this.searched = true;
          this.TLoading = false;
        });
    },
    loadFecha() {
      this.menu = false;
      this.searchTicket();
    },
    ExportarX: function(event){
      if(this.items.length == 0){
        this.$swal({
          icon: 'warning',
          title: 'no hay nada que exportar!',
        })
        return false
      }
      let value = "";
      if (this.row == 3) value = formatFecha(this.date, '_');
      else value = this.search;
      var table= document.getElementById('pageTG-wraperSearchTickets');
      var html = table.outerHTML;
      exportExcelJavascript(html, event, `Busqueda_${this.valores[this.row]}_${value}-${formatFecha(this.nowDate, '-')}`)
    },
  },
};
</script>

<style>
</style>