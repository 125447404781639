<template>
  <div>
    <div class="MyPaddingV">
      <v-card class="MyTdPadding" style="padding: 20px">
        <v-row style="zoom: 90%">
          <v-col cols="12" align="center">
            <h3><strong>Boletos cancelados</strong></h3>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="MyForm">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  style="height: 10px; !important"
                  label="Seleccione una fecha de inicio"
                  prepend-icon="mdi-calendar"
                  outlined
                  v-bind="attrs"
                  class="Mytext"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="esp"
                v-model="date"
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="6" md="3" class="MyForm">
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date2"
                  style="height: 10px; !important"
                  label="Seleccione una fecha de fin"
                  prepend-icon="mdi-calendar"
                  outlined
                  v-bind="attrs"
                  class="Mytext"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="esp"
                v-model="date2"
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col cols="12" sm="12" md="6" class="MyForm">
            <v-select
              prepend-icon="mdi-bank"
              dense
              outlined
              :items="Sitios"
              v-model="SitiosX"
              name="id"
              item-text="name"
              label="Seleccione un parador"
              background-color="white"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="3"
            class="Mypadding2 MyForm"
            align="center"
          >
            <v-btn
              :loading="Generete"
              :disabled="Generete"
              height="40"
              color="primary"
              class="ma-2"
              @click="GenereteX"
            >
              GENERAR
            </v-btn>

            <v-btn
              :loading="Generete"
              :disabled="Generete"
              height="40"
              color="success"
              class="ma-2"
              @click="ExportarX"
            >
              Exportar
            </v-btn>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <div id="page-wrap" align="center" v-if="DetUser.length > 0">
      <TableReport7 :titulox="titulox" :DetUser="DetUser"/>
    </div>
    <div style="padding:20px;" v-if="readyDet">
        <h5>Sin datos almacenados</h5>
    </div>
  </div>
</template>

<script>
import { exportExcelJavascript, sumarDias } from "@/utils/ReportsTools.js";
import $ from "jquery";
import axios from "axios";
import TableReport7 from "./TablaReport7.vue";

export default {
  components: {
    TableReport7,
  },
  data() {
    return {
      titulox: "",
      DetUser: [],
      readyDet: false,
      Generete: false,
      menu2: false,
      menu3: false,
      date: "",
      date2: "",
      SitiosX: "",
      Sitios: [
        { name: "CHICHEN ITZÁ", id: 1 },
        { name: "El Corchito", id: 17 },
      ],
    };
  },
  mounted() {
    this.Sitios = this.$root.siteX;
    this.date = sumarDias(new Date(Date.now()), -1);
    this.date2 = sumarDias(new Date(Date.now()), -1);
  },
  methods: {
    ExportarX: function (event) {
      if (!this.SitiosX) {
        this.$swal({
          icon: "warning",
          title: "Seleccione un Parador",
        });
        return false;
      }

      if (this.DetUser.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Genera el reporte!",
        });
        return false;
      }
      var table = document.getElementById("page-wrap");
      var html = table.outerHTML;
      exportExcelJavascript(html, event, this.titulox);
    },
    GenereteX: function (event) {
      this.DetUser = [];
      this.Generete = true;

      if (!this.SitiosX) {
        this.$swal({
          icon: "warning",
          title: "Seleccione un Parador",
        });
        this.Generete = false;
        return false;
      }

      var SitiosX = this.Sitios.find((itemx) => itemx.name === this.SitiosX);

      var name =
        "REPORTE DE BOLETOS CANCELADOS - " +
        this.SitiosX +
        " - de " +
        this.date +
        " a " +
        this.date2;
      this.titulox = name;
      //this.options2.plugins.title.text = name

      var options = {
        SitiosX: SitiosX.id,
        dateX: this.date,
        dateY: this.date2,
      };

      this.$root
        .post("/Report/ReportTrasacCancel", options)
        .then((response) => {
          this.Generete = false;
          var info = response.data[0];
          var list = [];
          var detUserList = [];
          var cajeros = [];
          info.forEach(item => {
            if (item.sEstatus == "PAGADO") {
              if(!cajeros.includes(item.UserId)){
                cajeros.push(item.UserId);
              }
              info.forEach(item2 => {
                if (item.NSERIENNR == item2.NSERIENNR && item2.sEstatus == "CANCELADO" && item.FechaOperacion == item2.FechaOperacion && item.UserId === item2.UserId) {
                  list.push({
                    FechaOperacion: item.FechaOperacion,
                    HoraOperacion: item.HoraOperacion,
                    NombreCajero: item.NombreCajero,
                    PAYNTRANSNR: item.NTRANSNR,
                    CANCELNTRANSNR: item2.NTRANSNR,
                    TotalTransac: item.TotalTransac,
                    UserId: item.UserId
                  });
                }
              });
            } 
          });

          cajeros.sort();
          cajeros.forEach(item => {
            var listAux = [];
            var total = 0;
            list.forEach( item2 => {
              if (item2.UserId == item) {
                total += item2.TotalTransac
                listAux.push(item2);
              }
            });

            if (listAux.length) {
              detUserList.push({
                data: listAux,
                totalCajero: total
              }); 
            }
          });

          this.DetUser = detUserList;

          if (this.DetUser.length > 0) {
            this.$parent.ShowPrinter = true;
            this.readyDet = false;
          } else {
            this.readyDet = true;
          }
        });
    },
  },
};
</script>


<style scoped>
.FullDivR4 {
  width: 100%;
  padding: 30px;
  height: auto;
}
.PieX {
  min-height: 400px !important;
}
.PieX div {
  height: 100% !important;
}
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  padding: 7px 10px 26px 24px;
  margin-right: 10px;
  height: auto;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
</style>