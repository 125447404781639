<template>
  <div>
    <div class="MyPaddingV">
      <v-card class="MyTdPadding" style="padding: 20px;">
          <v-row>
              <v-col cols="12" align="center">
                  <h3><strong>Reporte General De Ventas Mayoristas</strong></h3>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="MyForm">
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                      <v-text-field                   
                          v-model="date"
                          style="height: 10px; !important"
                          label="Seleccione una fecha de inicio"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          class="Mytext"
                          v-on="on"
                      ></v-text-field>
                      </template>
                      <v-date-picker
                      locale="esp"
                      v-model="date"
                      @input="menu2 = false"
                      ></v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" sm="6" md="3" class="MyForm">
                  <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                      <v-text-field                   
                          v-model="date2"
                          style="height: 10px; !important"
                          label="Seleccione una fecha de fin"
                          prepend-icon="mdi-calendar"
                          outlined
                          v-bind="attrs"
                          class="Mytext"
                          v-on="on"
                      ></v-text-field>
                      </template>
                      <v-date-picker
                      locale="esp"
                      v-model="date2"
                      @input="menu3 = false"
                      ></v-date-picker>
                  </v-menu>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="3" class="Mypadding2 MyForm" align="center">
                  <v-btn :loading="Generete" :disabled="Generete" height="40" color="primary" class="ma-2" @click="GenereteX">
                      GENERAR
                  </v-btn>

                  <v-btn :loading="Generete" :disabled="Generete" height="40" color="success" class="ma-2" @click="ExportarX">
                      Exportar
                  </v-btn>
              </v-col>
          </v-row>
      </v-card>
    </div>
    <div style="padding: 20px" v-if="DataEmpty">
      <h5>NO HAY VENTAS DE {{ date }} - {{ date2 }}</h5>
    </div>

    <div id="page-wrap" v-if="DetMayo.length > 0" align="center">
      <TablaReport14
        :date="date"
        :date2="date2"
        :DetMayo="DetMayo"
        :DetUserMayo="DetUserMayo"
        :TotalMayo="TotalMayo"
        :DetailCashier="DetailCashier"
      />
    </div>
  </div>
</template>

<script>
import { exportExcelJavascript, sumarDias } from "@/utils/ReportsTools.js";
import TablaReport14 from "./TablaReport14.vue";

export default {
  components: {
    TablaReport14,
  },
  data() {
    return {
      menu2: false,
      menu3: false,
      date: "",
      date2: "",
      nowDate: "",
      Generete: false,
      DataEmpty: false,
      titulox: "",
      subtitleX: "",
      DetMayo: [],
      DetUserMayo: [],
      TotalMayo: 0,
      DetailCashier: [],
    };
  },
  watch: {
    date: function () {
      this.ChangeDate(1)
    },
    date2: function () {
      this.ChangeDate(2)
    }
  },
  mounted() {
    this.date = sumarDias(new Date(Date.now()), 0);
    this.date2 = sumarDias(new Date(Date.now()), 0);
    this.nowDate = sumarDias(new Date(Date.now()), 0);
  },
  methods: {
    resetReportDetails: function () {
      this.DetMayo = [];
      this.DetUserMayo = [];
      this.TotalMayo = 0;
      this.Generete = false;
      this.DataEmpty = false;
      this.titulox = "";
      this.subtitleX = "";
    },
    ExportarX: function (event) {
      if (this.DetMayo.length == 0) {
        this.$swal({
          icon: "warning",
          title: "Genera el reporte!",
        });
        return false;
      }
      var table = document.getElementById("page-wrap");
      var html = table.outerHTML;
      exportExcelJavascript(html, event, this.titulox);
    },
    GenereteX: function () {
      this.resetReportDetails()
      this.Generete = true;

      this.$root
        .post("/Report/ReportMayoWithoutDetails", {
          dateX: this.date,
          dateY: this.date2
        })
        .then((response) => {
          if (response.data) {
            let { mayo, user, totalMayo, detailCashier }  = response.data
            this.titulox = `REPORTE_GENERAL_DE_VENTAS_MAYORISTAS-${this.date}_00:00-${this.date2}_23:59`;
            this.subtitleX = `Del: ${this.date} 00:00, Hasta: ${this.date2} 23:59`;

            for(var x = 0; x < mayo.length; x++){
                let cantidad = 0;
                for(var y = 0; y < mayo[x].data.length; y++){
                    cantidad += mayo[x].data[y].cantidad
                }
                mayo[x].Cantidad = cantidad
            }

            this.DetMayo        = mayo
            this.DetUserMayo    = user
            this.TotalMayo      = totalMayo
            this.DetailCashier     = detailCashier
            this.Generete       = false
            if (!mayo.length) this.DataEmpty = true;
          } else {
            this.DataEmpty = true;
          }
          this.Generete = false;
        });
    },
    ChangeDate(menu) {
      this.resetReportDetails()
      if (menu == 1) {
        this.menu2 = false;
      } else {
        this.menu3 = false;
      }
    },
  },
};
</script>


<style scoped>
.MyForm {
  height: 75px;
}
#page-wrap {
  margin-top: 20px;
  height: calc(100vh - 168px);
  overflow: auto;
  padding: 7px 10px 26px 24px;
  zoom: 80%;
  margin-right: 10px;
}
.MySeparator {
  background: #9a9a9a;
  height: 20px;
}
.MyBorder {
  border-top: 1px solid black;
}
.MyTdPadding {
  padding: 5px;
}
.MyPaddingV {
  margin: 25px 20px 25px 20px;
  zoom: 90%;
}
.Mytext .v-input__control div fieldset {
  max-height: 45px;
}
.Mytext .v-input__control div input {
  margin-top: -8px;
}
.Mytext .v-input__prepend-outer {
  margin: 9px !important;
}
.MyPadding {
  padding: 20px;
}
.Mypadding2 {
  padding: 4px !important;
}
</style>