<template>
    <div class="DivFull">
        <v-toolbar dark>
            <v-toolbar-title>
                Reporte de Efectivo
            </v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn class="mx-2" fab dark color="purple" @click="PrintElem('ReporEfectivo')">
                <span class="material-icons">
                    print
                </span>
            </v-btn>

        </v-toolbar>

        <div style="width: 100%; height: calc(100vh - 66px); overflow: auto; overflow-x: hidden;" align="center">
            <v-card style="margin:20px; padding:20px; width:700px;" align="left">

                <v-simple-table style="width:700px;">
                    <template v-slot:default>
                        <thead>
                            <tr>
                                <th class="text-left" style="width:108px !important;">
                                    <strong>Tipo de Pago</strong>
                                </th>
                                <th class="text-left" style="width:108px !important;">
                                    <strong>Valor</strong>
                                </th>
                                <th class="text-left" style="width:108px !important;">
                                    <strong>Cantidad</strong>
                                </th>
                                <th class="text-left">
                                    <strong>Subtotal</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in money" :key="'ReportPayment-'+index">
                                <td>
                                    <span v-if="item.tipo != 'euro'" class="material-icons" style="font-size: 35px;" :style="'color:'+item.color+';'">
                                        {{item.tipo}}
                                    </span>
                                    <svg v-if="item.tipo == 'euro'" version="1.0" xmlns="http://www.w3.org/2000/svg"
                                    width="30px" height="30px" viewBox="0 0 512.000000 512.000000"
                                    preserveAspectRatio="xMidYMid meet">

                                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                                    fill="#000000" stroke="none"  :style="'fill:'+item.color+';'">
                                    <path d="M2330 5110 c-494 -48 -950 -230 -1350 -538 -195 -150 -448 -432 -594
                                    -662 -63 -99 -186 -351 -230 -471 -49 -134 -102 -340 -128 -499 -31 -195 -31
                                    -565 0 -760 45 -276 116 -498 237 -745 132 -269 269 -460 489 -681 221 -220
                                    412 -357 681 -489 247 -121 469 -192 745 -237 195 -31 565 -31 760 0 276 45
                                    498 116 745 237 269 132 460 269 681 489 220 221 357 412 489 681 88 179 132
                                    296 180 476 63 240 78 371 78 649 0 278 -15 409 -78 649 -48 180 -92 297 -180
                                    476 -132 269 -269 460 -489 681 -221 220 -412 357 -681 489 -246 121 -474 193
                                    -740 235 -147 23 -475 34 -615 20z m490 -434 c649 -81 1215 -449 1565 -1016
                                    322 -522 395 -1191 194 -1783 -159 -470 -484 -874 -919 -1142 -522 -322 -1191
                                    -395 -1783 -194 -470 159 -874 484 -1142 919 -322 522 -395 1191 -194 1783
                                    235 695 838 1239 1549 1397 219 49 509 63 730 36z"/>
                                    <path d="M2348 3829 l-3 -222 -70 -19 c-96 -26 -264 -108 -345 -168 -82 -61
                                    -202 -185 -253 -263 -207 -312 -238 -714 -82 -1044 59 -124 105 -193 198 -290
                                    128 -134 296 -236 472 -286 l80 -23 3 -222 2 -222 210 0 210 0 2 222 3 222 80
                                    23 c185 53 335 147 500 313 l110 110 -150 150 -149 149 -115 -112 c-96 -93
                                    -131 -120 -202 -155 -190 -94 -388 -94 -578 0 -210 104 -351 331 -351 568 0
                                    236 140 463 350 568 191 96 390 95 580 0 69 -35 106 -63 201 -155 l115 -112
                                    149 149 150 150 -100 101 c-55 56 -133 126 -173 156 -86 64 -247 144 -347 171
                                    l-70 19 -3 222 -2 221 -210 0 -210 0 -2 -221z"/>
                                    </g>
                                    </svg>
                                </td>
                                <td>{{item.valor2}}</td>
                                <td>
                                    <input type="number" min="0" step="1" v-on:blur="fixinput(item)" @keyup="agrego(item)" @change="agrego(item)" v-model="item.cantidad" class="MyInputNumber Hidin">
                                </td>
                                <td>
                                    <strong class="Hidin">{{item.prefix}}{{formatPrice(item.total)}}</strong>
                                </td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan="3" align="right">
                                    <strong>Total</strong>
                                </td>
                                <td style="padding-left: 15px; border-top: 2px solid;">
                                    <strong class="Hidin">${{formatPrice(totalx)}}</strong>
                                </td>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>

                <div id="ReporEfectivo" style="position: absolute; top: 0; left: 0; width: 0; height: 0;">
                    <v-simple-table style="width:700px;">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left" style="width:108px !important;">
                                        Tipo de Pago
                                    </th>
                                    <th class="text-left" style="width:108px !important;">
                                        Valor
                                    </th>
                                    <th class="text-left" style="width:108px !important;">
                                        Cantidad
                                    </th>
                                    <th class="text-left">
                                        Subtotal
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in money" :key="'ReportPayment-'+index">
                                    <td>
                                        {{TipoPago(item.tipo)}}
                                    </td>
                                    <td>{{item.valor2}}</td>
                                    <td>
                                        {{item.cantidad}}
                                    </td>
                                    <td>
                                        <strong>{{item.prefix}}{{formatPrice(item.total)}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot>
                                <td colspan="3" align="right">
                                    <strong>Total</strong>
                                </td>
                                <td style="padding-left: 15px;">
                                    <strong>${{formatPrice(totalx)}}</strong>
                                </td>
                            </tfoot>
                        </template>
                    </v-simple-table>
                </div>
                

            </v-card>
        </div>
    </div>
</template>


<script>
import { formatPrice } from "@/utils/ReportsTools.js";

export default {
    data(){
        return{
            formatPrice,
            totalx: 0,
            headers: [
                { text: 'Model name', value: 'tipo' },
                { text: 'Power', value: 'valor' }
            ],
            money:  [
                        {id:1, tipo:'payments', color:'#8e6c9d', prefix:'$', valor:1000, valor2:1000, cantidad:0, total:0},
                        {id:2, tipo:'payments', color:'#505073', prefix:'$', valor:500, valor2:500, cantidad:0, total:0},
                        {id:3, tipo:'payments', color:'#76826a', prefix:'$', valor:200, valor2:200, cantidad:0, total:0},
                        {id:4, tipo:'payments', color:'#c74c41', prefix:'$', valor:100, valor2:100, cantidad:0, total:0},
                        {id:4, tipo:'payments', color:'#c74c41', prefix:'$', valor:50, valor2:50, cantidad:0, total:0},
                        {id:4, tipo:'payments', color:'#c74c41', prefix:'$', valor:20, valor2:20, cantidad:0, total:0},
                        {id:5, id:1, tipo:'monetization_on', color:'#8e6c9d', prefix:'$', valor:20, valor2:20, cantidad:0, total:0},
                        {id:5, id:1, tipo:'monetization_on', color:'#8e6c9d', prefix:'$', valor:10, valor2:10, cantidad:0, total:0},
                        {id:6, tipo:'monetization_on', color:'#505073', prefix:'$', valor:5, valor2:5, cantidad:0, total:0},
                        {id:7, tipo:'monetization_on', color:'#76826a', prefix:'$', valor:2, valor2:2, cantidad:0, total:0},
                        {id:8, tipo:'monetization_on', color:'#c74c41', prefix:'$', valor:1, valor2:1, cantidad:0, total:0},
                        {id:9, tipo:'euro', color:'#8e6c9d', prefix:'¢', valor:50, valor2:0.5, cantidad:0, total:0},
                        {id:10, tipo:'euro', color:'#505073', prefix:'¢', valor:20, valor2:0.2, cantidad:0, total:0},
                        {id:11, tipo:'euro', color:'#76826a', prefix:'¢', valor:10, valor2:0.1, cantidad:0, total:0},
                    ]
        }
    },
    methods: {
        fixinput(item){
            if(item.cantidad == ""){
                item.cantidad = 0
            }
        },
        agrego(item){
            var total = 0
            if(item.cantidad == ""){
                item.cantidad = 0
            }

            let val = parseInt(item.cantidad) * item.valor2
            item.total  = val

            for(var x=0; x < this.money.length; x++){
                total = total + this.money[x].total
            }

            this.totalx = total
        },
        TipoPago(item){
            let v = ''
            switch(item){
                case 'payments':
                    v = 'Billete(s)'
                    break
                case 'monetization_on':
                    v = 'Peso(s)'
                    break
                case 'euro':
                    v = 'Centavo(s)'
                    break
            }
            return v
        },
        PrintElem(elem)
        {
            const prtHtml = document.getElementById('ReporEfectivo').innerHTML;
            
            // Get all stylesheets HTML
            let stylesHtml = '';
            for (const node of [...document.querySelectorAll('link[rel="stylesheet"], style')]) {
                stylesHtml += node.outerHTML;
            }

            this.shoX_ = false

            var  today = new Date();
            var m = today.getMonth() + 1;
            var mes = (m < 10) ? '0' + m : m;
            var fecha = today.getDate()+'/' +mes+'/'+today.getYear()
            
            
            var mywindow = window.open('', 'PRINT', 'left=0,top=0,width=750,height=700,toolbar=0,scrollbars=0,status=0')

            
                mywindow.document.write('<html><head><title>Reporte de Efectivo</title>');
                mywindow.document.write('</head><body style="padding:20px;">');
                mywindow.document.write('<div><h2>Reporte de Efectivo</h2></div>');
                mywindow.document.write('<div><strong>Fecha:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</strong> '+fecha+'</div>');
                mywindow.document.write('<div><strong>Cajero:&nbsp;&nbsp;&nbsp;</strong> '+this.$root.token.name+' '+this.$root.token.name2+'</div>');
                mywindow.document.write('<div><strong>Sucursal:</strong> '+this.$root.token.sname+'</div><br>');
                mywindow.document.write(document.getElementById(elem).innerHTML);
                mywindow.document.write('</body></html>');
        

        /*
            mywindow.document.write(`<!DOCTYPE html>
                                    <html><head>
                                        ${stylesHtml}
                                    </head>
                                    <body>
                                        ${prtHtml}
                                    </body>
                                    </html>`);
            */

            mywindow.document.close(); // necessary for IE >= 10
            mywindow.focus(); // necessary for IE >= 10*/

            mywindow.print();
            mywindow.close();

            return true;
        }
    }
}
</script>


<style scoped>
    .Hidin{
        display: none;
    }
    .MyInputNumber{
        background: white;
        border: 1px solid;
        color: black;
        padding: 5px;
        border-radius: 3px;
    }
</style>
