<template>
    <div>
        <div class="MyPaddingV">
            <v-card class="MyTdPadding" style="padding: 20px;">
                <v-row>
                    <v-col cols="12" align="center">
                        <h3><strong>Reporte General De Comisiones Promtec</strong></h3>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="MyForm">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field                   
                                v-model="date"
                                style="height: 10px; !important"
                                label="Seleccione una fecha de inicio"
                                prepend-icon="mdi-calendar"
                                outlined
                                v-bind="attrs"
                                class="Mytext"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            locale="esp"
                            v-model="date"
                            @input="menu2 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="3" class="MyForm">
                        <v-menu v-model="menu3" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
                            <template v-slot:activator="{ on, attrs }">
                            <v-text-field                   
                                v-model="date2"
                                style="height: 10px; !important"
                                label="Seleccione una fecha de fin"
                                prepend-icon="mdi-calendar"
                                outlined
                                v-bind="attrs"
                                class="Mytext"
                                v-on="on"
                            ></v-text-field>
                            </template>
                            <v-date-picker
                            locale="esp"
                            v-model="date2"
                            @input="menu3 = false"
                            ></v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" sm="12" md="12" lg="3" class="Mypadding2 MyForm" align="center">
                        <v-btn :loading="Generete" :disabled="Generete" height="40" color="primary" class="ma-2" @click="GenereteX">
                            GENERAR
                        </v-btn>

                        <v-btn :loading="Generete" :disabled="Generete" height="40" color="success" class="ma-2" @click="ExportarX">
                            Exportar
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </div>
        <div id="page-wrap" align="center" v-if="DetSitios.length > 0">
            <TablaReport6 ref="TablaReport6" :date="date" :date2="date2" :DetSitios="DetSitios" :DetTotales="DetTotales" :TotalCuota="TotalCuota" :DetMayo="DetMayo" :DetUserMayo="DetUserMayo" :TotalCuotaMayo="TotalCuotaMayo"/>          
        </div>

    </div>
</template>

<script>
import { exportExcelJavascript, sumarDias } from "@/utils/ReportsTools.js";
import TablaReport6 from './TablaReport6.vue'

export default {
    components: {
        TablaReport6,
    },
    data(){
        return{
            titulox:        '',
            DataEmpty:      true,
            TxtEmpty:       '',
            DetTotales:     [],
            TotalCuota:     0,
            DetSitios:      [],
            DetMayo:        [],
            DetUserMayo:    [],
            TotalCuotaMayo:   0,
            Generete:       false,
            menu2:          false,
            menu3:          false,
            date:           '',
            date2:          '',
            // printToExcel: false
        }
    },
    mounted(){
        this.date   = sumarDias(new Date(Date.now()), -1)
        this.date2  = sumarDias(new Date(Date.now()), -1)
    },
    methods: {
        ExportarX: async function(event){
            if(this.DetTotales.length == 0){
                this.$swal({
                    icon: 'warning',
                    title: 'Genera el reporte!',
                })
                return false
            }
            
            let self = this
            let response = await this.$swal.fire({
                title: 'En que formato desea imprimir?',
                input: 'text',
                showCancelButton: true,
                showDenyButton: true,
                confirmButtonColor: "#3085d6",
                denyButtonColor: "#4caf50",
                cancelButtonColor: "#dd6b55",
                confirmButtonText: 'PDF de entrega',
                denyButtonText: 'Excel',
                cancelButtonText: 'Cancelar',
                preConfirm: (value) => {
                    if (!value) {
                        self.$swal.showValidationMessage(
                            'Se necesita un numero de entrega!'
                        )
                    }
                }
            })

            if (response.isDenied) {
                var table= document.getElementById('page-wrap');
                var html = table.outerHTML;
                return exportExcelJavascript(html, event, self.titulox)
            }
            if (response.isConfirmed) {
                self.$refs.TablaReport6.convertTablesOnPDF(response.value, self.titulox)
            }
        },
        GenereteX: function (event) {
            this.DetSitios        = []
            this.Generete       = true

            var name = 'comisiones_Promtec_de '+this.date+' a '+this.date2;
            this.titulox = name
            
            this.$root
            .post("/Report/ReportPromtecFinal", {
                dateX: this.date,
                dateY: this.date2
            })
            .then(response => {
                if(response.data){
                    
                    for(var x = 0; x < response.data.sitios.length; x++){
                        let Cantidad = 0;
                        let Canje = 0;
                        for(var y = 0; y < response.data.sitios[x].data.length; y++){
                            Cantidad += response.data.sitios[x].data[y].Cantidad
                            Canje += response.data.sitios[x].data[y].Canje
                        }
                        response.data.sitios[x].Cantidad = Cantidad
                        response.data.sitios[x].Canje = Canje
                        response.data.sitios[x].Total = Cantidad + Canje
                    }

                    for(var x = 0; x < response.data.mayo.length; x++){
                        let cantidad = 0;
                        for(var y = 0; y < response.data.mayo[x].data.length; y++){
                            cantidad += response.data.mayo[x].data[y].cantidad
                        }
                        response.data.mayo[x].Cantidad = cantidad
                    }

                    this.DetTotales     = response.data.totales
                    this.TotalCuota     = response.data.totalCuota
                    this.DetSitios      = response.data.sitios
                    this.DetMayo        = response.data.mayo
                    this.DetUserMayo    = response.data.user
                    this.TotalCuotaMayo   = response.data.cuotaMayo
                    this.Generete       = false
                }
            })
            .catch(error => {
                console.log(error)
            })
            
        },
    }
}
</script>


<style scoped>
    .FullDivR4{
        width: 100%;
        padding: 30px;
        height: auto;
    }
    .PieX{
        min-height: 400px !important;
    }
    .PieX div{
        height: 100% !important;
    }
    .MyForm{
        height: 75px;
    }
    #page-wrap{
        margin-top:20px;
        padding: 7px 10px 26px 24px;
        margin-right: 10px;
        height: auto;
    }
    .MySeparator{
        background: #9a9a9a;
        height: 20px;
    }
    .MyBorder{
        border-top: 1px solid black;
    }
    .MyTdPadding{
        padding: 5px;
    }
    .MyPaddingV{
        margin: 25px 20px 25px 20px;
        zoom: 90%;
    }
    .Mytext .v-input__control div fieldset{
        max-height: 45px;
    }
    .Mytext .v-input__control div input{
            margin-top: -8px;
    }
    .Mytext .v-input__prepend-outer{
        margin: 9px !important;
    }
    .MyPadding{
        padding: 20px;
    }
    .Mypadding2{
        padding: 4px !important;
    }
</style>